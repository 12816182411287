/*
 * @Description: 寄售商品列表
 * @Author: 杨志航
 * @Date: 2023-02-21 14:07:40
 */
import React, { useState, useEffect } from 'react'
import HeaderWhite from '@/components/HeaderWhite'
import { InfiniteScroll, PullToRefresh, Input } from 'antd-mobile'
import { pagination } from '@/libs/constant'
import api from '@/api'
import './index.scss'

const priceOptions = [
  { type: 'asc', name: '价格升序' },
  { type: 'desc', name: '价格降序' },
]

const timeOptions = [
  { type: 'asc', name: '时间升序' },
  { type: 'desc', name: '时间倒序' },
]

const defaultCondition = [
  { title: '综合', hasIcon: false, showOpt: false, active: true, select: null, options: [] },
  { title: '价格', hasIcon: true, showOpt: false, active: false, select: null, options: priceOptions },
  { title: '类型', hasIcon: true, showOpt: false, active: false, select: null, options: [] },
  { title: '时间', hasIcon: true, showOpt: false, active: false, select: null, options: timeOptions },
]

function Main({ history }) {
  const [page, setPage] = useState(pagination.page)
  const [perPage] = useState(pagination.perPage)
  const [hasMore, setHasMore] = useState(true)
  const [value, setValue] = useState('')
  const [productList, setProductList] = useState([])
  const [category, setCategory] = useState([])
  const [condition, setCondition] = useState(JSON.parse(JSON.stringify(defaultCondition)))
  const [conMaskVisible, setConMaskVisible] = useState(false)
  const [topMaskVisible, setTopMaskVisible] = useState(false)
  const [lastOrder, setLastOrder] = useState({})

  useEffect(() => {
    // 修改打开APP按钮位置
    const btnEle = document.getElementById('appDownloadBtn')
    btnEle.style.top = '.30rem'
    // 寄售列表
    getList()
    // 获取类型列表
    api.getSaleCategory().then(res => {
      setCategory(res)
      const arr = condition.map((item, index) => {
        if (index === 2) {
          item.options = res
        }
        return item
      })
      setCondition(arr)
    })
    return () => {
      btnEle.style.top = '.70rem'
    }
  }, [])

  const getList = async (params = {}) => {
    const curPage = params.page ?? page
    delete params.page
    const { list, total } = await api.getSaleList({ page: curPage, perPage, ...params })
    const totalPage = Math.ceil(total / perPage)

    if (curPage < totalPage) {
      setPage(curPage + 1)
      setHasMore(true)
    } else {
      setHasMore(false)
    }

    if (curPage === 1) {
      setProductList(list)
    } else {
      setProductList([...productList, ...list])
    }
  }

  const handleSearch = () => {
    let params ={ page: 1 }
    if (value) {
      params.productName = value
    }
    handleReset(params)
  }

  const clickCondition = index => {
    if (index === 0) {
      let params ={ page: 1 }
      if (value) {
        params.productName = value
      }
      handleReset(params)
      setConMaskVisible(false)
      setTopMaskVisible(false)
    } else {
      const arr = condition.map((v, j) => {
        if (j === index) {
          v.showOpt = true
        } else {
          v.showOpt = false
        }
        return v
      })
      setCondition(arr)
      setConMaskVisible(true)
      setTopMaskVisible(true)
    }
  }

  const ClickOption = (evt, index, optionIndex) => {
    evt.stopPropagation()
    const arr = condition.map((v, j) => {
      if (index === j) {
        v.showOpt = false
        v.title = v.options[optionIndex].name
        v.active = true
        v.select = optionIndex
      }
      if (j === 0) {
        v.active = false
      }
      return v
    })
    setCondition(arr)
    setConMaskVisible(false)
    setTopMaskVisible(false)
    let params = { page: 1 }
    let orderList = null

    if (value) {
      params.productName = value
    }

    if (arr[2].select !== null) {
      params.categoryId = arr[2].options[arr[2].select].id
    }

    if (arr[1].select !== null) {
      if (arr[3].select === null) {
        orderList = { price: arr[1].options[arr[1].select].type }
        params.orderList = JSON.stringify(orderList)
      } else {
        switch (index) {
          case 1:
            orderList = { issuesTime: arr[3].options[arr[3].select].type, price: arr[1].options[arr[1].select].type }
            params.orderList = JSON.stringify(orderList)
            break
          case 2:
            params.orderList = JSON.stringify(lastOrder)
            break
          case 3:
            orderList = { price: arr[1].options[arr[1].select].type, issuesTime: arr[3].options[arr[3].select].type }
            params.orderList = JSON.stringify(orderList)
            break
        }
      }
    } else if (arr[3].select !== null) {
      orderList = { issuesTime: arr[3].options[arr[3].select].type }
      params.orderList = JSON.stringify(orderList)
    }
    if (orderList !== null) {
      setLastOrder(orderList)
    }
    getList(params)
  }

  const handleClickMask = () => {
    const arr = condition.map(item => {
      item.showOpt = false
      return item
    })
    setCondition(arr)
    setConMaskVisible(false)
    setTopMaskVisible(false)
  }

  const handleReset = params => {
    getList(params)
    const arr = JSON.parse(JSON.stringify(defaultCondition)).map((v, j) => {
      if (j === 2) {
        v.options = category
      }
      return v
    })
    setCondition(arr)
  }

  const handlePublish = () => {
    const token = localStorage.getItem('token')
    if (token) {
      history.push('/publish')
    } else {
      history.push('/login')
    }
  }

  const handleInput = val => {
    const str = val.trim()
    setValue(str)
    if (str === '') {
      handleReset({ page: 1 })
    }
  }

  return (
    <div className="sale-container">
      {topMaskVisible && <div className="top-mask" onClick={handleClickMask}></div>}
      <img 
        className="entrance-publish" 
        src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/collection-entrance-publish.png" 
        onClick={handlePublish}
      />
      <HeaderWhite title="寄售" />
      <div className="sale-record" onClick={() => history.push('/sale/order')}>寄售记录</div>
      <div className="banner-super">
        <div className="form-super">
          <img className="icon-search" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-black-search.png" />
          <Input 
            placeholder='输入藏品名称' 
            clearable 
            value={value}
            onChange={handleInput}
          />
          <div className="search-btn" onClick={handleSearch}>搜索</div>
        </div>
        <div className="condition-super">
          {condition.map((item, index) => (
            <div 
              className={`item ${item.active ? 'active' : ''}`} 
              key={index}
              onClick={() => clickCondition(index)}
            >
              {item.title}
              {item.hasIcon && <img className="icon" src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-sale-arrow-${item.active ? 'active' : 'default'}.png`} />}
              {item.showOpt && (
                <div className="drop-super">
                  {item.options.map((v, j) => {
                    if (item.select === j) {
                      return null
                    } else {
                      return (<div 
                        className="option" 
                        key={j}
                        onClick={evt => ClickOption(evt, index, j)}
                      >{v.name}</div>)
                    }
                  })}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="content-super">
        {conMaskVisible && <div className="content-mask" onClick={handleClickMask}></div>}
        <PullToRefresh onRefresh={handleSearch}>
          {productList.length === 0 ? (
            <div className="enpty-super">
              <img className="enpty-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/welfare-enpty.png" />
              <div className="text">暂无搜索结果</div>
            </div>
          ) : (
            <>
              <div className="sale-list">
                {productList.map((item, index) => (
                  <div 
                    className="item" 
                    key={index}
                    onClick={() => history.push(`/product?id=${item.productId}&consignmentId=${item.consignmentId}`)}
                  >
                    <img className="main-pic" src={item.mainPic} />
                    <div className="name">{item.productName}</div>
                    <div className="price"><span className="yuan">¥</span>{item.price}</div>
                  </div>
                ))}
              </div>
              <InfiniteScroll loadMore={getList} hasMore={hasMore} />
            </>
          )}
        </PullToRefresh>
      </div>
    </div>
  )
}

export default Main
