/*
 * @Description: 区块链信息查询
 * @Author: 杨志航
 * @Date: 2022-11-04 11:11:25
 */
import React, { useState, useEffect } from 'react'
import { TextArea } from 'antd-mobile'
import Header from '@/components/Header'
import { getCurrentTime } from '@/libs/util'
import api from '@/api'
import './index.scss'

function Main({ history }) {
  const [value, setValue] = useState('')

  useEffect(() => {
    const hash = localStorage.getItem('searchHashValue')
    if (hash) {
      setValue(hash)
      localStorage.removeItem('searchHashValue')
    }
  }, [])

	const handleSearch = () => {
		if (value === '') return
    // 数据埋点
    window.aplus_queue.push({
      action: 'aplus.record',
      arguments: ['blockchain_query', 'CLK', {
        clickTime: getCurrentTime()
      }]
    })
    api.getHashDetail({ hash: value }).then(res => {
      const { resType, data } = res
      localStorage.setItem('searchHashValue', value)

      switch (resType) {
        case 1:
          history.push('/hash/deal', { data })
          break
        case 2:
          history.push('/hash/user', { data })
          break
        case 3:
          history.push('/hash/collection', { data })
          break
      }
    })
	}

	return (
		<div className="hash-container">
      <Header title="区块链信息查询" />
      <div className="search-title">请输入查询信息</div>
      <div className="search-tip">支持藏品、交易、账户区块链信息查询</div>
      <TextArea 
        value={value} 
        rows={8} 
        onChange={val => setValue(val.trim())}
        placeholder="合约地址/交易哈希值/区块链地址"
      />
      <div 
        className={`confirm-btn ${value ? 'enable' : ''}`} 
        onClick={handleSearch}
      >查询</div>
		</div>
	)
}

export default Main
