/*
 * @Description: 创作者
 * @Author: 杨志航
 * @Date: 2023-03-20 13:49:41
 */
import React, { useEffect, useState } from 'react'
import { Input, InfiniteScroll, PullToRefresh, Mask } from 'antd-mobile'
import { pagination } from '@/libs/constant'
import TabBar from '@/components/TabBar'
import { parseSearch } from '@/libs/util'
import api from '@/api'
import './index.scss'

function Main({ history }) {
  const [value, setValue] = useState('')
  const [page, setPage] = useState(pagination.page)
  const [perPage] = useState(pagination.perPage)
  const [productList, setProductList] = useState([])
  const [colWidth] = useState(166)
  const [leftList, setLeftList] = useState([])
  const [rightList, setRightList] = useState([])
  const [leftHeight, setLeftHeight] = useState(0)
  const [rightHeight, setRightHeight] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const [userInfo, setUserInfo] = useState({})
  const [cerVisible, setCerVisible] = useState(false)
  const [tipVisible, setTipVisible] = useState(false)
  const token = localStorage.getItem('token')

  useEffect(() => {
    // 是否登录
    if (!token) {
      history.push('/login?route=home')
      return
    }
    // 请求列表
    getList()
    // 用户信息
    api.getUserInfo().then(res => {
      setUserInfo(res)
    })
  }, [])

  const getList = async params => {
    const curPage = (params && params.page) || page
    const obj = { page: curPage, perPage }
    if (params && params.name) {
      obj.name = params.name
    }
    const res = await api.getCreatorMarket(obj)
    const { list, total } = res
    const totalPage = Math.ceil(total / perPage)
    const arr = list.map(item => {
      const { width, height } = parseSearch(item.mainPic)
      item.height = Math.round(colWidth * ( height / width )) + 76
      return item
    })
    handleWaterfall(arr, curPage)
    if (curPage < totalPage) {
      setPage(curPage + 1)
      setHasMore(true)
    } else {
      setHasMore(false)
    }

    if (curPage === 1) {
      setProductList(list)
    } else {
      setProductList([...productList, ...list])
    }
  }

  const handleWaterfall = (list, curPage) => {
    let lh = 0,
      rh = 0,
      ll = [],
      rl = [];
    if (curPage > 1) {
      lh = leftHeight
      rh = rightHeight
      ll = leftList
      rl = rightList
    }

    list.forEach(item => {
      if (lh <= rh) {
        ll.push(item)
        lh = lh + item.height
      } else {
        rl.push(item)
        rh = rh + item.height
      }
    })
    setLeftHeight(lh)
    setRightHeight(rh)
    setLeftList(ll)
    setRightList(rl)
  }

  const handleKeyDown = evt => {
    // 键盘搜索键
    if (evt.keyCode === 13) {
      setHasMore(false)
      getList({ page: 1, name: value })
    }
  }

  const handleRefresh = () => {
    getList({ page: 1 })
    setValue('')
  }

  const clickPublish = () => {
    if (userInfo.isCert === 2) {
      setCerVisible(true)
      return
    }
    if (userInfo.castCardNum === 0) {
      setTipVisible(true)
      return
    }
    history.push('/creator/publish')
  }

  const clickProduct = id => {
    history.push(`/product?id=${id}`)
  }

  const Item = ({ item }) => (
    <div className="item" onClick={() =>clickProduct(item.id)}>
      <img className="main-img" src={item.mainPic} />
      <div className="info-super">
        <div className="name">{item.name}</div>
        <div className="data-super">
          <div className="price">¥{item.price}</div>
          <div className="hot-super">
            <img className="icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-creator-hot.png" />
            {item.hot}
          </div>
        </div>
      </div>
    </div>
  )

  const handleInput = val => {
    const str = val.trim()
    setValue(str)
    if (str === '') {
      getList({ page: 1 })
    }
  }

  return (
    <div className="creator-container">
      <div className="search-wrapper">
        <div className="form-super">
          <img className="icon-search" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-search.png" />
          <Input 
            type="text" 
            enterKeyHint="search"
            placeholder="输入作品/作者名称"
            clearable 
            value={value}
            onChange={handleInput}
            onKeyDown={evt => handleKeyDown(evt)}
          />
          {userInfo.canCreateProduct === 1 && (
            <img 
              className="icon-publish" 
              src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-creator-publish.png" 
              onClick={clickPublish}
            />
          )}
        </div>
      </div>
      <PullToRefresh onRefresh={handleRefresh}>
        {productList.length === 0 ? (
          <div className="enpty-super">
            <img className="enpty-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/welfare-enpty.png" />
            <div className="text">哎呀，暂时没有作品信息</div>
          </div>
        ) : (
          <>
            <div className="product-list">
              <div className="list">
                {leftList.map((item, index) => <Item  key={index} item={item} />)}
              </div>
              <div className="list">
                {rightList.map((item, index) => <Item key={index} item={item} />)}
              </div>
            </div>
            <InfiniteScroll loadMore={getList} hasMore={hasMore} />
          </>
        )}
      </PullToRefresh>
      <TabBar active={1} />
      <Mask visible={cerVisible} onMaskClick={() => setCerVisible(false)}>
        <div className="overlay-content">
          <div className="overlay-content-top">
            <div className="top-title">先成为实名创作者</div>
            <div className="top-content">如果您想入驻平台发表作品，请您<br/>先申请成为实名创作者</div>
          </div>
          <div className="overlay-content-bottom">
            <div className="vertical-bar"></div>
            <div className="left" onClick={() => setCerVisible(false)}>稍后再说</div>
            <div className="right" onClick={() => history.push('/my/certification')}>立即认证</div>
          </div>
        </div>
      </Mask>
      <Mask visible={tipVisible} onMaskClick={() => setTipVisible(false)}>
        <div className="overlay-content">
          <div className="overlay-content-top">
            <div className="top-title">需要1张铸造卡</div>
            <div className="top-content">您的账户铸造卡可用次数不足</div>
          </div>
          <div className="overlay-content-bottom">
            <div className="right" onClick={() => setTipVisible(false)}>我知道了</div>
          </div>
        </div>
      </Mask>
    </div>
  )
}

export default Main
