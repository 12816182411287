/*
 * @Description: 发布作品=>上传作品 =>上传图片/视频
 * @Author: 刘晶雪
 * @Date: 2023-04-04 11:09
 */
import React, { useEffect, useState, useRef } from 'react'
import { Mask, Toast } from 'antd-mobile'
import { generateUUID } from '@/libs/util'
import api, { fetchOSSUpload } from '@/api'
import './index.scss'

const videoQuery = '?x-oss-process=video/snapshot,t_1000'

function WorksUploader({
	imageUrl,
	size = 5,
	onChange,
	accept = 'image/png, image/jpeg'
}) {
	const [src, setSrc] = useState('https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/upload-icon.png')
	const [visible, setVisible] = useState(false)
	const inputRef = useRef(null)
	const [OSSData, setOSSData] = useState({})
	const [isImage, setIsImage] = useState(true)

	useEffect(() => {
		if (accept.indexOf('video') !== -1) {
			setIsImage(false)
		}
	}, [])

	useEffect(() => {
		if (imageUrl) {
			if (accept.indexOf('video') !== -1) {
				setSrc(imageUrl + videoQuery)
			} else {
				setSrc(imageUrl)
			}
		}
	}, [imageUrl])

	const getImageInfo = file => {
    return new Promise((resolve, reject) => {
      const filereader = new FileReader()
      filereader.onload = (e) => {
        const src = e.target.result
        const image = new Image()
        image.onload = () => {
          const { width, height } = image
          resolve({
            width,
            height,
          })
        };
        image.onerror = reject
        image.src = src
      }
      filereader.readAsDataURL(file)
    })
  }

	const changeImage = async () => {
		let file = inputRef.current.files[0]
		inputRef.current.value = ''
		if (!file) return
		if (size) {
			const isLessSize = file.size / 1024 / 1024 < size;
			if (!isLessSize) {
				Toast.show({
					icon: 'fail',
					content: `文件大小必须小于 ${size}MB！`,
				})
				return
			}
		}
		let data = OSSData
		if (!data.expire || data.expire * 1000 < Date.now()) {
			data = await api.getOssToken()
			setOSSData(data)
		}
		const suffix = file.name.slice(file.name.lastIndexOf('.'))
		const filename = generateUUID() + suffix
		const parmas = {
			name: file.name,
			key: data.dir + filename,
			OSSAccessKeyId: data.accessid,
			policy: data.policy,
			Signature: data.signature,
			callback: data.callback,
			success_action_status: '200',
			file,
		}
		setVisible(true)
		fetchOSSUpload(data.host, parmas).then(async () => {
			let sourceUrl = `${data.host}/${data.dir}${filename}`
			if (isImage) {
				const imgInfo = await getImageInfo(file)
				sourceUrl = `${data.host}/${data.dir}${filename}?width=${imgInfo.width}&height=${imgInfo.height}`
			}
			if (onChange) {
				onChange(sourceUrl)
			}
			setVisible(false)
		}).catch(() => {
			setVisible(false)
		})
	}

	return (
		<div className="upload-works">
      <img className="image" src={src} />
			<input
				className="input"
				ref={inputRef}
				type="file"
				onChange={changeImage}
				accept={accept}
				name="file"
			/>
			<Mask visible={visible} />
		</div>
	)
}

export default WorksUploader
