/*
 * @Description: 区块链技术支持
 * @Author: 杨志航
 * @Date: 2022-12-21 13:37:43
 */
import React from 'react'
import './index.scss'

function Support () {

  return (
    <div className="block-support-container">
      <img className="block-support-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/block-support-company.png" />
      <div className="block-support-text">数文链提供技术支持</div>
    </div>
  )
}

export default Support
