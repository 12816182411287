/*
 * @Description: 商品详情
 * @Author: 杨志航
 * @Date: 2022-07-07 16:26:49
 */
import React, { useState, useEffect, useRef } from 'react'
import { Toast, Mask, ImageViewer } from 'antd-mobile'
import { CountDown } from 'react-vant'
import { formatHomeTime, copyText, parseSearch, getCurrentTime, getDeviceType } from '@/libs/util'
import Header from '@/components/Header'
import Support from '@/components/Support'
import api from '@/api'
import './index.scss'

const defaultTools = [
  { title: '编号排序', active: false, status: 'default' },
  { title: '价格排序', active: true, status: 'down' },
]

function Main({ history, location }) {
  const token = localStorage.getItem('token')
  const search = parseSearch(location.search)
  const [detail, setDetail] = useState({})
  const [hasCollected, setHasCollected] = useState(false)
  const [videoVisible, setVideoVisible] = useState(false)
  const [mediaVisible, setMediaVisible] = useState(false)
  const [cerVisible, setCerVisible] = useState(false)
  const [orderVisible, setOrderVisible] = useState(false)
  const [orderNo, setOrderNo] = useState('')
  const [orderType, setOrderType] = useState(0)
  const [userInfo, setUserInfo] = useState({})
  const [progress, setProgress] = useState({})
  const [tokenVisible, setTokenVisible] = useState(false)
  const [tokenList, setTokenList] = useState([])
  const [activeToken, setActiveToken] = useState({})
  const [tools, setTools] = useState(defaultTools)
  const [isOrigin, setIsOrigin] = useState(false)
  const [optionVisible, setOptionVisible] = useState(false)
  const [imageVisible, setImageVisible] = useState(false)
  const [noVisible, setNoVisible] = useState(false)
  const [noList, setNoList] = useState([])
  const [checkedNo, setCheckedNo] = useState({})
  const videoRef = useRef(null)
  const mediaRef = useRef(null)
  const { canPayment } = global

  useEffect(() => {
    let params = { id: search.id }
    // 寄售商品
    if (search.consignmentId) {
      params.consignmentId = search.consignmentId
    }
    api.getProductOne(params).then(async res => {
      const { data } = res
      const { productType, progress, consignmentId, tokenId, id, creatorType, name } = data
      let userId = undefined
      setDetail(data)
      setHasCollected(data.hasCollected)
      // 盲盒
      if (productType === 2) {
        setProgress(progress)
      }
      // 是否登录
      if (token) {
        const res = await api.getUserInfo()
        userId = res.id
        setUserInfo(res)
      }
      // 同类寄售数据
      if (consignmentId) {
        api.getSimilarList({ pid: search.id }).then(list => {
          setTokenList(list)
          const obj = list.find(item => item.tokenId === tokenId)
          if (obj) {
            setActiveToken(obj)
          } else {
            history.replace(`/product?id=${id}&consignmentId=${list[0].id}`)
            setActiveToken(list[0])
          }
        })
      }
      // 是否原创作品
      if (creatorType === 2 && !consignmentId) {
        setIsOrigin(true)
      }
      // 数据埋点
      const param = {
        userId,
        goodId: id,
        goodName: name,
        clickTime: getCurrentTime()
      }
      window.aplus_queue.push({
        action: 'aplus.record',
        arguments: ['gooddetail_open', 'CLK', param]
      })
      // 上传pv/uv
      api.upPuv({ productId: id })
    })
    
    scrollToAnchor('top')
  }, [])

  // 滚动到锚点
  const scrollToAnchor = id => {
    const anchorElement = document.getElementById(id)
    if(anchorElement) { 
      anchorElement.scrollIntoView({ behavior: 'auto' })
    }
  }

  const getDetail = () => {
    api.getProductOne({ id: search.id }).then(res => {
      setDetail(res.data)
    })
  }

  const handlePlay = (setShow, ref) => {
    setShow(true)
    ref.current.play()
  }

  const handleClose = (setShow, ref) => {
    setShow(false)
    ref.current.pause()
    ref.current.currentTime = 0
  }

  const getBtnClass = () => {
    const { status, subscribed, consignmentNum, consignmentMain } = detail
    let className = ''
    if ((status === 2 && subscribed ) || status === 3 || status === 4) {
      className = 'disable'
    }
    if (status === 1 && isOrigin && consignmentNum === 1 && consignmentMain?.status === 3) {
      className = 'disable'
    }
    return className
  }

  const buyBtn = () => {
    const { consignmentNum, consignmentMain } = detail
    let view = null
    if (isOrigin && consignmentNum === 1 && consignmentMain?.status === 3) {
      view = <div className="big-font">锁定中</div>
    } else {
      view = <div className="big-font">{canPayment ? '立即购买' : '下载APP购买'}</div>
    }
    return view
  }

  const waitBtn = () => {
    const { lastTime, subscribed } = detail
    let view = null
    let text = '预约'
    if (subscribed) {
      text = '已预约'
    }
    if (lastTime > 0 && lastTime <= 24 * 60 * 60) {
      view = <>
        <div className="small-font">{text}</div>
        <div className="residue">
          倒计时 <CountDown time={lastTime * 1000} onFinish={getDetail} />
        </div>
      </>
    } else {
      view = <>
        <div className="small-font">{text}</div>
        <div className="residue">
          {formatHomeTime(detail.issuesTime)}开售
        </div>
      </>
    }
    return view
  }

  const handleError = err => {
    const { code, data, message } = err
    if (code === 120001) {
      setNoVisible(false)
      setOrderVisible(true)
      setOrderNo(data.orderNo)
      setOrderType(data.orderType)
    } else if (code === 120000 || code === 120002) {
      Toast.show({
        icon: 'fail',
        content: message || '服务器发生错误，请联系管理员',
      })
    }
  }

  const handlePayWait = () => {
    if (orderType === 4) {
      history.push('/sale/buydetail', { orderNo })
    } else {
      history.push('/order/buydetail', { orderNo })
    }
  }

  const handleClickBtn = () => {
    const { status, subscribed, id, consignmentId, consignmentNum, consignmentMain } = detail
    switch (status) {
      case 1: // 销售中
        // 支付开关
        if (!canPayment) {
          window.open('https://nft.372163.com/h5/share')
        }
        // 原创作品正在支付中
        if (isOrigin && consignmentNum === 1 && consignmentMain?.status === 3) return
        // 未登录
        if (!token) {
          history.push('/login')
          return
        }
        // 未实名认证
        if (userInfo.isCert === 2) {
          setCerVisible(true)
          return
        }
        // 原创作品购买
        if(isOrigin) {
          if (consignmentNum > 1) {
            api.canSellList({ id }).then(res => {
              const { list } = res
              setNoList(list)
              setCheckedNo(list[0])
              setNoVisible(true)
            }).catch(() => getDetail())
          } else {
            api.orderCheck({ consignmentId: consignmentMain.id }).then(() => {
              history.push('/payment', { productId: id, consignmentId: consignmentMain.id })
            }).catch(err => handleError(err))
          }
          return
        }
        // 其他商品购买
        let obj = {}
        if (consignmentId) {
          obj = { consignmentId: activeToken.id }
        } else {
          obj = { productId: id }
        }
        api.orderCheck(obj).then(() => {
          if (consignmentId) {
            history.push('/payment', { productId: id, consignmentId: activeToken.id })
          } else {
            history.push('/payment', { productId: id })
          }
        }).catch(err => handleError(err))
        break
      case 2: // 预售
        // 未登录
        if (!token) {
          history.push('/login')
          return
        }
        if (!subscribed) {
          api.productSubscribe({ productId: id }).then(() => {
            Toast.show({
              icon: 'success',
              content: '已添加到日历',
              afterClose: () => getDetail(),
            })
            // 数据埋点
            const param = {
              userId: userInfo.id,
              goodId: detail.id,
              deviceType: getDeviceType(),
              clickTime: getCurrentTime()
            }
            window.aplus_queue.push({
              action: 'aplus.record',
              arguments: ['goods_reserve', 'CLK', param]
            })
          })
        }
        break
    }
  }

  const handleBack = () => {
    if (search.share) {
      history.push('/home')
    } else {
      history.goBack()
    }
  }

  const handleCopy = () => {
    let url = window.location.href
    if (url.indexOf('&share=1') === -1) {
      url = url + '&share=1'
    }
    copyText(url, '已复制商品链接')
  }

  const handleShare = () => {
    // 数据埋点
    const param = {
      userId: userInfo.id,
      goodId: detail.id,
      deviceType: getDeviceType(),
      clickTime: getCurrentTime()
    }
    window.aplus_queue.push({
      action: 'aplus.record',
      arguments: ['gooddetail_share', 'CLK', param]
    })
    // 上传分享次数
    api.upShare({ productId: detail.id }).finally(() => handleCopy())
  }

  const selectToken = item => {
    history.replace(`/product?id=${detail.id}&consignmentId=${item.id}`)
    setActiveToken(item)
    setTokenVisible(false)
  }

  const clickTools = index => {
    let newList = null
    const arr = tools.map((v, j) => {
      if (j === index) {
        v.active = true
        if (v.status !== 'down' ) {
          v.status = 'down'
          switch (index) {
            case 0:
              newList = tokenList.sort((a, b) => a.tokenId - b.tokenId)
              break
            case 1:
              newList = tokenList.sort((a, b) => parseFloat(a.price) - parseFloat(b.price))
              break
          }
          setTokenList(newList)
        } else {
          v.status = 'up'
          switch (index) {
            case 0:
              newList = tokenList.sort((a, b) => b.tokenId - a.tokenId)
              break
            case 1:
              newList = tokenList.sort((a, b) => parseFloat(b.price) - parseFloat(a.price))
              break
          }
          setTokenList(newList)
        }
      } else {
        v.active = false
        v.status = 'default'
      }
      return v
    })
    setTools(arr)
  }

  const dealName = name => {
    if (!name) return ''
    let str = name
    if (name.length > 12) {
      str = name.slice(0, 8) + '...' + name.slice(-3)
    }
    return str
  }

  const handleInterest = () => {
    if (hasCollected) {
      api.productCancelCollect({
        productId: detail.id
      }).then(() => {
        setHasCollected(false)
        Toast.show({
          icon: 'success',
          content: '取消收藏',
          duration: 1000
        })
      })
    } else {
      api.productCollect({
        productId: detail.id
      }).then(() => {
        setHasCollected(true)
        Toast.show({
          icon: 'success',
          content: '收藏成功',
          duration: 1000
        })
      })
    }
  }

  const handleAvatar = () => {
    history.push('/collection/avatar', { pic: detail.mainPic })
  }

  const handleSell = () => {
    const { id, status } = detail
    const params = { 
      id,
      enable: status === 1 ? 2 : 1
    }
    const msg = status === 1 ? '停售成功' : '寄售成功'
    api.productSetEnable(params).then(() => {
      Toast.show({
        icon: 'success',
        content: msg,
        duration: 1000,
        afterClose: () => {
          getDetail()
          setOptionVisible(false)
        },
      })
    })
  }

  const handleShowCollection = () => {
    if (detail.renderPic) {
      history.push('/collection/show', { id: search.id, isProduct: true })
    } else { // 原创作品
      setImageVisible(true)
    }
  }

  const handlecheckNo = item => {
    setCheckedNo(item)
  }

  const handleCreatorBuy = () => {
    if (checkedNo.status === 3) return
    api.orderCheck({ consignmentId: checkedNo.id }).then(() => {
      history.push('/payment', { productId: detail.id, consignmentId: checkedNo.id })
    }).catch(err => handleError(err))
  }

  return (
    <div className="product-container" id="top">
      <Header bgColor="transprent" onClick={handleBack} />
      <img 
        className="icon-share" 
        src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-product-share.png" 
        onClick={handleShare}
      />
      <div className="banner">
        <img className="banner-picture" src={detail.renderPic ? detail.renderPic : detail.mainPic} />
        <div className="banner-icon">
          {detail.hasBuy && (
            <>
              <img 
                className="icon-3d" 
                src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-collection-3d.png" 
                onClick={handleShowCollection}
              />
              {(detail.productCategoryId === 3 || detail.productCategoryId === 4) && 
                <img 
                  className="icon-play" 
                  src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-collection-play.png"
                  onClick={() => handlePlay(setMediaVisible, mediaRef)}
                />
              }
            </>
          )}
        </div>
        {search.consignmentId ? (
          <div className="banner-name">{`${dealName(detail.name)} #${activeToken?.tokenNo}`}</div>
        ) : (
          <div className="banner-name">{detail.name}</div>
        )}
        {isOrigin ? (
          <div className="banner-subtitle">
            {
              detail.tagNames?.length > 0 && 
              detail.tagNames.map((v, i) => < span className="banner-describe0" key={i}>{v}</span>)
            }
          </div>
        ) : (
          <div className="banner-subtitle">
            <span className="banner-quantity">限量</span>
            <span className="banner-part">{detail.issuesNum}份</span>
            {search.consignmentId ? (
              <>
                <span className="banner-quantity">流通</span>
                <span className="banner-part">{detail.circulationNum}份</span>
              </>
            ) : (
              detail.tagNames?.length > 0 && 
              detail.tagNames.map((v, i) => < span className={'banner-describe' + i} key={i}>{v}</span>)
            )}
          </div>
        )}
        {!detail.hasBuy && (
          <div className="banner-prompt">
            <div className="dotted-left"></div>
            <img 
              className="lock" 
              src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-lock.png" 
            />
            <div>购买即可体验内容</div>
            <div className="dotted-right"></div>
          </div>
        )}
      </div>
      <div className="author-super">
        <img 
          className="author-icon" 
          src={detail.creatorLogo} 
        />
        <div className="author-title">
          <div>{detail.creatorName}</div>
          <div className="name">创作者</div>
        </div>
      </div>
      {search.consignmentId && (
        <div className="sale-super" onClick={() => setTokenVisible(true)}>
          <div>
            <div className="title">同类寄售</div>
            <div className="subtitle">当前藏品的所有寄售列表</div>
          </div>
          <img className="icon-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-left-arrow.png" />
        </div>
      )}
      {detail.productType === 2 && ( // 盲盒
        <div className="progress">
          <div className="my-collection">我的收集（{progress.numHold}/{progress.numTotal}）</div>
          <div className="my-img">
            {progress.list && progress.list.map((item, index) => (
              <img 
                key={index}
                className="img" 
                src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/product-progress-${item.holdNum > 0 ? 'enable' : 'disable'}.png`}
              />
            ))}
          </div>
          <div className="look-btn" onClick={() => history.push('/product/progress', { id: detail.id })}>
            查看收集进度
            <img className="arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/product-progress-arrow.png" />
          </div>
        </div>
      )}
      {isOrigin ? (
        <>
          <div className="create-title">作品信息</div>
          <div className="create-section">
            <div className="info-list">
              {detail.consignmentNum === 1 && (
                <div className="item">
                  <div className="label">作品编号:</div>
                  <div className="value">#01/1</div>
                </div>
              )}
              <div className="item">
                <div className="label">合约地址:</div>
                <div className="value">
                  <div className="text">{detail.blockHash}</div>
                  <img 
                    className="icon-copy" 
                    src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/qukuailiandizhi.png" 
                    onClick={() => copyText(detail.blockHash)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="create-title">作品详情</div>
          <div className="create-section">
            <div className="desc-list">
              <div className="item">
                <div className="label">作品描述</div>
                <div className="value" dangerouslySetInnerHTML={{__html: detail.desc?.replaceAll('\n', '<br />')}}></div>
              </div>
              <div className="item">
                <div className="label">创作者介绍</div>
                <div className="value" dangerouslySetInnerHTML={{__html: detail.creatorDesc?.replaceAll('\n', '<br />')}}></div>
              </div>
            </div>
          </div>
          {detail.isCreator && (
            <>
              <div className="create-title">出售记录</div>
              <div className="create-section">
                <table border="0">
                  <thead>
                    <tr>
                      <th align="left">作品编号</th>
                      <th>价格</th>
                      <th>状态</th>
                      <th>日期</th>
                    </tr>
                  </thead>
                  <tbody>
                    {detail.consignmentSoldList?.map((item, index) => (
                      <tr key={index}>
                        <td>{item.no}</td>
                        <td align="center">¥{item.price.slice(0, -3)}</td>
                        <td align="center">已售出</td>
                        <td align="center">{item.addTime.slice(0, 10).replaceAll('-', '/')}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {detail.consignmentSoldList?.length === 0 && <div className="record-enpty">暂无出售记录哦</div>}
              </div>
            </>
          )}
          <div className="create-title">购买须知</div>
          <div className="create-section">
            <div className="create-notice" dangerouslySetInnerHTML={{__html: detail.instructions?.replaceAll('\n', '<br />')}}></div>
          </div>
        </>
      ) : (
        <>
          <div className="introduce">
            <div className="introduce-title">藏品介绍</div>
            {detail.descVideo && 
              <div className="video-super" onClick={() => handlePlay(setVideoVisible, videoRef)}>
                <img 
                  className="poster" 
                  src={detail.mainPic}
                />
                <img 
                  className="icon" 
                  src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/product-video-play-icon.png" 
                />
              </div>
            }
            <div className="introduce-desc" dangerouslySetInnerHTML={{__html: detail.desc}}></div>
            <div className="introduce-publish">
              <div className="publish-left">发行方</div>
              <div className="publish-right">{detail.issuerName}</div>
            </div>
            <div className="introduce-publish">
              <div className="publish-left">发行时间</div>
              <div className="publish-right">{detail.issuesTime?.slice(0, 10)}</div>
            </div>
          </div>
          <div className="notice">
            <div className="notice-title">购买须知</div>
            <div className="notice-contet" dangerouslySetInnerHTML={{__html: detail.instructions?.replaceAll('\n', '<br />')}}></div>
          </div>
        </>
      )}
      <div className="support-super">
        <Support />
      </div>
      <div className="footer">
        <div className="price">¥ {search.consignmentId ? activeToken?.price : detail.price}</div>
        <div className="botton-super">
          {isOrigin && (
            <>
              <img 
                className="icon-interest" 
                src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-interest-${hasCollected ? 'checked' : 'default'}.png`}
                onClick={handleInterest}
              />
              {detail.isCreator && (
                <img 
                  className="icon-option" 
                  src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-more-option.png" 
                  onClick={() => setOptionVisible(true)}
                />
              )}
            </>
          )}
          <div className={`button ${getBtnClass()}`} onClick={handleClickBtn}>
            {detail.status === 1 && buyBtn()}
            {detail.status === 2 && waitBtn()}
            {detail.status === 3 && <div className="big-font">已售罄</div>}
            {detail.status === 4 && <div className="big-font">{isOrigin ? '已停售' : '已结束'}</div>}
          </div>
        </div>
      </div>
      <Mask 
        className="media-modal-super"
        visible={videoVisible} 
        onMaskClick={() => handleClose(setVideoVisible, videoRef)}
        style={{ '--z-index': 1000 }}
        forceRender
      >
        <video 
          className="media-player"
          src={detail.descVideo}
          loop
          ref={videoRef}
          controls
        >
          您的浏览器不支持 video 标签。
        </video>
      </Mask>
      <Mask 
        className="media-modal-super"
        visible={mediaVisible} 
        onMaskClick={() => handleClose(setMediaVisible, mediaRef)}
        style={{ '--z-index': 1000 }}
        forceRender
      >
        {detail.categoryId === 3 &&
          <video 
            className="media-player"
            src={detail.video}
            loop
            ref={mediaRef}
            controls
            controlsList="nodownload"
          >
            您的浏览器不支持 video 标签。
          </video>
        }
        {detail.categoryId === 4 &&
          <audio 
            className="media-player"
            src={detail.audio}
            loop
            ref={mediaRef}
            controls
            controlsList="nodownload"
          >
            您的浏览器不支持 audio 标签。
          </audio>
        }
      </Mask>
      <Mask 
        visible={cerVisible} 
        onMaskClick={() => setCerVisible(false)} 
      >
        <div className="overlay-content">
          <div className="overlay-content-top">
            <div className="top-title">实名认证提示</div>
            <div className="top-content">应国家法规要求，<br/>购买/领取数字藏品需先进行实名认证</div>
          </div>
          <div className="overlay-content-bottom">
            <div className="vertical-bar"></div>
            <div className="left" onClick={() => setCerVisible(false)}>稍后再说</div>
            <div className="right" onClick={() => history.push('/my/certification')}>去实名</div>
          </div>
        </div>
      </Mask>
      <Mask 
        visible={orderVisible} 
        onMaskClick={() => setOrderVisible(false)} 
      >
        <div className="overlay-content">
          <div className="overlay-content-center">
            <div className="top-title">存在待支付订单，不可重复提交哦</div>
          </div>
          <div className="overlay-content-bottom">
            <div className="vertical-bar"></div>
            <div className="left" onClick={() => setOrderVisible(false)}>在想想</div>
            <div className="right" onClick={handlePayWait}>去支付</div>
          </div>
        </div>
      </Mask>
      <Mask 
        visible={tokenVisible} 
        onMaskClick={() => setTokenVisible(false)} 
      >
        <div className="token-list-modal">
          <div className="title-super">
            <div className="title">寄售列表</div>
            <img 
              className="icon-close" 
              src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/login-icon-close.png" 
              onClick={() => setTokenVisible(false)}
            />
          </div>
          <div className="list-title">
            <div className="account">共{tokenList.length}个</div>
            <div className="btn-super">
              {tools.map((item, index) => (
                <div 
                  className={`sort-super ${item.active ? 'active' : ''}`} 
                  key={index}
                  onClick={() => clickTools(index)}
                >
                  {item.title}
                  <div className={`icon ${item.status}`}></div>
                </div>
              ))}
            </div>
          </div>
          <div className="list-content">
            {tokenList.map((item, index) => (
              <div 
                className={`item ${item.tokenId === activeToken?.tokenId ? 'active' : ''}`} 
                key={index}
                onClick={() => selectToken(item)}
              >
                <div className="number">#{item.tokenNo}{item.tokenId === activeToken?.tokenId ? '（当前编号）' : ''}</div>
                <div className="price">
                  ¥{item.price}
                  <img className="icon-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-left-arrow.png" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Mask>
      <Mask 
        visible={optionVisible} 
        onMaskClick={() => setOptionVisible(false)} 
        disableBodyScroll={true}
      >
        <div className="more-option-modal">
          <img 
            className="icon-close" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/login-icon-close.png" 
            onClick={() => setOptionVisible(false)}
          />
          {detail.status !== 3 && (
            <div className="option-item" onClick={handleSell}>
              <img className="icon" src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-product-sell-${detail.status === 1 ? 'stop' : 'start'}.png`} />
              {detail.status === 1 ? '停售作品' : '寄售作品'}
            </div>
          )}
          <div className="option-item" onClick={handleAvatar}>
            <img className="icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-product-avatar.png" />
            设为头像
          </div>
        </div>
      </Mask>
      <Mask 
        visible={noVisible} 
        onMaskClick={() => setNoVisible(false)} 
        disableBodyScroll={true}
      >
        <div className="select-no-modal">
          <img 
            className="icon-close" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/login-icon-close.png" 
            onClick={() => setNoVisible(false)}
          />
          <div className="content-super">
            <div className="title">选择编号</div>
            <div className="no-list">
              {noList.map((item, index) => (
                <div 
                  className={`item ${item.id === checkedNo.id ? 'checked' : ''}`} 
                  key={index}
                  onClick={() => handlecheckNo(item)}
                >{item.no}</div>
              ))}
            </div>
          </div>
          
          <div className="btn-super">
            <div className="price">¥ {detail.price}</div>
            <div 
              className={`btn-buy ${checkedNo.status === 3 ? 'disable' : ''}`}
              onClick={handleCreatorBuy}
            >{checkedNo.status === 3 ? '锁定中' : '立即购买'}</div>
          </div>
        </div>
      </Mask>
      <ImageViewer
        image={detail.mainPic}
        visible={imageVisible}
        onClose={() => setImageVisible(false)}
      />
    </div>
  )
}

export default Main
