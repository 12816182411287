/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-09-16 10:49:45
 */
import React, { useState, useEffect } from 'react'
import { Mask, Toast, Input } from 'antd-mobile'
import Header from '@/components/Header'
import { Link } from 'react-router-dom'
import { regExt } from '@/libs/constant'
import { dealMobile } from '@/libs/util'
import api from '@/api'
import './index.scss'

let countTimer = null

function BindCard({ history }) {
	const [userInfo, setUserInfo] = useState({})
  const [codeVisible, setCodeVisible] = useState(false)
  const [isAgree, setIsAgree] = useState(false)
  const [preBindEnable, setPreBindEnble] = useState(false)
  const [cardNo, setCardNo] = useState('')
  const [mobile, setMobile] = useState('')
  const [smsCode, setSmsCode] = useState('')
  const [uniqueCode, setUniqueCode] = useState('')
  const [confirmEnable, setConfirmEnble] = useState(false)
  const [initialCount] = useState(60)
  const [count, setCount] = useState(initialCount)
  const [sending, setSending] = useState(false)
  const [nameVisible, setNameVisible] = useState(false)

	useEffect(() => {
		api.getUserInfo().then(res => {
      setUserInfo(res)
    })
    return () => {
      // 页面关闭清除countdown
      clearInterval(countTimer)
    }
	}, [])

  useEffect(() => {
		if (isAgree && cardNo.length >= 19 && mobile.length === 11) {
      setPreBindEnble(true)
    } else {
      setPreBindEnble(false)
    }
	}, [isAgree, cardNo, mobile])

  useEffect(() => {
    if (count >= 0 && sending) {
      countTimer = setTimeout(() => {
        setCount(count - 1)
      }, 1000)
    } else {
      setCount(initialCount)
      setSending(false)
    }
  }, [count, sending])

  useEffect(() => {
		if (smsCode.length === 6) {
      setConfirmEnble(true)
    } else {
      setConfirmEnble(false)
    }
	}, [smsCode])

	const handlePreBind = () => {
		if (!preBindEnable) return
    const trueNo = cardNo.replace(/\s/g, '')
    
    if (!regExt.bankNo.test(trueNo)) {
      Toast.show({
        icon: 'fail',
        content: '银行卡号格式不正确',
      })
      return
    }
    if (!regExt.phone.test(mobile)) {
      Toast.show({
        icon: 'fail',
        content: '手机号格式不正确',
      })
      return
    }
    api.preBindCard({ mobile, cardNo: trueNo }).then(res => {
      setUniqueCode(res.uniqueCode)
      setCodeVisible(true)
      setSending(true)
      setSmsCode('')
    })
	}

  const codeModalClose = () => {
    setCodeVisible(false)
    clearInterval(countTimer)
  }

  const handleConfirm = () => {
    if (!confirmEnable) return
    api.confirmBindCard({ uniqueCode, smsCode }).then(res => {
      setUniqueCode('')
      setSmsCode('')
      codeModalClose()
      Toast.show({
        icon: 'success',
        content: '绑卡成功',
        afterClose: () => history.goBack()
      })
    })
  }

  const handleSendCode = () => {
    if (sending) return
    api.preBindCard({ mobile, cardNo: cardNo.replace(/\s/g, '') }).then(res => {
      setUniqueCode(res.uniqueCode)
      setSending(true)
      setSmsCode('')
    })
  }

  const handleCardNo = val => {
    // 每隔四位空一格
    setCardNo(val.replace(/\s/g, '').replace(/(.{4})/g, "$1 ").trim())
  }

	return (
		<div className="bind-card-container">
			<Header title="绑定银行卡" />
			<div className="header-tip">为了账户安全，仅支持绑定您实名信息下的银行卡</div>
      <div className="bind-card-content">
        <div className="label">
          姓名
          <img 
            className="icon-name" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-bindcard-name.png" 
            onClick={() => setNameVisible(true)}
          />
        </div>
        <div className="name">{userInfo.realName}</div>
        <div className="label">卡号</div>
        <Input 
          type="tel"
          placeholder='请输入卡号' 
          clearable 
          maxLength={23}
          value={cardNo}
          onChange={val => handleCardNo(val)}
        />
        <div className="label">银行卡预留手机号</div>
        <Input 
          type="tel"
          placeholder='请输入银行卡预留手机号' 
          clearable 
          maxLength={11}
          value={mobile}
          onChange={val => setMobile(val.trim())}
        />
        <div className="bank-list" onClick={() => history.push('/payment/banklist')}>查看支持银行</div>
      </div>
      <div className="footer">
        <div className="agree-super">
          <img 
            className="icon-checkbox" 
            src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/login-icon-${isAgree ? 'agree' : 'disagree'}.png`}
            onClick={() => setIsAgree(!isAgree)}
          />
          我已阅读并同意<Link className="link" to="/agree/baofu">《宝付协议》</Link>
        </div>
        <div 
          className={`confirm-btn ${preBindEnable ? 'enable' : ''}`}
          onClick={handlePreBind}
        >同意绑定银行卡</div>
      </div>
      <Mask
        visible={codeVisible} 
        onMaskClick={codeModalClose} 
      >
        <div className="code-super">
          <img 
            className="icon-close" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/login-icon-close.png" 
            onClick={codeModalClose}
          />
          <div className="title">绑定验证</div>
          <div className="content">
            <div className="phone">{dealMobile(mobile)}</div>
            <Input 
              type="tel"
              placeholder='请输入验证码' 
              clearable 
              maxLength={6}
              value={smsCode}
              onChange={val => setSmsCode(val.trim())}
            />
          </div>
          <div 
            className={`countdown-btn ${sending ? '' : 'enable'}`}
            onClick={handleSendCode}
          >{sending ? `${count}s后重试` : '获取验证码'}</div>
          <div 
            className={`confirm-btn ${confirmEnable ? 'enable' : ''}`}
            onClick={handleConfirm}
          >确定</div>
        </div>
      </Mask>
      <Mask
        visible={nameVisible} 
        onMaskClick={() => setNameVisible(false)} 
      >
        <div className="name-super">
          <div className="title">姓名说明</div>
          <div className="content">
            <div className="desc">为了提升您的服务体验，避免重复填写信息，自动读取了您的账号实名认证信息对应的姓名信息，请仔细核对是否正确</div>
            <div className="desc">为了账户安全，注意需绑定您名下的银行卡(目前仅支持借记卡)，否则会导致绑卡失败</div>
          </div>
          <div 
            className="confirm-btn enable"
            onClick={() => setNameVisible(false)}
          >知道了</div>
        </div>
      </Mask>
		</div>
	)
}

export default BindCard
