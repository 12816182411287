/*
 * @Description: 订单列表
 * @Author: 杨志航
 * @Date: 2022-07-13 09:53:05
 */
import React, { useState, useEffect } from 'react'
import { Tabs, PullToRefresh, InfiniteScroll } from 'antd-mobile'
import { orderTypes, sellTabs, buyTabs, pagination } from '@/libs/constant'
import api from '@/api'
import './index.scss'

function Order({ history }) {
  const [page, setPage] = useState(pagination.page)
  const [perPage] = useState(pagination.perPage)
  const [orderList, setOrderList] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [activeType, setActiveType] = useState(0)
  const [activeIndex, setActiveIndex] = useState(0)
  const [activeTabs, setActiveTabs] = useState(sellTabs)

  useEffect(() => {
    // 修改打开APP按钮位置
    const btnEle = document.getElementById('appDownloadBtn')
    btnEle.style.top = '.30rem'
    // 获取列表数据
    const pageStorage = localStorage.getItem('saleStatus')
    if (pageStorage) {
      const saleStatus = JSON.parse(pageStorage)
      localStorage.removeItem('saleStatus')
      setActiveType(saleStatus.type)
      setActiveIndex(saleStatus.index)
      if (saleStatus.type === 0) {
        setActiveTabs(sellTabs)
        getList({ type: 0, status: saleStatus.status })
      } else if (saleStatus.type === 1) {
        setActiveTabs(buyTabs)
        getList({ type: 1, status: saleStatus.status })
      }
    } else {
      getList({ type: 0 })
    }
    return () => {
      btnEle.style.top = '.70rem'
    }
  }, [])

  const getList = async (params = {}) => {
    const curPage = params.page ?? page
    const status = params.status ?? 0
    let obj = { page: curPage, perPage }
    if (status !== 0) {
      obj.status = status
    }
    let url = ''
    if (params.type === 0) {
      url = 'getSellOutList'
      obj.type = 1
    } else if (params.type === 1) {
      url = 'getOrderList'
    }
    const { list, total } = await api[url](obj)
    const totalPage = Math.ceil(total / perPage)

    if (curPage < totalPage) {
      setPage(curPage + 1)
      setHasMore(true)
    } else {
      setHasMore(false)
    }

    if (curPage === 1) {
      setOrderList(list)
    } else {
      setOrderList([...orderList, ...list])
    }
  }

  const handleTabChange = key => {
    const index = activeTabs.findIndex(item => item.key === key)
    setActiveIndex(index)
    setHasMore(false)
    document.documentElement.scrollTop = 0
    getList({ page: 1, type: activeType, status: activeTabs[index].status })
  }

  const changeType = key => {
    const index = orderTypes.findIndex(item => item.key === key)
    setActiveType(index)
    setActiveIndex(0)
    document.documentElement.scrollTop = 0
    if (key === 'sell') {
      setActiveTabs(sellTabs)
      getList({ page: 1, type: 0 })
    } else if (key === 'buy') {
      setActiveTabs(buyTabs)
      getList({ page: 1, type: 1 })
    }
  }

  const getTabData = status => activeTabs.find(item => item.status === status)

  const clickOrder = item => {
    // 储存列表状态
    localStorage.setItem('saleStatus', JSON.stringify({ type: activeType, status: activeTabs[activeIndex].status, index: activeIndex }))
    if (activeType === 0) {
      history.push('/order/selldetail', { id: item.consignmentId })
    } else if (activeType === 1) {
      history.push('/order/buydetail', { orderNo: item.orderNo })
    }
  }

  return (
    <div className="order-container">
      <div className="header-super">
        <div className="sale-order-header-container">
          <div onClick={() => history.goBack()} className="icon-arrow"></div>
          <Tabs 
            activeKey={orderTypes[activeType].key} 
            onChange={key => changeType(key)}
          >
            {orderTypes.map(item => <Tabs.Tab title={item.title} key={item.key}/>)}
          </Tabs>
        </div>
        <div className="tabs-super">
          <Tabs 
            activeKey={activeTabs[activeIndex].key} 
            onChange={key => handleTabChange(key)}
          >
            {activeTabs.map(item => <Tabs.Tab title={item.title} key={item.key}/>)}
          </Tabs>
        </div>
      </div>
      <PullToRefresh onRefresh={() => getList({ page: 1, type: activeType, status: activeTabs[activeIndex].status })}>
        {orderList.length === 0 ? (
          <div className="enpty-super">
            <img className="enpty-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/welfare-enpty.png" />
            <div className="text">您还没有相关订单</div>
          </div>
        ) : (
          <>
            <div className="order-list">
              {orderList.map((item, index) => (
                <div className="item" key={index} onClick={() => clickOrder(item)}>
                  <img className="pic" src={activeType === 0 ? item.mainPic : item.productPic} />
                  <div className="block">
                    <div className="info">
                      <div className="name">{item.productName}</div>
                      <div className={`status ${getTabData(item.status)?.key}`}>{getTabData(item.status)?.statusName}</div>
                    </div>
                    <div className="token">#{item.tokenNo}</div>
                    <div className="info">
                      {activeType === 0 ? (
                        <div className="price"><span>预计收入：</span>¥ {item.preIncome}</div>
                      ) : (
                        <div className="price">¥ {item.price}</div>
                      )}
                      {activeType === 1 && item.status === 1 &&
                        <img className="btn" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/order-pay-btn.png" />
                      }
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <InfiniteScroll loadMore={() => getList({ type: activeType, status: activeTabs[activeIndex].status })} hasMore={hasMore} />
          </>
        )}
      </PullToRefresh>
    </div>
  )
}

export default Order
