/*
 * @Description: 交易哈希值
 * @Author: 杨志航
 * @Date: 2022-11-07 15:57:27
 */
import React, { useState, useEffect } from 'react'
import { copyText } from '@/libs/util'
import Header from '@/components/Header'
import './index.scss'

function Deal({ location }) {
	const [detail, setDetail] = useState({})
	const [collectionVisible, setCollectionVisible] = useState(true)
	const [logsVisible, setLogsVisible] = useState(true)

	useEffect(() => {
		setDetail(location.state.data)
	}, [])

	return (
		<div className="hash-deal-container">
      <Header title="区块链信息查询" />
			<div className="blockchain-detail">
				<div className="detail-content">
					<img className="top-bg" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/certificate-top-bg.png" />
					<img className="content-bg" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/certificate-content-bg.png" />
					<img className="bottom-bg" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/certificate-bottom-bg.png" />
					<div className="detail-content-title">数文链 / 无限数藏</div>
					<img className="detail-content-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/blockchain-tit-top.png" />
					<div className="detail-content-subhead">区块链查证信息概览</div>
					<img className="detail-content-img detail-content-img-bottom" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/blockchain-tit-top.png" />
          <div className={`detail-title ${logsVisible ? '' : 'down'}`} onClick={() => setLogsVisible(!logsVisible)}>
						流转信息
						<img className="icon-arrow down" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/certificate-title-arrow.png" />
					</div>
					{logsVisible && detail.logs && (
						<div className="logs-list">
              <div className="item dot">
                <div className="label">转出地址</div>
								<div className="value">
									{detail.logs[0].fromBlockHash || '无'}
									{detail.logs[0].fromBlockHash && (
										<img 
											onClick={() => copyText(detail.logs[0].fromBlockHash)} 
											className="icon-copy" 
											src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-hash-copy.png" 
										/>
									)}
								</div>
              </div>
              <div className="item">
                <div className="item">
                  <div className="label">流转类型</div>
                  <div className="value">{detail.logs[0].typeName}</div>
                </div>
              </div>
              <div className="item">
                <div className="item">
                  <div className="label">流转时间</div>
                  <div className="value">{detail.logs[0].txTime}</div>
                </div>
              </div>
              <div className="item dot">
                <div className="label">转入地址</div>
								<div className="value">
									{detail.logs[0].toBlockHash}
									<img 
										onClick={() => copyText(detail.logs[0].toBlockHash)} 
										className="icon-copy" 
										src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-hash-copy.png" 
									/>
								</div>
              </div>
            </div>
					)}
					<div className={`detail-title ${collectionVisible ? '' : 'down'}`} onClick={() => setCollectionVisible(!collectionVisible)}>
						藏品信息
						<img className="icon-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/certificate-title-arrow.png" />
					</div>
					{collectionVisible && (
						<div className="info-list">
							<div className="item">
								<div className="label">藏品名称</div>
								<div className="value">{detail.productName}</div>
							</div>
							<div className="item">
								<div className="label">合约地址</div>
								<div className="value">
									{detail.productBlockHash}
									<img 
										onClick={() => copyText(detail.productBlockHash)} 
										className="icon-copy" 
										src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-hash-copy.png" 
									/>
								</div>
							</div>
							<div className="item">
								<div className="label">藏品编号</div>
								<div className="value">{detail.tokenNo || '无'}</div>
							</div>
							<div className="item">
								<div className="label">创作者</div>
								<div className="value">{detail.productCreatorName}</div>
							</div>
							<div className="item">
								<div className="label">发行方</div>
								<div className="value">{detail.productIssuerName}</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default Deal
