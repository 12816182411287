/*
 * @Description: 发布寄售
 * @Author: 杨志航
 * @Date: 2023-02-20 11:24:54
 */
import React, { useState, useEffect, useRef } from 'react'
import HeaderWhite from '@/components/HeaderWhite'
import { Mask, Toast } from 'antd-mobile'
import api from '@/api'
import './index.scss'

function Detail({ history, location }) {
  const { state } = location
  const [price, setPrice] = useState('')
  const [detail, setDetail] = useState({})
  const [userInfo, setUserInfo] = useState({})
  const [isAgree, setIsAgree] = useState(false)
  const [tipVisible, setTipVisible] = useState(false)
  const [incomeVisible, setIncomeVisible] = useState(false)
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [password, setPassword] = useState('')
  const inputRef = useRef(null)

  useEffect(() => {
    // 发布详情
    getDetail()
    // 用户信息
    api.getUserInfo().then(res => {
      setUserInfo(res)
    })
  }, [])

  const getDetail = () => {
    api.getPushDetail({ userProductId: state.id }).then(res => {
      setDetail(res)
    })
  }

  const handleChangeInput = evt => {
    const val = evt.target.value.replace(/[^0-9]/g,'')
    setPrice(val)
  }

  const handleSubmit = () => {
    if (userInfo.hasOptPassword === 2) {
      setTipVisible(true)
      return
    }
    if (!isAgree) {
      Toast.show({
        content: '请先阅读并勾选协议',
      })
      return
    }
    if (!price) {
      Toast.show({
        content: '请设置售价',
      })
      return
    }
    if (Number(price) < Number(detail.consignmentAmountLowerLimit)) {
      Toast.show({
        content: `寄售单价不得低于${detail.consignmentAmountLowerLimit}元`,
      })
      return
    }
    if (Number(price) > Number(detail.consignmentAmountLimit)) {
      Toast.show({
        content: `寄售价格最高设置${detail.consignmentAmountLimit}元`,
      })
      return
    }
    setPasswordVisible(true)
    inputRef.current.focus()
  }

  const transFee = fee => {
    let val = 0
    if (price && price > 0) {
      val = price * fee / 100
    }
    return val.toFixed(2)
  }

  const calcIncome = () => {
    let val = 0
    if (price && price > 0) {
      val = price * (100 - detail.technicalServiceFee - detail.payChannelFee - detail.blockchainServiceFee) / 100
    }
    return val.toFixed(2)
  }

  const handleCode = evt => {
    const val = evt.target.value
    setPassword(val)
    if (val.length === 6) {
      const param = {
        userProductId: detail.userProductId,
        price,
        preIncome: calcIncome(),
        operatPassword: val,
      }
      api.pushConsignment(param).then(() => {
        Toast.show({
          icon: 'success',
          content: '操作成功',
          afterClose: () => history.goBack()
        })
      }).catch(err => {
        if (err.code === 2) {
          handleClose()
          Toast.show({
            icon: 'fail',
            content: err.message || '服务器发生错误，请联系管理员',
            afterClose: () => getDetail()
          })
        } else {
          setPassword('')
        }
      })
    }
  }

  const handleClose = () => {
    setPasswordVisible(false)
    setPassword('')
  }

  const handleClick = () => {
    inputRef.current.focus()
    inputRef.current.setSelectionRange(100, 100)
  }

  const handleSet = () => {
    localStorage.setItem('needBack', 1)
    history.push('/password', { hasOptPassword: userInfo.hasOptPassword })
  }

  return (
    <div className="publish-detail-container">
      <HeaderWhite title="设置售价" />
      <div className="banner-super">
        <img className="main-pic" src={detail.mainPic} />
        <div className="name-super">
          <div className="name">{detail.productName}</div>
          <div className="token">
            <span className="num">#{detail.tokenNo?.split('/')[0]}</span>
            /{detail.tokenNo?.split('/')[1]}
          </div>
        </div>
      </div>
      <div className="form-super">
        <div className="title">设置售价</div>
        <div className="price-super">
          <div className="origin-price">购入价格：¥{detail.price}</div>
          <div className="input-super">
            <span className="yuan">¥</span>
            <input 
              className="price-value-input"
              type="tel" 
              maxLength={6}
              value={price}
              onChange={handleChangeInput}
              placeholder="输入单价"
            />
          </div>
        </div>
      </div>
      <div className="calc-super">
        <div className="fee-list">
          {detail.technicalServiceFee != 0 && (
            <div className="item">
              <div className="label">技术服务费({detail.technicalServiceFee}%)</div>
              <div className="value">¥{transFee(detail.technicalServiceFee)}</div>
            </div>
          )}
          {detail.payChannelFee !=0 && (
            <div className="item">
              <div className="label">支付通道费({detail.payChannelFee}%)</div>
              <div className="value">¥{transFee(detail.payChannelFee)}</div>
            </div>
          )}
          {detail.blockchainServiceFee !=0 && (
            <div className="item">
              <div className="label">区块链服务费({detail.blockchainServiceFee}%)</div>
              <div className="value">¥{transFee(detail.blockchainServiceFee)}</div>
            </div>
          )}
        </div>
        <div className="income-super">
          <div className="label">
            预计收入
            <img 
              className="icon" 
              src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-price-tip.png" 
              onClick={() => setIncomeVisible(true)}
            />
          </div>
          <div className="value">
            <span className="yuan">¥</span>{calcIncome()}
          </div>
        </div>
      </div>
      <div className="intro-super">
        <div className="title">寄售须知</div>
        <div className="text">1、藏品未经购买前，可以在“寄售记录-我卖出的”修改寄售金额或取消寄售。一经购买，不再支持任何修改操作；</div>
        <div className="text">2、藏品出售成功后，您将获得扣除各项服务费之后的售卖收入，收入默认进入您的钱包账户，请您提前在个人中心-我的钱包开通钱包服务；</div>
        <div className="text">3、藏品出售的增值收入部分，请自行申报个人所得税。</div>
      </div>
      <div className="btn-super">
        <div className="agree-super">
          <img 
            className="icon-checkbox" 
            src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/login-icon-${isAgree ? 'agree' : 'disagree'}.png`}
            onClick={() => setIsAgree(!isAgree)}
          />
          我已阅读并同意<span className="link" onClick={() => history.push('/agree/sale')}>《寄售协议》</span>
        </div>
        <div 
          className="code-btn"
          onClick={handleSubmit}
        >确认寄售</div>
      </div>
      <input 
        className="password-value-input"
        type="tel" 
        ref={inputRef}
        maxLength={6}
        value={password}
        onChange={handleCode}
      />
      <Mask visible={tipVisible} onMaskClick={() => setTipVisible(false)}>
        <div className="wallet-tip-modal">
          <div className="overlay-content-top">
            <div className="top-title">未设置操作密码</div>
            <div className="top-content">您的账号暂未设置操作密码，为保<br/>障您的账号安全，需先设置完成后<br/>才能使用寄售功能</div>
          </div>
          <div className="overlay-content-bottom">
            <div className="vertical-bar"></div>
            <div className="left" onClick={() => setTipVisible(false)}>稍后再说</div>
            <div className="right" onClick={handleSet}>设置密码</div>
          </div>
        </div>
      </Mask>
      <Mask visible={incomeVisible} onMaskClick={() => setIncomeVisible(false)}>
        <div className="income-tip-modal">
          <img className="icon-close" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-black-close.png" 
            onClick={() => setIncomeVisible(false)}
          />
          <div className="title">预计收入说明</div>
          <div className="content">预计收入是平台扣除手续费后您的预估收入金额，实际收入以您钱包具体到账为准</div>
          <div className="btn" onClick={() => setIncomeVisible(false)}>我知道了</div>
        </div>
      </Mask>
      <Mask visible={passwordVisible} onMaskClick={handleClose}>
        <div className="input-password-super">
          <img 
            className="icon-close" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-black-close.png" 
            onClick={handleClose}
          />
          <div className='content' onClick={handleClick}>
            <div className="title">输入密码</div>
            <div className="subtitle">请输入操作密码</div>
            <div className="box-list">
              {new Array(6).fill('').map((item, index) => (
                <div className="box-item" key={index}>
                  <div className={`dot ${password.length < index + 1 ? 'hide' : ''}`}></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Mask>
    </div>
  )
}

export default Detail
