/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-10-27 14:37:49
 */
import React, { useEffect, useState } from 'react'
import Header from '@/components/Header'
import { Input, Toast } from 'antd-mobile'
import { regExt } from '@/libs/constant'
import api from '@/api'
import './index.scss'

function Search({ history, location }) {
  const { state } = location
  const [value, setValue] = useState('')
  const [enptyVisible, setEnptyVisible] = useState(false)
  const [userVisible, setUserVisible] = useState(false)
  const [userInfo, setUserInfo] = useState({})
  const [userChecked, setUserChecked] = useState(false)

  useEffect(() => {
    // 修改打开APP按钮位置
    const btnEle = document.getElementById('appDownloadBtn')
    btnEle.style.top = '.30rem'
    return () => {
      btnEle.style.top = '.70rem'
    }
  }, [])

  const handleSearch = () => {
    if (!regExt.phone.test(value)) {
      Toast.show({
        icon: 'fail',
        content: '请输入正确的11位手机号',
      })
      return
    }
    api.userQuery({ mobile: value }).then(res => {
      const { total, list } = res
      if (total === 0) {
        setEnptyVisible(true)
        setUserVisible(false)
      } else {
        setUserInfo(list[0])
        setUserVisible(true)
        setEnptyVisible(false)
      }
    })
  }

  const handleNext = () => {
    if (userChecked) {
      history.push('/collection/confirm', { id : state.id, toUserId: userInfo.id })
    }
  }

  return (
    <div className="search-user-container">
      <Header title="请选择转赠对象" />
      <div className="form-super">
        <img className="icon-search" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-search.png" />
        <Input 
          type="tel" 
          placeholder='输入对方手机号' 
          clearable 
          maxLength={11}
          value={value}
          onChange={val => setValue(val.trim())}
        />
        <div className="search-btn" onClick={handleSearch}>搜索</div>
      </div>
      {enptyVisible && (
        <div className="enpty-super">
          <img className="icon-enpty" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-search-enpty.png" />
          <div className="tip">该手机号尚未注册无限数藏，<br />无法进行转赠</div> 
          <div className="btn" onClick={() => history.push('/invite')}>去邀请</div>
        </div>
      )}
      {userVisible && (
        <div className="user-super">
          <div className="tip">请选择转赠对象</div>
          <div className="content">
            <div className="info">
              <img className="avatar" src={userInfo.avatar} />
              {userInfo.nickname}
            </div>
            <img 
              className="icon-radio" 
              src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-user-${userChecked ? 'checked' : 'uncheck'}.png`}
              onClick={() => setUserChecked(!userChecked)}
            />
          </div>
          <div className={`btn ${userChecked ? 'enable' : ''}`} onClick={handleNext}>下一步</div>
        </div>
      )}
    </div>
  )
}

export default Search
