/*
 * @Description: 发布寄售商品
 * @Author: 杨志航
 * @Date: 2023-02-17 13:52:51
 */
import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import Main from './Main'
import Detail from './Detail'

class Publish extends Component {
  render () {
    const { path } = this.props.match

    return (
      <div>
        <Switch>
          <Route exact path={path + '/detail'} component={Detail}/>
          <Route component={Main} />
        </Switch>
      </div>
    )
  }
}

export default withRouter(Publish)
