/*
 * @Description: canvas工具
 * @Author: 杨志航
 * @Date: 2022-11-08 16:53:15
 */
import { generateUUID } from '@/libs/util'

export default class CanvasTool {
  constructor({ ctx }) {
    this.ctx = ctx
  }

  drawImage (url, x, y, width, height) {
    // 使用promise使图片按照顺序绘制
    return new Promise(resolve => {
      const img = new Image()
      // 设置跨域属性，因为使用跨域图片时，无法将canvas转成图片下载
      img.setAttribute('crossOrigin','anonymous')
      img.onload = () => {
        // 参数 1：要绘制的 img   参数 2、3：绘制的 img 在 canvas 中的坐标 参数4,5是width，height
        this.ctx.drawImage(img, x, y, width, height)
        resolve()
      }
      // 加后缀解决跨域报错问题
      const signal = `${url.indexOf('?') > -1 ? '&' : '?'}uuid=${generateUUID()}`
      img.src = url + signal
    })
  }

  drawText (obj) {
    const { color, size, align, baseline, text, maxWidth, bold, x, y } = obj
    // 字体的颜色
    this.ctx.fillStyle = color
    // 字体的字号
    this.ctx.font = `${size}px PingFangSC-Regular, PingFang SC`
    // 文字的对齐，可选值 'left'、'center'、'right'
    this.ctx.textAlign = align || 'left'
    // 设置文字的水平对齐，可选值 'normal'、'top'、'middle'、'bottom'
    this.ctx.textBaseline = baseline || 'top'
    let drawText = text
    const lines = this.getTextLine({ size, text, width: maxWidth })
    // 超过最大长度
    if (lines.length > 1) {
      drawText = lines[0].slice(0, -1) + '...'
    }
    // 是否加粗
    if (bold) {
        this.ctx.fillText(drawText, x, y - 0.5)
        this.ctx.fillText(drawText, x - 0.5, y)
    }
    this.ctx.fillText(drawText, x, y)
    if (bold) {
        this.ctx.fillText(drawText, x, y + 0.5)
        this.ctx.fillText(drawText, x + 0.5, y)
    }
  }

  getTextLine (obj) {
    const { size, text, width } = obj
    this.ctx.font = `${size}px PingFangSC-Regular, PingFang SC`
    const arrText = text.split('')
    let line = ''
    let arrTr = []
    for (let i = 0; i < arrText.length; i++) {
      const testLine = line + arrText[i]
      const metrics = this.ctx.measureText(testLine)
      if (metrics.width > width && i > 0) {
        arrTr.push(line)
        line = arrText[i]
      } else {
        line = testLine
      }
      if (i == arrText.length - 1) {
        arrTr.push(line)
      }
    }
    return arrTr
  }
}
