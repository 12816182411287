/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-07-05 11:23:02
 */
import React, { useState, useEffect } from 'react'
import { copyText } from '@/libs/util'
import { myMenus } from '@/libs/constant'
import { Mask } from 'antd-mobile'
import TabBar from '@/components/TabBar'
import api from '@/api'
import './index.scss'

function Main({ history }) {
  const token = localStorage.getItem('token')
  const [serviceVisible, setServiceVisible] = useState(false)
  const [cerVisible, setCerVisible] = useState(false)
  const [userInfo, setUserInfo] = useState({})

  useEffect(() => {
    if (token) {
      api.getUserInfo().then(res => {
        setUserInfo(res)
      })
    }
  }, [])

  const handleClickMenu = link => {
    if (token) {
      if (link === '/invite' && userInfo.isCert === 2) {
        setCerVisible(true)
      } else {
        history.push(link)
      }
    } else {
      history.push('/login')
    }
  }

  return (
    <div className="my-container">
      <div className="my-container-header">
        <img 
          onClick={() => handleClickMenu('/message')} 
          className="my-container-message" 
          src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-message${userInfo.hasUnRead === 1 ? '-dot': ''}.png`}
        />
        <img onClick={() => {
          if (token) {
            setServiceVisible(true)
          } else {
            history.push('/login')
          }
        }} className="my-container-service" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-service.png" />
      </div>
      <div className="my-container-name">
        <img className="name-img" src={userInfo.avatar ? userInfo.avatar : 'https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/default-avatar.png'} />
        {!token ? <div className="name-title" onClick={() => history.push('/login')}>
          <div className="name-title-top">立即登录</div>
          <div className="name-title-bottom">登录查看藏品</div>
        </div> : <div className="name-title">
          <div className="name-title-top">
            <div className="nickname">{userInfo.nickname ? userInfo.nickname : '立即登录'}</div>
            {userInfo.medalList && userInfo.medalList.medal_1 === 1 && (
              <img className="medal" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/constructor-medal.png" />
            )}
          </div>
          <div className="name-title-bottom">区块链地址：{userInfo.blockHash ? userInfo.blockHash : '实名认证后可自动生成'}</div>
          {userInfo.blockHash &&<img onClick={() => copyText(userInfo.blockHash)} className="name-qukuailian" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/qukuailiandizhi.png" />}
        </div>}
      </div>
      <div className="my-container-autonyms">
        {userInfo.isCert === 2 &&  <div className="my-container-autonym">
          <div>未实名认证，无法购买数字藏品哦</div>
          <img 
            className="authentication" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/authentication.png"
            onClick={() => history.push('/my/certification')}
          />
        </div>}
      </div>
      <div className="my-container-center">
        {myMenus.map((item, index) => (
          <div className="item" key={index} onClick={() => handleClickMenu(item.link)}>
            <img className="my-container-icon-img" src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-my-${item.key}.png`} />
            <div className="my-container-center-text">{item.title}</div>
          </div>
        ))}
      </div>
      <TabBar active={3} />
      <Mask visible={serviceVisible} onMaskClick={() => setServiceVisible(false)}>
        <div className="modal-contact-content">
          <div className="mask-title">请添加我的联系方式</div>
          <img 
            className="mask-img" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/contact-way.png" />
          <div className="mask-hint">长按保存</div>
        </div>
      </Mask>
      <Mask 
        visible={cerVisible} 
        onMaskClick={() => setCerVisible(false)} 
      >
        <div className="overlay-content">
          <div className="overlay-content-top">
            <div className="top-title">实名认证提示</div>
            <div className="top-content">请您先完成实名认证</div>
          </div>
          <div className="overlay-content-bottom">
            <div className="vertical-bar"></div>
            <div className="left" onClick={() => setCerVisible(false)}>稍后再说</div>
            <div className="right" onClick={() => history.push('/my/certification')}>去认证</div>
          </div>
        </div>
      </Mask>
    </div>
  )
}

export default Main
