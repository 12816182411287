/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-08-10 08:55:57
 */
const redBallSrc = 'https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/ball-red.png'
const goldBallSrc = 'https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/ball-gold.png'
const purpleBallSrc = 'https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/ball-purple.png'
const DAMPING = 0.7 // 阻尼
const GRAVITY = 0.6 // 重力
const SPEED = 1 // 速度
const MOUSE_SIZE = 30;

class Ball {
  constructor({ x, y, ratio, rotate, radius }) {
    this.x = x;
    this.y = y;

    this.px = x;
    this.py = y;

    this.fx = 0;
    this.fy = 0;

    this.radius = radius;

    this.rotate = rotate;
    this.ratio = ratio;
  }

  // 作用力
  apply_force(delta) {
    delta *= delta;

    this.fy += GRAVITY;

    this.x += this.fx * delta;
    this.y += this.fy * delta;

    this.fx = this.fy = 0;
  }

  verlet() {
    let nx = this.x * 2 - this.px;
    let ny = this.y * 2 - this.py;

    this.px = this.x;
    this.py = this.y;

    this.x = nx;
    this.y = ny;
  }

  draw(context, img) {
    const { x, y, rotate, radius } = this;
    context.save();
    context.translate(x, y);
    context.rotate(rotate);
    context.drawImage(img, -radius, -radius, radius * 2, radius * 2);
    context.translate(-x, -y);
    context.restore();
  }
}

class LotteryMachine {
  constructor(opt = {}) {
    let ratio = 2;
    let def = {
      id: 'balls',
      width: window.innerWidth,
      ratio: ratio,
      count: 12, // 球的个数
      size: 28 / (ratio / 2), // 球的大小
      colors: [
        '#c15bb6',
        '#8380c3',
        '#ffde7f',
        '#ff8f03',
        '#ec5767',
        '#ff8085',
      ],
    };
    this.ratio = 2;
    this.playing = false;
    this.stick = {
      x: 0,
      y: 0,
      angle: (Math.PI * 360) / 180,
      speed: 16,
    };
    this.requestID = null;
    this.opt = Object.assign({}, def, opt);
    this.balls = [];
    this.obstacles = [];

    this.init();
  }

  init() {
    // 初始化画布
    const { id, ratio, count, width } = this.opt;
    const canvas = document.getElementById(id);
    this.canvas = canvas;
    this.canvas.width = width;
    this.canvas.height = width;
    this.context = canvas.getContext('2d');
    this.context.scale(ratio, ratio);
    this.context.save();

    // 创建球
    this.balls = new Array(Math.abs(count))
      .fill(0)
      .map((item) => this.createBall());

    this.createObstacles();
    // 加载资源
    this.loadResources();

    return this;
  }
  loadResources() {
    Promise.all([
      this.loadBall(redBallSrc),
      this.loadBall(goldBallSrc),
      this.loadBall(purpleBallSrc),
    ]).then((images) => {
      this.redBall = images[0];
      this.goldBall = images[1];
      this.purpleBall = images[2];
      this.animate();
    });
  }

  play() {
    this.playing = true;
  }

  stop() {
    this.playing = false;
  }

  distroy() {
    window.cancelAnimationFrame(this.requestID)
  }

  // 创建球体
  createBall() {
    let pos = this.getBallPosition();
    return new Ball(pos);
  }

  animate() {
    this.requestID = window.requestAnimationFrame(this.animate.bind(this));

    const { balls, playing } = this;
    let iter = 20;

    let delta = SPEED / iter;

    while (iter--) {
      let i = balls.length;

      while (i--) {
        balls[i].apply_force(delta);
        balls[i].verlet();
      }

      this.resolveCollisions();
      this.checkWalls();

      let j = balls.length;
      while (j--) balls[j].verlet();

      this.resolveCollisions(1);
      this.checkWalls();
    }

    this.drawBalls();

    playing && this.mixing();
  }

  loadBall(src) {
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.onload = (e) => {
        resolve(image);
      };
      image.src = src;
    });
  }

  drawBalls() {
    const { balls, context, redBall, goldBall, purpleBall } = this;
    const { width } = this.opt;
    let i = balls.length;
    context.clearRect(0, 0, width, width);
    while (i--) {
      if (0 <= i && i < 4) {
        balls[i].draw(context, redBall);
      }
      if (4 <= i && i < 8) {
        balls[i].draw(context, goldBall);
      }
      if (8 <= i) {
        balls[i].draw(context, purpleBall);
      }
    }
  }
  drawObstacles() {
    const { context, obstacles } = this;
    let i = obstacles.length;
    context.fillStyle = 'rgba(27,155,244,0.3)';
    while (i--) {
      let obstacle = obstacles[i];
      context.fillStyle = 'rgba(0,0,0,0.1)';
      context.strokeStyle = 'rgba(0,0,0,0.2)';

      context.beginPath();
      context.arc(obstacle.x, obstacle.y, obstacle.radius, 0, Math.PI * 2);
      context.fill();
      context.stroke();
    }
  }

  createObstacles() {
    const { canvas, ratio } = this;
    let radius = canvas.width / ratio / 2;
    for (let i = 0; i < 180; i++) {
      let angle = (Math.PI * i * 2) / 180;
      let size = 4;
      let y = Math.sin(angle) * (radius + size / 2) + radius;
      let x = Math.cos(angle) * (radius + size / 2) + radius;
      this.obstacles.push({
        x,
        y,
        radius: size,
      });
    }
  }

  // 搅动
  mixing() {
    const { stick, canvas } = this;
    if (stick.x <= 0) {
      this.stick.speed = 16;
    }
    if (stick.x >= canvas.width / 2) {
      this.stick.speed = -16;
    }
    this.stick.x = stick.x + stick.speed;
    this.stick.y = canvas.width / 2;
  }

  // 碰撞检测
  resolveCollisions(ip) {
    const { balls, playing, stick, canvas, obstacles } = this;
    let i = balls.length;

    while (i--) {
      let ball_1 = balls[i];

      if (playing) {
        let diff_x = ball_1.x - stick.x;
        let diff_y = ball_1.y - stick.y;
        let dist = Math.sqrt(diff_x * diff_x + diff_y * diff_y);
        let real_dist = dist - (ball_1.radius + MOUSE_SIZE);

        if (real_dist < 0) {
          let depth_x = diff_x * (real_dist / dist);
          let depth_y = diff_y * (real_dist / dist);

          ball_1.x -= depth_x * 0.005;
          ball_1.y -= depth_y * 0.005;
        }
      }
      // 障碍物
      let k = obstacles.length;
      while (k--) {
        let obstacle = obstacles[k];
        let diff_x = ball_1.x - obstacle.x;
        let diff_y = ball_1.y - obstacle.y;
        let dist = Math.sqrt(diff_x * diff_x + diff_y * diff_y);
        let real_dist = dist - (ball_1.radius + obstacle.radius);

        if (real_dist < 0) {
          let depth_x = diff_x * (real_dist / dist);
          let depth_y = diff_y * (real_dist / dist);

          ball_1.x -= depth_x * 0.005;
          ball_1.y -= depth_y * 0.005;
        }
      }

      let n = balls.length;

      while (n--) {
        if (n == i) continue;

        let ball_2 = balls[n];

        let diff_x = ball_1.x - ball_2.x;
        let diff_y = ball_1.y - ball_2.y;

        let length = diff_x * diff_x + diff_y * diff_y;
        let dist = Math.sqrt(length);
        let real_dist = dist - (ball_1.radius + ball_2.radius);

        if (real_dist < 0) {
          let vel_x1 = ball_1.x - ball_1.px;
          let vel_y1 = ball_1.y - ball_1.py;
          let vel_x2 = ball_2.x - ball_2.px;
          let vel_y2 = ball_2.y - ball_2.py;

          let depth_x = diff_x * (real_dist / dist);
          let depth_y = diff_y * (real_dist / dist);

          ball_1.x -= depth_x * 0.5;
          ball_1.y -= depth_y * 0.5;

          ball_2.x += depth_x * 0.5;
          ball_2.y += depth_y * 0.5;

          if (ip) {
            let pr1 = (DAMPING * (diff_x * vel_x1 + diff_y * vel_y1)) / length,
              pr2 = (DAMPING * (diff_x * vel_x2 + diff_y * vel_y2)) / length;

            vel_x1 += pr2 * diff_x - pr1 * diff_x;
            vel_x2 += pr1 * diff_x - pr2 * diff_x;

            vel_y1 += pr2 * diff_y - pr1 * diff_y;
            vel_y2 += pr1 * diff_y - pr2 * diff_y;

            ball_1.px = ball_1.x - vel_x1;
            ball_1.py = ball_1.y - vel_y1;

            ball_2.px = ball_2.x - vel_x2;
            ball_2.py = ball_2.y - vel_y2;
          }
        }
      }
    }
  }

  // 检测边界
  checkWalls() {
    const { balls, canvas } = this;
    let i = balls.length;

    while (i--) {
      let ball = balls[i];

      if (ball.x < ball.radius) {
        let vel_x = ball.px - ball.x;
        ball.x = ball.radius;
        ball.px = ball.x - vel_x * DAMPING;
      } else if (ball.x + ball.radius > canvas.width / 2) {
        let vel_x = ball.px - ball.x;
        ball.x = canvas.width / 2 - ball.radius;
        ball.px = ball.x - vel_x * DAMPING;
      }

      if (ball.y < ball.radius) {
        let vel_y = ball.py - ball.y;
        ball.y = ball.radius;
        ball.py = ball.y - vel_y * DAMPING;
      } else if (ball.y + ball.radius > canvas.height / 2) {
        let vel_y = ball.py - ball.y;
        ball.y = canvas.height / 2 - ball.radius;
        ball.py = ball.y - vel_y * DAMPING;
      }
    }
  }

  // 生成球的位置信息
  getBallPosition() {
    let angle = (Math.PI * Math.random() * 360) / 180;
    let randomShift = 0;
    const { width, size, ratio } = this.opt;
    let radius = width / ratio / 2;
    let y = Math.sin(angle) * (radius - size / 2) + radius + randomShift;
    let x = Math.cos(angle) * (radius - size / 2) + radius + randomShift;
    return {
      x,
      y,
      rotate: (Math.PI * Math.random() * 360) / 180,
      ratio: 2,
      radius: size / 2,
    };
  }
}

export default LotteryMachine
