/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-10-24 14:42:22
 */
import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import Main from './Main'
import Code from './Code'
import Set from './Set'
import Validate from './Validate'
import Reset from './Reset'

class My extends Component {
  render () {
    const { path } = this.props.match

    return (
      <div>
        <Switch>
          <Route exact path={path + '/code'} component={Code}/>
          <Route exact path={path + '/set'} component={Set}/>
          <Route exact path={path + '/validate'} component={Validate}/>
          <Route exact path={path + '/reset'} component={Reset}/>
          <Route component={Main} />
        </Switch>
      </div>
    )
  }
}

export default withRouter(My)
