/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-07-15 13:54:31
 */
import React from 'react'
import { tabBarMenus } from '@/libs/constant'
import { withRouter } from 'react-router-dom'
import './index.scss'

function TabBar ({ active =  0, history }) {

  const handleClick = key => {
    if (key !== tabBarMenus[active].key) {
      history.push(`/${key}`)
    }
  }

  return (
    <div className="tab-bar-container">
      {tabBarMenus.map((item, index) => (
        <div 
          className={`tab-item ${index === active ? 'current' : ''}`} 
          key={index} 
          onClick={() => handleClick(item.key)}
        >
          <img className="icon-img" src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-${index === active ? item.key + '-current' : item.key}.png`} />
          <div>{item.title}</div>
        </div>
      ))}
    </div>
  )
}

export default withRouter(TabBar)
