/*
 * @Description: 
 * @Author: 杨志航<yangzhihang@372163.com>
 * @Date: 2021-11-23 16:12:45
 */
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom'
import Home from './pages/Home'
import My from './pages/My'
import NoMatch from './pages/404'
import Login from './pages/Login'
import Product from './pages/Product'
import Agree from './pages/Agree'
import Collection from './pages/Collection'
import Order from './pages/Order'
import Message from './pages/Message'
import Abandon from './pages/Abandon'
import Lottery from './pages/Lottery'
import Welfare from './pages/Welfare'
import Invite from './pages/Invite'
import Payment from './pages/Payment'
import Topic from './pages/Topic'
import Password from './pages/Password'
import Hash from './pages/Hash'
import Compose from './pages/Compose'
import Wallet from './pages/Wallet'
import Publish from './pages/Publish'
import Sale from './pages/Sale'
import Creator from './pages/Creator'
import Announce from './pages/Announce'

export default () => {
    return (
        <Switch>
            <Route exact path="/" render={() => <Redirect to="/home" push />} />   
            <Route path="/home" component={Home} />
            <Route path='/my' component={My} />
            <Route path='/login' component={Login} />
            <Route path='/product' component={Product} />
            <Route path='/agree' component={Agree} />
            <Route path='/collection' component={Collection} />
            <Route path='/order' component={Order} />
            <Route path='/message' component={Message} />
            <Route path='/abandon' component={Abandon} />
            <Route path='/lottery' component={Lottery} />
            <Route path='/welfare' component={Welfare} />
            <Route path='/invite' component={Invite} />
            <Route path='/payment' component={Payment} />
            <Route path='/topic' component={Topic} />
            <Route path='/password' component={Password} />
            <Route path='/hash' component={Hash} />
            <Route path='/compose' component={Compose} />
            <Route path='/wallet' component={Wallet} />
            <Route path='/publish' component={Publish} />
            <Route path='/sale' component={Sale} />
            <Route path='/creator' component={Creator} />
            <Route path='/announce' component={Announce} />
            <Route component={NoMatch} />
        </Switch>
    )
}
