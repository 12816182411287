/*
 * @Description: 应用权限申请与使用情况说明
 * @Author: 杨志航
 * @Date: 2022-06-13 17:08:04
 */
import React, { useEffect } from 'react'
import Header from '@/components/Header'
import './index.scss'

function Useage ({ location }) {
  const { state } = location

  useEffect(() => {
    if (state) {
      scrollToAnchor(state.id)
    } else {
      scrollToAnchor('top')
    }
  }, [])

  const scrollToAnchor = id => {
    const anchorElement = document.getElementById(id)
    if(anchorElement) { 
      anchorElement.scrollIntoView({ behavior: 'auto' })
    }
  }

  return (
    <div className="agree-container" id="top">
      <Header />
      <div className="agree-content-super">
        <div className="agree-title">《应用权限申请与使用情况说明》</div>
        <div>系统权限申请与使用说明为了向您提供服务以及保障您的账户安全，无限数藏APP可能需要在必要范围内向您申请获取下列权限。请您放心，无限数藏APP不会默认开启这些权限，仅在您主动确认开启的情况下，无限数藏APP才有可能通过这些权限收集您的信息。特别需要说明的是，无限数藏APP获取具体某一项权限并不代表无限数藏APP必然会收集您的信息；即使您已经确认向无限数藏APP开启权限，无限数藏APP也仅会在合法、正当、必要的范围内收集您的信息。</div>
        <div>Android操作系统</div>
        <img className="agree-img" style={{ paddingTop: '.20rem' }} src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/and-system.png" />
        <div>iOS操作系统</div>
        <img className="agree-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/ios-system.png" />
        <div className="anchor-sdk" id="sdk"></div>
        <div className="agree-title">《第三方SDK目录说明》</div>
        <div style={{ paddingTop: '.40rem' }}>为保障“无限数藏APP”服务的相关功能的实现与应用安全稳定的运行，我们可能会接入由第三方提供的软件开发包（SDK）或类似应用程序实现相关目的。我们会对合作方获取信息的软件工具开发包（SDK）或类似应用程序进行严格的安全评估，以保护您的个人信息安全。以下是我们接入的主要的第三方服务商的信息： <br/>SDK名称：友盟移动统计<br/>SDK使用目的：数据统计分析服务，地理位置校准报表数据准确性，提供基础反作弊<br/>服务数据类型：Mac地址、唯一设备识别码（IMEI、android ID、IDFA、OPENUDID、GUID、SIM卡、IMSI信息）<br/>来源：https://www.umeng.com/page/policy<br/><br/>QQ分享SDK<br/>收集个人信息用途/目的：帮助用户将内容分享至QQ<br/>收集个人信息类型：网络信息<br/>运营方：深圳市腾讯计算机系统有限公司<br/>隐私权政策链接：https://docs.qq.com/doc/p/8ca74fba95bca4a30eeee1044050b510300d5382?dver=2.1.27013722<br/>适用操作系统：安卓、iOS<br/><br/>微信SDK<br/>收集个人信息用途/目的：帮助用户将内容分享至微信<br/>收集个人信息类型：设备信息（IMEI、IMSI、MAC地址、Android ID）、网络信息<br/>运营方：深圳市腾讯计算机系统有限公司<br/>隐私权政策链接：https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy<br/>适用操作系统：安卓、iOS<br/><br/>SDK名称：微信SDK<br/>使用目的：支付<br/>数据类型：设备标识信息、手机IEMI、Android ID、MAC地址、IMSI信息<br/>来源： http://developers.weixin.qq.com/doc/oplatform/Mobile_APP/Access_Guide/Android.html</div>
      </div>
    </div>
  )
}

export default Useage
