/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-09-15 13:58:12
 */
import React from 'react'
import Header from '@/components/Header'
import { bankNames } from '@/libs/constant'
import './index.scss'

function BankList() {

	return (
		<div className="bank-list-container">
      <Header title="支持银行" />
      <div className="list-super">
        {bankNames.map((item, index) => (
          <div className="item" key={index}>
            <img className="icon" src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-bank-${item.key}.png`} />
            {item.title}
          </div>
        ))}
      </div>
		</div>
	)
}

export default BankList
