/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2023-01-05 17:07:07
 */
import React, { useState, useEffect } from 'react'
import Header from '@/components/Header'
import api from '@/api'
import './index.scss'

function Progress({ history, location }) {
  const { state } = location
  const [detail, setDetail] = useState({})
  const [productList, setProductList] = useState([])
  
  useEffect(() => {
    api.getProductProgress({ id: state.id }).then(res => {
      const { info, list, numHold, numTotal } = res
      const bannerInfo = {
        ...info,
        numHold,
        numTotal,
        process: `${Math.floor(numHold / numTotal * 10000) / 100}%`
      }
      setDetail(bannerInfo)
      setProductList(list)
    })
  }, [])

  return (
    <div className="product-progress-container">
      <Header title="收集进度" />
      <div className="progress-banner">
        <div className="info-super">
          <img className="main-pic" src={detail.mainPic} />
          <div className="title-super">
            <div className="title">{detail.name}</div>
            <div className="btn" onClick={() => history.goBack()}>去收集</div>
          </div>
        </div>
        <div className="process-super">
          <div>{detail.numHold}/{detail.numTotal}</div>
          <div className="outer">
            <div className="inner" style={{ width: detail.process }}></div>
          </div>
        </div>
      </div>
      <div className="progress-content">
        {productList.map((item, index) => (
          <div 
            className={`product-item ${item.holdNum === 0 && 'disable'}`}
            key={index} 
          >
            <div className="pic-super">
              <img className="main-pic" src={item.mainPic} />
              {item.holdNum === 0 && <div className="hold-tip">未获得</div>}
            </div>
            <div className="name">{item.name}</div>
            <img className="level" src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/progress-level-${item.levelName}.png`} />
            {item.holdNum !== 0 && <div className="hold-num">{item.holdNum}</div>}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Progress
