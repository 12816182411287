/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-09-09 15:34:10
 */
import React, { useState, useEffect } from 'react'
import { Toast, Mask } from 'antd-mobile'
import Header from '@/components/Header'
import { parseSearch } from '@/libs/util'
import api from '@/api'
import './index.scss'

function Detail({ history, location }) {
  const search = parseSearch(location.search)
  const [detail, setDetail] = useState({})
  const [isCert, setIsCert] = useState(false)
  const [cerVisible, setCerVisible] = useState(false)
  const [loginVisible, setLoginVisible] = useState(false)
  const [canTake, setCanTake] = useState(false)
  const [btnText, setBtnText] = useState('')
  const [orderVisible, setOrderVisible] = useState(false)
  const [orderNo, setOrderNo] = useState('')
  const { canPayment } = global

  useEffect(() => {
    // 是否登录
    const token = localStorage.getItem('token')
    if (!token) {
      history.push('/login')
      return
    }
    // 获取空投活动详情
    getDetail()
    // 是否实名认证
    api.getUserInfo().then(res => {
      if (res && res.isCert === 1) {
        setIsCert(true)
      }
    })
  }, [])

  const getDetail = () => {
    api.getAirdropDetail({ id: search.id }).then(res => {
      setDetail(res)
      const { canJoin, status, hasGot, lastNum, price } = res
      // 是否可领取
      if (canJoin && status === 2 && !hasGot && lastNum > 0) {
        setCanTake(true)
      }
      // 按钮文字
      let text = ''
      if (status === 1) {
        text = '活动未开始'
      } else if (status === 3) {
        text = '活动已结束'
      } else if (status === 2) {
        if (!canJoin) {
          text = '暂无领取资格'
        } else {
          if (hasGot) {
            text = '已领取'
          } else {
            if (lastNum > 0) {
              if (!canPayment && parseFloat(price) > 0) {
                text = '下载APP领取'
              } else {
                text = '立即领取'
              }
            } else {
              text = '已领完'
            }
          }
        } 
      }
      setBtnText(text)
    })
  }

  const handleClickBtn = () => {
    const { id, price } = detail
    // 是否可领取
    if (!canTake) return
    // 是否登录
    if (!localStorage.getItem('token')) {
      setLoginVisible(true)
      return
    }
    // 是否实名认证
    if (!isCert) {
      setCerVisible(true)
      return
    }
    if (parseFloat(price) > 0) {
      if (canPayment) {
        api.airdropGetCheck({ id }).then(() => {
          history.push('/payment', { airdropId: id })
        }).catch(err => {
          const { code, data, message } = err
          if (code === 120001) {
            setOrderVisible(true)
            setOrderNo(data.orderNo)
          } else if (code === 120002) {
            Toast.show({
              icon: 'fail',
              content: message || '服务器发生错误，请联系管理员',
              afterClose: () => window.location.reload()
            })
          } else {
            setTimeout(() => window.location.reload(), 2000)
          }
        })
      } else {
        window.open('https://nft.372163.com/h5/share')
      }
    } else {
      api.airdropGet({ id }).then(() => {
        Toast.show({
          icon: 'success',
          content: '恭喜您已成功领取，请稍后在我的藏品内查看(空投发放需要一些时间哟)~',
          afterClose: () => {
            setCanTake(false)
            setBtnText('已领取')
          },
        })
      }).catch(() => {
        setTimeout(() => window.location.reload(), 2000)
      })
    }
  }

  return (
    <div className="welfare-detail-container">
      <Header title={detail.name} onClick={() => { search.origin === '1' ? history.goBack() : history.push('/welfare') }} />
      <div className="banner">
        <img className="banner-picture" src={detail.pic} />
        <div className="banner-time">活动时间：{detail.startTime && `${detail.startTime.slice(0, 10)} ~ ${detail.endTime.slice(0, 10)}`}</div>
      </div>
      <div className="section">
        <div className="section-title">活动规则</div>
        <div className="section-content" dangerouslySetInnerHTML={{__html: detail.desc}}></div>
      </div>
      {detail.type === 2 && (
        <div className="footer">
          <div className="price">¥ {detail.price}</div>
          <div 
            className={`button ${canTake ? '' : 'disable'}`} 
            onClick={handleClickBtn}
          >
            {btnText}
          </div>
        </div>
      )}
      <Mask 
        visible={cerVisible} 
        onMaskClick={() => setCerVisible(false)} 
      >
        <div className="overlay-content">
          <div className="overlay-content-top">
            <div className="top-title">实名认证提示</div>
            <div className="top-content">应国家法规要求，<br/>购买/领取数字藏品需先进行实名认证</div>
          </div>
          <div className="overlay-content-bottom">
            <div className="vertical-bar"></div>
            <div className="left" onClick={() => setCerVisible(false)}>稍后再说</div>
            <div className="right" onClick={() => history.push('/my/certification')}>去实名</div>
          </div>
        </div>
      </Mask>
      <Mask 
        visible={loginVisible} 
        onMaskClick={() => setLoginVisible(false)} 
      >
        <div className="overlay-content">
          <div className="overlay-content-top">
            <div className="top-title">提示</div>
            <div className="top-content">您还没有登录，<br/>请先登录后再尝试领取哦</div>
          </div>
          <div className="overlay-content-bottom">
            <div className="vertical-bar"></div>
            <div className="left" onClick={() => setLoginVisible(false)}>稍后领取</div>
            <div className="right" onClick={() => history.push('/login')}>立即登录</div>
          </div>
        </div>
      </Mask>
      <Mask 
        visible={orderVisible} 
        onMaskClick={() => setOrderVisible(false)} 
      >
        <div className="overlay-content">
          <div className="overlay-content-center">
            <div className="top-title">存在待支付订单，不可重复提交哦</div>
          </div>
          <div className="overlay-content-bottom">
            <div className="vertical-bar"></div>
            <div className="left" onClick={() => setOrderVisible(false)}>在想想</div>
            <div className="right" onClick={() => history.push('/order/buydetail', { orderNo })}>去支付</div>
          </div>
        </div>
      </Mask>
    </div>
  )
}

export default Detail
