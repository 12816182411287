/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-07-06 16:26:31
 */
import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import Main from './Main'
import Code from './Code'

class Abandon extends Component {
  render () {
    const { path } = this.props.match

    return (
      <div>
        <Switch>
          <Route exact path={path + '/code'} component={Code}/>
          <Route component={Main} />
        </Switch>
      </div>
    )
  }
}

export default withRouter(Abandon)
