/*
 * @Description: 发布作品=>作品类型
 * @Author: 刘晶雪
 * @Date: 2023-04-03 16:29
 */
import React, { useState, useEffect } from 'react'
import { Toast } from 'antd-mobile'
import WorksUploader from '@/components/WorksUploader'
import Header from '@/components/Header'
import './index.scss'

function Major({ history }) {
	const [name, setName] = useState('')
	const [categoryId, setCategoryId] = useState(1) // 藏品类型：1:图片，3:视频
	const [imagePic, setImagePic] = useState('')
	const [videoPic, setVideoPic] = useState('')
	const [video, setVideo] = useState('')
	const [imageCopyright, setImageCopyright] = useState('')
	const [videoCopyright, setVideoCopyright] = useState('')

	useEffect(() => {
		const data = JSON.parse(localStorage.getItem('productData'))
		const num = Number(data.categoryId)
		setName(data.name)
		setCategoryId(num)
		if (num === 1) {
			setImagePic(data.mainPic)
			setImageCopyright(data.copyrightAdr)
		} else if (num === 3) {
			setVideo(data.video)
			setVideoPic(data.mainPic)
			setVideoCopyright(data.copyrightAdr)
		}
	}, [])

	//提交
	const handleSubmit = () => {
		if (name === '') {
			Toast.show({
				icon: 'fail',
				content: '请填写作品名称',
			})
			return
		}
		if (categoryId === 1) {
			if (imagePic === '') {
				Toast.show({
					icon: 'fail',
					content: '请上传作品',
				})
				return
			}
		} else if (categoryId === 3) {
			if (video === '') {
				Toast.show({
					icon: 'fail',
					content: '请上传作品',
				})
				return
			}
			if (videoPic === '') {
				Toast.show({
					icon: 'fail',
					content: '请上传视频封面',
				})
				return
			}
		}
		const data = JSON.parse(localStorage.getItem('productData'))
		data.name = name
		data.categoryId = categoryId
		if (categoryId === 1) {
			data.mainPic = imagePic
			data.copyrightAdr = imageCopyright
		} else if (categoryId === 3) {
			data.video = video
			data.mainPic = videoPic
			data.copyrightAdr = videoCopyright
		}
		localStorage.setItem('productData', JSON.stringify(data))
		history.goBack()
	}

	return (
		<div className="creator-major-container">
			<Header title="发布作品" />
			<div className="form-list">
				<div className="item">
					<div className="label-super">
						<span className="require-star">*</span>
						作品名称
					</div>
					<div className="input-super">
						<input
							className="input"
							placeholder="请填写16字以内作品名称"
							value={name}
							maxLength={16}
							onChange={evt => setName(evt.target.value.trim())}
						/>
					</div>
				</div>
				<div className="item">
					<div className="label-super">
						<span className="require-star">*</span>
						作品类型
					</div>
					<div className="input-hint">
						<div>选择发布的作品类型，图片格式支持png/jpg/gif</div>
					</div>
					<div className="works-type">
						<div className={`type type-image ${categoryId === 1 ? 'enable' : 'disable'}`} onClick={() => setCategoryId(1)}></div>
						<div className={`type type-video ${categoryId === 3 ? 'enable' : 'disable'}`} onClick={() => setCategoryId(3)}></div>
					</div>
				</div>
				{categoryId === 1 && (
					<>
						<div className="item">
							<div className="label-super">
								<span className="require-star">*</span>
								上传作品
							</div>
							<div className="input-hint">
								<div>图片文件需小于5M，视频文件需小于30M</div>
							</div>
							<WorksUploader 
								onChange={url => setImagePic(url)} 
								imageUrl={imagePic}
								accept="image/png, image/jpeg, image/gif"
							/>
						</div>
						<div className="item">
							<div className="label-super">著作权证明</div>
							<WorksUploader 
								onChange={url => setImageCopyright(url)} 
								imageUrl={imageCopyright}
							/>
						</div>
					</>
				)}
				{categoryId === 3 && (
					<>
						<div className="item">
							<div className="label-super">
								<span className="require-star">*</span>
								上传作品
							</div>
							<div className="input-hint">
								<div>图片文件需小于5M，视频文件需小于30M</div>
							</div>
							<WorksUploader 
								onChange={url => setVideo(url)} 
								imageUrl={video}
								accept="video/*"
								size={30}
							/>
						</div>
						<div className="item">
							<div className="label-super">
								<span className="require-star">*</span>
								上传视频封面
							</div>
							<div className="input-hint">
								<div>封面图片格式支持png/jpg</div>
							</div>
							<WorksUploader 
								onChange={url => setVideoPic(url)} 
								imageUrl={videoPic}
							/>
						</div>
						<div className="item">
							<div className="label-super">著作权证明</div>
							<WorksUploader 
								onChange={url => setVideoCopyright(url)} 
								imageUrl={videoCopyright}
							/>
						</div>
					</>
				)}
			</div>
			<div className="submit-super">
        <div className="submit-btn" onClick={handleSubmit}>提交</div>
      </div>
		</div>
	)
}

export default Major