/*
 * @Description: 商品详情
 * @Author: 杨志航
 * @Date: 2023-01-05 16:57:05
 */
import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import Main from './Main'
import Progress from './Progress'

class Product extends Component {
  render () {
    const { path } = this.props.match

    return (
      <div>
        <Switch>
          <Route exact path={path + '/progress'} component={Progress}/>
          <Route component={Main} />
        </Switch>
      </div>
    )
  }
}

export default withRouter(Product)
