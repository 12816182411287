/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-07-06 16:30:27
 */
import React, { useState, useEffect, useRef } from 'react'
import { Input, Toast } from 'antd-mobile'
import Header from '@/components/Header'
import api from '@/api'
import { dealMobile } from '@/libs/util'
import './index.scss'

let countTimer = null

function Code({ history, location }) {
  const { state: { mobile } } = location
  const [value, setValue] = useState('')
  const [initialCount] = useState(60)
  const [count, setCount] = useState(initialCount)
  const [sending, setSending] = useState(true)
  const codeRef = useRef(null)

  useEffect(() => {
    codeRef.current.focus()
    return () => {
      clearInterval(countTimer)
    }
  }, [])

  useEffect(() => {
    if (count >= 0 && sending) {
      countTimer = setTimeout(() => {
        setCount(count - 1)
      }, 1000)
    } else {
      setCount(initialCount)
      setSending(false)
    }
  }, [count, sending])

  const handleCode = val => {
    setValue(val)
    if (val.length === 6) {
      codeRef.current.nativeElement.setSelectionRange(0,0)
      codeRef.current.blur()
      let params = { smsCode: val }
      api.optPassword(params).then(() => {
        history.push('/password/set', { smsCode: val })
      })
    }
  }

  const handleSend = () => {
    if (sending) return
    api.sendSms({ mobile }).then(() => {
      Toast.show({
        icon: 'success',
        content: '验证码已发送',
      })
      setSending(true)
      setValue('')
    })
  }

  return (
    <div className="password-code-container">
      <Header />
      <div className="title">输入验证码</div>
      <div className="tip">
        已发送到{dealMobile(mobile)}
        <span 
          className={`send-again ${sending ? '' : 'enable'}`}
          onClick={handleSend}
        >重新发送</span>
        {sending && `（${count}s）`}
      </div>
      <div className="content">
        <Input 
          type="tel" 
          ref={codeRef}
          maxLength={6}
          value={value}
          onChange={handleCode}
        />
        <div className="box-list">
          {new Array(6).fill('').map((item, index) => (
            <div className="box-item" key={index}></div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Code
