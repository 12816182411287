/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-11-04 11:20:51
 */
import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import Main from './Main'
import Deal from './Deal'
import Collection from './Collection'
import User from './User'

class Hash extends Component {
  render () {
    const { path } = this.props.match

    return (
      <div>
        <Switch>
          <Route exact path={path + '/deal'} component={Deal}/>
          <Route exact path={path + '/user'} component={User}/>
          <Route exact path={path + '/collection'} component={Collection}/>
          <Route component={Main} />
        </Switch>
      </div>
    )
  }
}

export default withRouter(Hash)
