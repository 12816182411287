/*
 * @Description: 创作者
 * @Author: 杨志航
 * @Date: 2023-03-20 13:48:16
 */
import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import Main from './Main'
import Major from './Major'
import Publish from './Publish'
import Describe from './Describe'
import Tags from './Tags'
class Creator extends Component {
  render () {
    const { path } = this.props.match

    return (
      <div>
        <Switch>
          <Route exact path={path + '/major'} component={Major}/>
          <Route exact path={path + '/publish'} component={Publish}/>
          <Route exact path={path + '/describe'} component={Describe}/>
          <Route exact path={path + '/tags'} component={Tags}/>
          <Route component={Main} />
        </Switch>
      </div>
    )
  }
}

export default withRouter(Creator)
