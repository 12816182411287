/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-10-31 11:22:57
 */
import React, { useState, useEffect, useRef } from 'react'
import Header from '@/components/Header'
import { Mask, Toast } from 'antd-mobile'
import api from '@/api'
import './index.scss'

function Confirm({ history, location }) {
  const { state } = location
  const [detail, setDetail] = useState({})
  const [userInfo, setUserInfo] = useState({})
  const [tipVisible, setTipVisible] = useState(false)
  const [inputVisible, setInputVisible] = useState(false)
  const [value, setValue] = useState('')
  const inputRef = useRef(null)

  useEffect(() => {
    api.getCollectionOne({ id: state.id }).then(res => {
      setDetail(res.data)
    })
    api.getUserInfo().then(res => {
      setUserInfo(res)
    })
  }, [])

  const handleConfirm = () => {
    if (userInfo.hasOptPassword === 2) {
      setTipVisible(true)
    } else {
      setInputVisible(true)
      inputRef.current.focus()
    }
  }

  const handleCode = evt => {
    const val = evt.target.value
    setValue(val)
    if (val.length === 6) {
      const param = {
        id: detail.id,
        toUserId: state.toUserId,
        password: val,
      }
      api.sendSubgift(param).then(() => {
        Toast.show({
          icon: 'success',
          content: '操作成功',
          afterClose: () => history.go(-2)
        })
      }).catch(() => {
        setValue('')
      })
    }
  }

  const handleClose = () => {
    setInputVisible(false)
    setValue('')
  }

  const handleClick = () => {
    inputRef.current.focus()
    inputRef.current.setSelectionRange(100, 100)
  }

  const handleSet = () => {
    localStorage.setItem('needBack', 1)
    history.push('/password', { hasOptPassword: userInfo.hasOptPassword })
  }

  return (
    <div className="confirm-subgift-container">
      <Header title="确认转赠" />
      <div className="collection-super">
        <img className="main-pic" src={detail.productMainPic} />
        <div className="content">
          <div className="name">{detail.productName}</div>
          <div className="text">{detail.productCreatorName}</div>
          <div className="text">编号：{detail.tokenNo}</div>
          <div className="text">当前拥有者：{detail.ownerName}</div>
        </div>
      </div>
      <div className="tip-super">
        <div className="title">温馨提示：</div>
        <div className="text">一旦发起数字藏品转赠，即代表您同意以下条件</div>
        <div className="text">· 转赠成功不可撤销，藏品附加权益将一同转移，您的账户中将不再显示此藏品信息</div>
        <div className="text">· 转赠成功后系统处理需要一些时间（通常24小时内），请耐心等待</div>
        <div className="text">· 我们不支持任何形式的有偿转赠，请警惕欺诈风险，一旦发现您的异常行为，我们会对相关账户采取限制措施</div>
      </div>
      <div className="btn-confirm" onClick={handleConfirm}>确认转赠</div>
      <div className="tip-text">若对方{detail.subgiftExpirePeriod}小时内未领取，将退还给您</div>
      <input 
        className="password-value-input"
        type="tel" 
        ref={inputRef}
        maxLength={6}
        value={value}
        onChange={handleCode}
      />
      <Mask 
        visible={tipVisible} 
        onMaskClick={() => setTipVisible(false)} 
      >
        <div className="overlay-content">
          <div className="overlay-content-top">
            <div className="top-title">暂未设置操作密码</div>
            <div className="top-content">为了您的操作安全，请先设置</div>
          </div>
          <div className="overlay-content-bottom">
            <div className="vertical-bar"></div>
            <div className="left" onClick={() => setTipVisible(false)}>稍后再说</div>
            <div className="right" onClick={handleSet}>去设置</div>
          </div>
        </div>
      </Mask>
      <Mask 
        visible={inputVisible} 
        onMaskClick={handleClose}
        forceRender
      >
        <div className="input-password-super">
          <img 
            className="icon-close" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/login-icon-close.png" 
            onClick={handleClose}
          />
          <div className='content' onClick={handleClick}>
            <div className="title">输入操作密码</div>
            <div className="box-list">
              {new Array(6).fill('').map((item, index) => (
                <div className="box-item" key={index}>
                  <div className={`dot ${value.length < index + 1 ? 'hide' : ''}`}></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Mask>
    </div>
  )
}

export default Confirm
