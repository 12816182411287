/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-08-09 14:25:26
 */
import React, { useState, useEffect } from 'react'
import { pagination } from '@/libs/constant'
import { InfiniteScroll, PullToRefresh } from 'antd-mobile'
import Header from '@/components/Header'
import api from '@/api'
import './index.scss'

function Reword({ history, location }) {
  const { type } = location.state
  const [page, setPage] = useState(pagination.page)
  const [perPage] = useState(pagination.perPage)
  const [rewordList, setRewordList] = useState([])
  const [hasMore, setHasMore] = useState(true)
  document.title = '我的奖池'

  useEffect(() => {
    getList()
  }, [])

  const getList = async curPage => {
    curPage = curPage || page
    const { list, total } = await api.getActivityRecord({ page: curPage, perPage })
    const totalPage = Math.ceil(total / perPage)

    if (curPage < totalPage) {
      setPage(curPage + 1)
      setHasMore(true)
    } else {
      setHasMore(false)
    }

    if (curPage === 1) {
      setRewordList(list)
    } else {
      setRewordList([...rewordList, ...list])
    }
  }

  const handleLook = () => {
    if (type === 'web') {
      history.push('/collection')
    } else {
      if (window.android && window.android.gotoCollectionTab) {
        window.android.gotoCollectionTab()
      }
      if (
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.gotoCollectionTab
      ) {
        window.webkit.messageHandlers.gotoCollectionTab.postMessage(null)
      }
    }
  }
  
  return (
    <div className="reword-container" style={{ paddingTop: type === 'web' ? '.44rem' : 0 }}>
      {type === 'web' &&
        <Header title="我的奖池" />
      }
      <PullToRefresh onRefresh={() => getList(pagination.page)}>
        <div className="reword-list">
          {rewordList.map((item, index) => (
            <div className="item" key={index}>
              {item.status === 0 ? (
                <div className="pic-super">
                  <img className="face" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/lottery-reword-face.png"/>
                </div>
              ) : (
                <img className="pic" src={item.mainPic} />
              )}
              <div className="content">
                <div>
                  <div className="name">{item.status === 0 ? '谢谢参与' : item.name}</div>
                  <div className="time">{item.addTime.slice(0, 10)}</div>
                </div>
                {item.status !== 0 && 
                  <div>
                    <div className="status" style={{ color: item.status === 1 ? '#333333' : '#FE7147'}}>{item.status === 1 ? '待发放' : '已发放'}</div>
                    {item.status === 2 && 
                      <img 
                        className="btn" 
                        src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/reword-look-btn.png" 
                        onClick={handleLook}
                      />
                    }
                  </div>
                }
              </div>
            </div>
          ))}
        </div>
        <InfiniteScroll loadMore={getList} hasMore={hasMore} />
      </PullToRefresh>
    </div>
  )
}

export default Reword
