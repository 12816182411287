/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-08-10 09:44:24
 */
import React, { useState, useEffect } from 'react'
import Header from '@/components/Header'
import api from '@/api'
import './index.scss'

function Rule({ location }) {
  const { type, id } = location.state
  const [detail, setDetail] = useState({})
  document.title = '活动规则'

  useEffect(() => {
    api.getActivityDetail({ id }).then(res => {
      setDetail(res)
    })
  }, [])
  
  return (
    <div className="rule-container" style={{ paddingTop: type === 'web' ? '.64rem' : '.20rem' }}>
      {type === 'web' &&
        <Header title="活动规则" />
      }
      <div className="section">
        <div className="title">活动时间</div>
        <div>{
            detail.effectStartTime && detail.effectStartTime.slice(0, 10)
          } 至 {
            detail.effectEndTime && detail.effectEndTime.slice(0, 10)
          }，奖品抽完即止</div>
      </div>
      <div className="section">
        <div className="title">活动对象</div>
        {/* <div>扫码参与抽奖活动的全体用户</div> */}
        <div>持有《富贵兔》数字藏品的用户</div>
      </div>
      {/* <div className="section">
        <div className="title">参与方式</div>
        <div className="intro">扫描二维码，活动现场注册无限数藏平台账号并进行实名认证，即可参与活动</div>
        <div className="qr-list">
          <div className="item">
            <img className="qr" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/qr-nft-download.png" />
            <div>扫码下载APP</div>
          </div>
          <div className="item">
            <img className="qr" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/qr-nft-wechat.png" />
            <div>扫码关注公众号</div>
          </div>
        </div>
      </div> */}
      <div className="section">
        <div className="title">活动规则</div>
        <div className="desc">
          1、一个无限数藏账号的用户仅可参与一次活动；<br/>
          {/* 2、奖品预计在活动结束后3个工作日内发放，中奖的小伙伴进入APP时会有弹窗提示，奖品发放后可在APP内我的藏品中查看，请您耐心等待奖品发放呦～<br/> */}
          2、抽中的数字藏品，会在24小时内自动发放到您的无限数藏账户中，请耐心等待哦~<br/>
          3、奖品自动发放至参与抽奖的账号内，不支持转移至其他账号；<br/>
          4、活动奖品中奖概率如下：{detail.actPrize && detail.actPrize.map(item => `${item.name}中奖概率${item.winningProbability}%`).join('；')}；<br/>
          5、若发现抽奖活动中存在违规行为的用户，平台将取消活动奖品发放；<br/>
          6、本次活动最终解释权归平台所有。
        </div>
      </div>
    </div>
  )
}

export default Rule
