/*
 * @Description: 证书
 * @Author: 杨志航
 * @Date: 2022-11-07 14:28:29
 */
import React, { useState, useEffect } from 'react'
import { copyText } from '@/libs/util'
import Header from '@/components/Header'
import api from '@/api'
import './index.scss'

function Certificate({ location }) {
	const { state : { hash } } = location
	const [detail, setDetail] = useState({})
	const [collectionVisible, setCollectionVisible] = useState(true)
	const [logsVisible, setLogsVisible] = useState(true)

	useEffect(() => {
		api.getCertificateDetail({ hash }).then(res => {
      setDetail(res.data)
    })
	}, [])

	return (
		<div className="collection-certificate-container">
      <Header title="证书" />
			<div className="blockchain-detail">
				<div className="detail-content">
					<img className="top-bg" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/certificate-top-bg.png" />
					<img className="content-bg" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/certificate-content-bg.png" />
					<img className="bottom-bg" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/certificate-bottom-bg.png" />
					<div className="detail-content-title">数文链 / 无限数藏</div>
					<img className="detail-content-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/blockchain-tit-top.png" />
					<div className="detail-content-subhead">区块链查证信息概览</div>
					<img className="detail-content-img detail-content-img-bottom" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/blockchain-tit-top.png" />
					<div className={`detail-title ${collectionVisible ? '' : 'down'}`} onClick={() => setCollectionVisible(!collectionVisible)}>
						藏品信息
						<img className="icon-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/certificate-title-arrow.png" />
					</div>
					{collectionVisible && (
						<div className="info-list">
							<div className="item">
								<div className="label">藏品名称</div>
								<div className="value">{detail.productName}</div>
							</div>
							<div className="item">
								<div className="label">合约地址</div>
								<div className="value">
									{detail.productBlockHash}
									<img 
										onClick={() => copyText(detail.productBlockHash)} 
										className="icon-copy" 
										src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-hash-copy.png" 
									/>
								</div>
							</div>
							<div className="item">
								<div className="label">藏品编号</div>
								<div className="value">{detail.tokenNo}</div>
							</div>
							<div className="item">
								<div className="label">持有者</div>
								<div className="value">{detail.ownerName}</div>
							</div>
							<div className="item">
								<div className="label">创作者</div>
								<div className="value">{detail.productCreatorName}</div>
							</div>
							<div className="item">
								<div className="label">发行方</div>
								<div className="value">{detail.productIssuerName}</div>
							</div>
						</div>
					)}
					<div className={`detail-title ${logsVisible ? '' : 'down'}`} onClick={() => setLogsVisible(!logsVisible)}>
						流转信息
						<img className="icon-arrow down" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/certificate-title-arrow.png" />
					</div>
					{logsVisible && (
						<div className="logs-list">
							{detail.logs && detail.logs.map((item, index) => (
								<div className="item" key={index}>
									<div className="user-name">{item.userName}</div>
									<div className="info-super">
										<div className="info-item">
											<div className="label">流转类型</div>
											<div className="value">{item.typeName}</div>
										</div>
										<div className="info-item">
											<div className="label">流转时间</div>
											<div className="value">{item.txTime}</div>
										</div>
										<div className="info-item">
											<div className="label">交易哈希值</div>
											<div className="value">
												{item.txHash}
												<img 
													onClick={() => copyText(item.txHash)} 
													className="icon-copy" 
													src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-hash-copy.png" 
												/>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default Certificate
