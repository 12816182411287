/*
 * @Description: 作品审核中、失败查看页
 * @Author: 朱燕
 * @Date: 2023-04-13 16:34:10
 */
import React, { useState, useEffect, useRef } from 'react'
import Header from '@/components/Header'
import { ImageViewer, Mask } from 'antd-mobile'
import api from '@/api'
import './index.scss'

function Detail({ location }) {
  const { state } = location
  const [detail, setDetail] = useState({})
  const [tagNames, setTagNames] = useState([])
  const [imageVisible, setImageVisible] = useState(false)
  const [imageSrc, setImageSrc] = useState(false)
  const [mediaVisible, setMediaVisible] = useState(false)
  const mediaRef = useRef(null)

  useEffect(() => {
    // 获取详情
    getDetail()
  }, [])

  const getDetail = () => {
    api.getProductOne({ id: state.id }).then(res => {
      setDetail(res.data)
      setTagNames(res.data.tagNames)
    })
  }

  const handlePlay = () => {
    setMediaVisible(true)
    mediaRef.current.play()
  }

  const handleClose = () => {
    setMediaVisible(false)
    mediaRef.current.pause()
    mediaRef.current.currentTime = 0
  }

  const handleShowImg = src => {
    setImageSrc(src)
    setImageVisible(true)
  }

  return (
    <div className="collection-createDetail-container">
      <Header title={detail.name} />
      <div className="content-top">
        <div className="content-top-title"></div>
        {detail.checkStatus === 3 && <div className="content-top-title">作品审核不通过</div>}
        {detail.checkStatus === 3 && <div className="content-top-text">不通过原因：{detail.checkFailReasonDesc}</div>}
        {detail.checkStatus === 1 && <div className="content-top-title">作品审核中</div>}
        {detail.checkStatus === 1 && <div className="content-top-text">预计需要1-3个工作日，请您耐心等待～</div>}
      </div>
      <div className="section-con">
        <div className="section">
          <div className="item">
            <div className="label">作品名称：</div>
            <div className="value">{detail.name}</div>
          </div>
          <div className="item">
            <div className="label">作品标签：</div>
            <div className="value">{tagNames.join(',')}</div>
          </div>
          <div className="item">
            <div className="label">发布数量：</div>
            <div className="value">{detail.issuesNum}</div>
          </div>
          <div className="item">
            <div className="label">作品价格：</div>
            <div className="value">¥{detail.price}</div>
          </div>
          {detail.categoryId === 1 && (
            <div className="item">
              <div className="label">作品图片：</div>
              <div className="value-btn" onClick={() => handleShowImg(detail.mainPic)}>点击查看</div>
            </div>
          )}
          {(detail.categoryId === 3 || detail.categoryId === 4) && (
            <>
              <div className="item">
                <div className="label">作品视频：</div>
                <div className="value-btn" onClick={handlePlay}>点击查看</div>
              </div>
              <div className="item">
                <div className="label">作品封面：</div>
                <div className="value-btn" onClick={() => handleShowImg(detail.mainPic)}>点击查看</div>
              </div>
            </>
          )}
          {detail.copyrightAdr !== '' && (
            <div className="item">
              <div className="label">著作权证明：</div>
              <div className="value-btn" onClick={() => handleShowImg(detail.copyrightAdr)}>点击查看</div>
            </div>
          )}
          <div className="desc-list">
            <div className="desc-item">
              <div className="desc-label">作品描述：</div>
              <div className="desc-value">{detail.desc}</div>
            </div>
            <div className="desc-item">
              <div className="desc-label">创作者介绍：</div>
              <div className="desc-value">{detail.creatorDesc}</div>
            </div>
          </div>
        </div>
      </div>
      <ImageViewer
        image={imageSrc}
        visible={imageVisible}
        onClose={() => setImageVisible(false)}
      />
      <Mask
        className="media-modal-super"
        visible={mediaVisible}
        onMaskClick={handleClose}
        style={{ '--z-index': 1000 }}
        forceRender
      >
        {detail.categoryId === 3 &&
          <video
            className="media-player"
            src={detail.video}
            loop
            ref={mediaRef}
            controls
            controlsList="nodownload"
          >
            您的浏览器不支持 video 标签。
          </video>
        }
        {detail.categoryId === 4 &&
          <audio
            className="media-player"
            src={detail.audio}
            loop
            ref={mediaRef}
            controls
            controlsList="nodownload"
          >
            您的浏览器不支持 audio 标签。
          </audio>
        }
      </Mask>
    </div>
  )
}

export default Detail
