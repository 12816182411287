/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-08-08 11:32:59
 */
import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import Main from './Main'
import Reword from './Reword'
import Rule from './Rule'

class Lottery extends Component {
  render () {
    const { path } = this.props.match

    return (
      <div>
        <Switch>
          <Route exact path={path + '/reword'} component={Reword}/>
          <Route exact path={path + '/rule'} component={Rule}/>
          <Route component={Main} />
        </Switch>
      </div>
    )
  }
}

export default withRouter(Lottery)
