/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-10-24 13:54:41
 */
import React from 'react'
import Header from '@/components/Header'
import api from '@/api'
import './index.scss'

function Main({ history, location }) {
  const { state: { hasOptPassword } } = location
  const mobile = localStorage.getItem('mobile')

  const handleSubmit = () => {
    if (hasOptPassword === 1) {
      history.push('/password/validate', { mobile })
    } else {
      api.sendSms({ mobile }).then(() => {
        history.push('/password/code', { mobile })
      })
    }
  }

  return (
    <div className="password-container">
      <Header title="操作密码" />
      {
        hasOptPassword === 1
        ? <img className="password-done" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/set-password-done.png" /> 
        : <img className="password-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/set-password-icon.png" /> 
      }
      <div className="password-title">{`${hasOptPassword === 1 ? '已' : '未'}设置操作密码`}</div>
      <div className="password-tip">操作密码将用于转赠等操作</div>
      <div className="password-btn" onClick={handleSubmit}>{hasOptPassword === 1 ? '修改密码' : '安全认证并设置操作密码'}</div>
    </div>
  )
}

export default Main
