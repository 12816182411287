/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-09-14 15:25:43
 */
import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import Main from './Main'
import Record from './Record'

class Invite extends Component {
  render () {
    const { path } = this.props.match

    return (
      <div>
        <Switch>
          <Route exact path={path + '/record'} component={Record}/>
          <Route component={Main} />
        </Switch>
      </div>
    )
  }
}

export default withRouter(Invite)
