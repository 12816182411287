/*
 * @Description: 官方公告
 * @Author: 朱燕
 * @Date: 2023-04-04 13:43:54
 */
import React, { useEffect, useState } from 'react'
import Header from '@/components/Header'
import { Input, InfiniteScroll, PullToRefresh } from 'antd-mobile'
import { pagination } from '@/libs/constant'
import api from '@/api'
import './index.scss'

function Announce({ history }) {
  const [value, setValue] = useState('')
  const [page, setPage] = useState(pagination.page)
  const [perPage] = useState(pagination.perPage)
  const [announceList, setAnnounceList] = useState([])
  const [hasMore, setHasMore] = useState(true)

  useEffect(() => {
    // 修改打开APP按钮位置
    const btnEle = document.getElementById('appDownloadBtn')
    btnEle.style.top = '.30rem'
    // 请求列表
    getList()
    return () => {
      btnEle.style.top = '.70rem'
    }
  }, [])

  const getList = async params => {
    const curPage = (params && params.page) || page
    let obj = { page: curPage, perPage }
    if (params && params.desc) {
      obj.desc = params.desc
    }
    const { list, total } = await api.getAnnouncementList(obj)
    const totalPage = Math.ceil(total / perPage)

    if (curPage === 1) {
      setAnnounceList(list)
    } else {
      setAnnounceList([...announceList, ...list])
    }

    if (curPage < totalPage) {
      setPage(curPage + 1)
      setHasMore(true)
    } else {
      setHasMore(false)
    }
  }

  const handleSearch = () => {
    setAnnounceList([])
    setHasMore(false)
    getList({ page: 1, desc: value })
  }

  const handleRefresh = () => {
    getList({ page: 1 })
    setValue('')
  }

  const handleInput = val => {
    const str = val.trim()
    setValue(str)
    if (str === '') {
      getList({ page: 1 })
    }
  }

  return (
    <div className="announce-container">
      <Header title="官方公告" onClick={() => history.push('/home')} />
      <div className="search-wrapper">
        <div className="form-super">
          <img className="icon-search" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-search.png" />
          <Input
            type="text"
            placeholder='上新公告/销毁公告/活动公告'
            clearable
            value={value}
            onChange={handleInput}
          />
          <div className="search-btn" onClick={handleSearch}>搜索</div>
        </div>
      </div>
      <PullToRefresh onRefresh={handleRefresh}>
        {announceList.length === 0 ? (
          <div className="enpty-super">
            <img className="enpty-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/welfare-enpty.png" />
            <div className="text">暂无数据</div>
          </div>
        ) : (
          <>
            <div className="announce-list">
              {announceList.map((item, index) => (
                <div className="list-content" key={index} onClick={() => history.push('/announce/detail', { id: item.id })}>
                  <div className="announce-time">{item.publishTime}</div>
                  <div className="item">
                    <img className="main-img" src={item.pic} />
                    <div className="main-bottom">
                      <div className="title">【{item.typeName}】{item.title}</div>
                      <div className="desc">{item.desc}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <InfiniteScroll loadMore={getList} hasMore={hasMore} />
          </>
        )}
      </PullToRefresh>
    </div>
  )
}

export default Announce
