/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-07-13 09:51:10
 */
import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import Main from './Main'
import BuyDetail from './BuyDetail'
import SellDetail from './SellDetail'

class Order extends Component {
  render () {
    const { path } = this.props.match

    return (
      <div>
        <Switch>
          <Route exact path={path + '/buydetail'} component={BuyDetail}/>
          <Route exact path={path + '/selldetail'} component={SellDetail}/>
          <Route component={Main} />
        </Switch>
      </div>
    )
  }
}

export default withRouter(Order)
