/*
 * @Description: 发布作品
 * @Author: 杨志航
 * @Date: 2023-03-31 16:28:56
 */
import React, { useState, useEffect } from 'react'
import { Mask, Toast } from 'antd-mobile'
import Header from '@/components/Header'
import api from '@/api'
import { regExt } from '@/libs/constant'
import './index.scss'

const defaultData = {
  name: '',
  tags: [],
  mainPic: '',
  creatorDesc: '',
  desc: '',
  categoryId: 1,
  video: '',
  copyrightAdr: '',
}

function Publish({ history }) {
  const [detail, setDetail] = useState({})
  const [confirmVisible, setConfirmVisible] = useState(false)
  const [agreementVisible, setAgreementVisible] = useState(false) // 是否展示阅读协议
  const [enable, setEnble] = useState(false) // 阅读协议下方按钮是否高亮
  const [issuesNum, setIssuesNum] = useState(1)
  const [price, setPrice] = useState('')

  useEffect(() => {
    let data = localStorage.getItem('productData')
    if (data) {
      data = JSON.parse(data)
      setDetail(data)
    } else {
      localStorage.setItem('productData', JSON.stringify(defaultData))
      setDetail(defaultData)
    }
    if (!localStorage.getItem('creatorPublishAgree')) {
      setAgreementVisible(true)
    }
    // 修改打开APP按钮位置
    const btnEle = document.getElementById('appDownloadBtn')
    btnEle.style.top = '.30rem'
    return () => {
      btnEle.style.top = '.70rem'
    }
  }, [])

  const handleScroll = evt => {
    const dom = evt.target
    const clientHeight = dom.clientHeight
    const scrollTop = dom.scrollTop
    const scrollHeight = dom.scrollHeight
    if (clientHeight + scrollTop > scrollHeight - 5) {
      setEnble(true)
    }
  }

  const handleAgree = () => {
    if (enable) {
      localStorage.setItem('creatorPublishAgree', 1)
      setAgreementVisible(false)
    }
  }

  const changeNumber = evt => {
    const num = evt.target.value.trim()
    if (num > 20) {
      setIssuesNum(20)
    } else {
      setIssuesNum(num)
    }
  }

  const blurNumber = evt => {
    const num = Number(evt.target.value)
    if (isNaN(num) || num < 1) {
      setIssuesNum(1)
    }
  }

  const blurPrice = evt => {
    if(!regExt.positiveInt.test(evt.target.value)) {
      Toast.show({
        icon: 'fail',
        content: '请填写正整数',
      })
      setPrice('')
    }
  }

  const handleMinus = () => {
    if (issuesNum > 1) {
      setIssuesNum(issuesNum - 1)
    }
  }

  const handleAdd = () => {
    if (issuesNum < 20) {
      setIssuesNum(issuesNum + 1)
    }
  }

  const handleSubmit = () => {
    const { name, desc, creatorDesc, tags } = detail
    if (name === '') {
      Toast.show({
        icon: 'fail',
        content: '请上传作品',
      })
      return
    }
    if (desc === '') {
      Toast.show({
        icon: 'fail',
        content: '请填写作品描述',
      })
      return
    }
    if (creatorDesc === '') {
      Toast.show({
        icon: 'fail',
        content: '请填写创作者介绍',
      })
      return
    }
    if (tags.length === 0) {
      Toast.show({
        icon: 'fail',
        content: '请选择作品标签',
      })
      return
    }
    if (issuesNum === '') {
      Toast.show({
        icon: 'fail',
        content: '请填写发布数量',
      })
      return
    }
    if (price === '') {
      Toast.show({
        icon: 'fail',
        content: '请填写作品价格',
      })
      return
    }
    setConfirmVisible(true)
  }

  const handleComfirm = () => {
    const { name, categoryId, mainPic, video, copyrightAdr, desc, creatorDesc, tags } = detail
    const params = {
      name,
      categoryId,
      mainPic,
      desc,
      creatorDesc,
      tagId: tags.map(item => item.id).join(','),
      issuesNum,
      price,
    }
    if (categoryId === 3) {
      params.video = video
    }
    if (copyrightAdr) {
      params.copyrightAdr = copyrightAdr
    }
    setConfirmVisible(false)
    api.productCreate(params).then(() => {
      Toast.show({
        icon: 'success',
        content: '提交成功',
        afterClose: () => {
          localStorage.removeItem('productData')
          handleBack()
        }
      })
    })
  }

  const handleBack = () => {
    localStorage.removeItem('creatorPublishAgree')
    localStorage.removeItem('productData')
    history.goBack()
  }

  return (
    <div className="creator-publish-container">
      <Header title="发布作品" onClick={handleBack} />
      <div className="form-list">
        <div className="item">
          <div className="label-super">
            <span className="require-star">*</span>
            上传作品
            <span className="label-tip">选择您想要发布的作品上传</span>
          </div>
          <div className="input-super" onClick={() => history.push('/creator/major')}>
            <div className="info-super">
              {detail.mainPic && <img className="main-pic" src={detail.mainPic} />}
              <div>
                <div className="input-tip">点击上传</div>
                <div>发布作品消耗一张铸造卡</div>
              </div>
            </div>
            <img className="icon-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-left-arrow.png" />
          </div>
        </div>
        <div className="item">
          <div className="label-super">
            <span className="require-star">*</span>
            作品描述
          </div>
          <div className="input-super" onClick={() => history.push('/creator/describe', { type: 1 })}>
            {detail.desc ? (
              <div className="value">{detail.desc}</div>
            ) : (
              <div className="holder">请填写作品描述</div>
            )}
            <img className="icon-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-left-arrow.png" />
          </div>
        </div>
        <div className="item">
          <div className="label-super">
            <span className="require-star">*</span>
            创作者介绍
          </div>
          <div className="input-super" onClick={() => history.push('/creator/describe', { type: 2 })}>
            {detail.creatorDesc ? (
              <div className="value">{detail.creatorDesc}</div>
            ) : (
              <div className="holder">请填写创作者介绍</div>
            )}
            <img className="icon-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-left-arrow.png" />
          </div>
        </div>
        <div className="item">
          <div className="label-super">
            <span className="require-star">*</span>
            作品标签
          </div>
          <div className="input-super" onClick={() => history.push('/creator/tags')}>
            {detail.tags?.length > 0 ? (
              <div className="value">{detail.tags?.map(item => item.name).join('，')}</div>
            ) : (
              <div className="holder">请选择作品标签</div>
            )}
            <img className="icon-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-left-arrow.png" />
          </div>
        </div>
        <div className="item">
          <div className="label-super">
            <span className="require-star">*</span>
            发布数量
          </div>
          <div className="input-super">
            <input 
              className="input"
              type="tel"
              placeholder="请选择发布数量"
              value={issuesNum}
              onChange={changeNumber}
              onBlur={blurNumber}
            />
            <div className="suffix">
              <img 
                className="icon-option" 
                src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-minus-${issuesNum > 1 ? 'enable' : 'disable'}.png`}
                onClick={handleMinus}
              />
              <span className="number">{issuesNum}</span>
              <img 
                className="icon-option" 
                src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-add-${issuesNum < 20 ? 'enable' : 'disable'}.png`}
                onClick={handleAdd}
              />
            </div>
          </div>
        </div>
        <div className="item">
          <div className="label-super">
            <span className="require-star">*</span>
            作品价格
          </div>
          <div className="input-super">
            <span className="prefix">¥</span>
            <input 
              className="input"
              type="tel"
              placeholder="请输入作品价格"
              value={price}
              onChange={evt => setPrice(evt.target.value.trim())}
              onBlur={blurPrice}
            />
          </div>
          <div className="item-tip">为保障交易成功，作品价格不得低于30元</div>
        </div>
      </div>
      <div className="submit-super">
        <div className="submit-btn" onClick={handleSubmit}>提交</div>
      </div>
      <Mask visible={confirmVisible} onMaskClick={() => setConfirmVisible(false)}>
        <div className="overlay-content">
          <div className="overlay-content-top">
            <div className="top-title">确认发布</div>
            <div className="top-content">一经发布，作品资料不可修改，是<br/>否确认发布？</div>
          </div>
          <div className="overlay-content-bottom">
            <div className="vertical-bar"></div>
            <div className="left" onClick={() => setConfirmVisible(false)}>再看看</div>
            <div className="right" onClick={handleComfirm}>确认发布</div>
          </div>
        </div>
      </Mask>
      <Mask visible={agreementVisible} disableBodyScroll={true}>
        <div className="mask-agreement">
          <img 
            className="icon-close" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/login-icon-close.png" 
            onClick={handleBack}
          />
          <div className="agreement-title">内容发布协议</div>
          <div className="agreement-img-container" onScroll={handleScroll}>
            <img className="agreement-all-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/agree-creator.png" />
          </div>
          <div 
            className={`agreement-submit ${enable ? 'enable' : ''}`}
            onClick={handleAgree}
          >我已阅读并同意</div>
        </div>
      </Mask>
    </div>
  )
}

export default Publish
