/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-09-15 13:40:13
 */
import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import Main from './Main'
import BankList from './BankList'
import PayStatus from './PayStatus'
import BindCard from './BindCard'

class Payment extends Component {
  render () {
    const { path } = this.props.match

    return (
      <div>
        <Switch>
          <Route exact path={path + '/banklist'} component={BankList}/>
          <Route exact path={path + '/paystatus'} component={PayStatus}/>
          <Route exact path={path + '/bindcard'} component={BindCard}/>
          <Route component={Main} />
        </Switch>
      </div>
    )
  }
}

export default withRouter(Payment)
