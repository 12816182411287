/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-10-24 16:59:13
 */
import React, { useState, useEffect, useRef } from 'react'
import { Toast } from 'antd-mobile'
import Header from '@/components/Header'
import api from '@/api'
import './index.scss'

function Reset({ history, location }) {
  const { state: { password, oldPassword, smsCode } } = location
  const [value, setValue] = useState('')
  const inputRef = useRef(null)

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  const handleCode = evt => {
    const val = evt.target.value
    setValue(val)
    if (val.length === 6) {
      if (val !== password) {
        setValue('')
        inputRef.current.focus()
        Toast.show({
          icon: 'fail',
          content: '两次输入密码不一致',
        })
        return
      }
      let params = { newPassword : val }
      if (oldPassword) {
        params.oldPassword = oldPassword
      }
      if (smsCode) {
        params.smsCode = smsCode
      }
      api.optPassword(params).then(() => {
        Toast.show({
          icon: 'success',
          content: '设置成功',
          duration: 1000,
          afterClose: () => {
            if (localStorage.getItem('needBack')) {
              localStorage.removeItem('needBack')
              history.go(-4)
            } else {
              history.push('/my/setting')
            }
          }
        })
      })
    }
  }

  const handleClick = () => {
    inputRef.current.focus()
    inputRef.current.setSelectionRange(100, 100)
  }

  return (
    <div className="password-reset-container">
      <Header title="设置密码" />
      <div className="set-title">再次输入操作密码</div>
      <div className="content" onClick={handleClick}>
        <input 
          className="password-value-input"
          type="tel" 
          ref={inputRef}
          maxLength={6}
          value={value}
          onChange={handleCode}
        />
        <div className="box-list">
          {new Array(6).fill('').map((item, index) => (
            <div className="box-item" key={index}>
              <div className={`dot ${value.length < index + 1 ? 'hide' : ''}`}></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Reset
