/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2023-02-10 13:56:46
 */
import React, { useState, useEffect, useRef } from 'react'
import HeaderWhite from '@/components/HeaderWhite'
import { InfiniteScroll, PullToRefresh, Mask, Toast } from 'antd-mobile'
import { DatetimePicker } from 'react-vant'
import { pagination } from '@/libs/constant'
import { getYearMonth } from '@/libs/util'
import api from '@/api'
import './index.scss'

const listTabs = ['全部', '订单结算', '提现']
const cashStatusList = [
  { name: '提现中', color: '#4095E5' },
  { name: '提现成功', color: '#60C167' },
  { name: '提现失败', color: '#EF715D' },
]
const passwordStatusList = [
  { title: '账号验证', content: '请输入手机验证码，用于安全校验' },
  { title: '设置密码', content: '请输入要设置的新支付密码' },
  { title: '设置密码', content: '请再次输入要设置的新支付密码' },
  { title: '修改支付密码', content: '请输入当前支付密码，以验证身份' },
  { title: '忘记支付密码', content: '请输入手机验证码，用于安全校验' },
]
let countTimer = null

function Main({ history }) {
  const [page, setPage] = useState(pagination.page)
  const [perPage] = useState(pagination.perPage)
  const [billList, setBillList] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [activeTab, setActiveTab] = useState(0)
  const [cerVisible, setCerVisible] = useState(false)
  const [tipVisible, setTipVisible] = useState(false)
  const [forbidVisible, setForbidVisible] = useState(false)
  const [cashVisible, setCashVisible] = useState(false)
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [billDate, setBillDate] = useState(new Date())
  const [detail, setDetail] = useState({})
  const [initialCount] = useState(60)
  const [count, setCount] = useState(initialCount)
  const [sending, setSending] = useState(false)
  const [smsCode, setSmsCode] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [activeStatus, setActiveStatus] = useState(0)

  const inputRef = useRef(null)

  useEffect(() => {
    // 请求账单列表
    getList()
    // 是否实名认证
    api.getUserInfo().then(res => {
      if (res.isCert === 2) {
        setCerVisible(true)
      } else {
        getPageInfo()
      }
    })
    return () => {
      clearTimeout(countTimer)
    }
  }, [])

  useEffect(() => {
    if (count >= 0 && sending) {
      countTimer = setTimeout(() => {
        setCount(count - 1)
      }, 1000)
    } else {
      setCount(initialCount)
      setSending(false)
    }
  }, [count, sending])

  // 请求钱包详情
  const getPageInfo = () => {
    api.getWalletInfo().then(res => {
      const { hasPayPassword, status } = res
      setDetail(res)
      // 是否设置支付密码
      if (hasPayPassword === 2) {
        setActiveStatus(0)
        setTipVisible(true)
      }
      // 钱包禁用
      if (status === 2) {
        setForbidVisible(true)
      }
    })
  }

  const getList = async (params = {}) => {
    const curPage = params.page ?? page
    const month = params.month ?? getYearMonth(billDate)
    const type = params.type ?? activeTab
    const { list, total } = await api.getWalletBill({ page: curPage, perPage, month, type })
    const totalPage = Math.ceil(total / perPage)

    if (curPage === 1) {
      setBillList(list)
    } else {
      setBillList([...billList, ...list])
    }

    if (curPage < totalPage) {
      setPage(curPage + 1)
      setHasMore(true)
    } else {
      setHasMore(false)
    }
  }

  // 更新商品列表
  const handleRefresh = () => {
    getList({
      page: pagination.page
    })
  }

  const handleChangeDate = val => {
    setBillDate(val)
    getList({
      page: pagination.page,
      month:  getYearMonth(val)
    })
  }

  const handleChangeTab = index => {
    setActiveTab(index)
    setHasMore(false)
    getList({
      page: pagination.page,
      type: index
    })
  }

  const handleSendCode = () => {
    if (sending) return
    const mobile = localStorage.getItem('mobile')
    api.sendSms({ mobile }).then(() => {
      setSending(true)
      setInputValue('')
      inputRef.current.focus()
    })
  }

  const setPassword = () => {
    setTipVisible(false)
    setPasswordVisible(true)
    handleSendCode()
  }

  const closePassword = () => {
    getPageInfo()
    setPasswordVisible(false)
    setInputValue('')
  }

  const handleClickInput = () => {
    inputRef.current.focus()
    inputRef.current.setSelectionRange(100, 100)
  }

  const handleChangeInput = evt => {
    const val = evt.target.value
    setInputValue(val)
    if (val.length === 6) {
      switch (activeStatus) {
        case 0:
        case 4:
          api.payPassword({ smsCode: val }).then(() => {
            setActiveStatus(1)
            setSmsCode(val)
            setInputValue('')
          })
          break
        case 1:
          setActiveStatus(2)
          setNewPassword(val)
          setInputValue('')
          break
        case 2:
          if (val !== newPassword) {
            setInputValue('')
            inputRef.current.focus()
            Toast.show({
              icon: 'fail',
              content: '两次输入密码不一致',
            })
          } else {
            let params = { newPassword : val }
            if (oldPassword !== '') {
              params.oldPassword = oldPassword
            }
            if (smsCode !== '') {
              params.smsCode = smsCode
            }
            api.payPassword(params).then(() => {
              Toast.show({
                icon: 'success',
                content: '设置成功',
                duration: 1000,
                afterClose: () => {
                  closePassword()
                }
              })
            })
          }
          break
        case 3:
          api.payPassword({ oldPassword: val }).then(() => {
            setActiveStatus(1)
            setOldPassword(val)
            setInputValue('')
          })
          break
      }
    }
  }

  const handleReset = () => {
    setActiveStatus(3)
    setPasswordVisible(true)
  }

  const handleForgetCode = () => {
    setActiveStatus(4)
    setInputValue('')
    handleSendCode()
  }

  return (
    <div className="wallet-container">
      <HeaderWhite title="我的钱包" bgColor="transprent" />
      <div className="wallet-top-super">
        <div className="wallet-banner-super">
          <div className="banner-label">可提现余额（元）</div>
          <div className="banner-value">{detail.balance}</div>
          <img 
            className="banner-btn" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/wallet-money-btn.png" 
            onClick={() => setCashVisible(true)}
          />
        </div>
        <div className="set-password-super" onClick={handleReset}>
          修改支付密码
          <img className="icon-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-black-arrow.png" />
        </div>
      </div>
      <div className="wallet-list-super">
        <div className="list-title">
          账单
          <DatetimePicker
            popup={{
              round: true,
            }}
            type='year-month'
            minDate={new Date(2023, 0, 1)}
            maxDate={new Date()}
            value={billDate}
            onConfirm={val => handleChangeDate(val)}
            formatter={(type, val) => {
              if (type === 'year') {
                return `${val}年`
              }
              if (type === 'month') {
                return `${val}月`
              }
              return val
            }}
            confirmButtonText="确定"
          >
            {
              (val, _, actions) => (
                <div className="list-date">
                  <div onClick={() => actions.open()}>{getYearMonth(val)}</div>
                  <img className="icon-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/wallet-date-arrow.png" />
                </div>
              )
            }
          </DatetimePicker>
        </div>
        <div className="list-tabs">
          {listTabs.map((title, index) => (
            <div 
              className={`tab-item ${activeTab === index ? 'active' : ''}`} 
              key={index}
              onClick={() => handleChangeTab(index)}
            >{title}</div>)
          )}
        </div>
        <PullToRefresh onRefresh={handleRefresh}>
          {billList.length === 0 ? (
            <div className="enpty-super">
              <img className="enpty-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/welfare-enpty.png" />
              <div className="text">你还没有账单记录哦</div>
            </div>
          ) : (
            <>
              {billList.map((item, index) => (
                <div className="list-item" key={index} onClick={() => history.push('/wallet/detail', { id: item.id })}>
                  <div className="info-top">
                    <div className="title">
                      <div className="name">{item.title}</div>
                      {item.type === 2 && item.cashStatus !== 0 &&
                        <div 
                          className="status" 
                          style={{ color: cashStatusList[item.cashStatus - 1].color }}
                        >
                          {cashStatusList[item.cashStatus - 1].name}
                        </div>
                      }
                    </div>
                    <div className={`pay ${Number(item.amount) > 0 ? 'plus' : ''}`}>{Number(item.amount) > 0 ? '+' : ''}{item.amount}</div>
                  </div>
                  <div className="info-bottom">
                    <div className="time">{item.addTime}</div>
                    <div className="balance">余额{item.balance}</div>
                  </div>
                </div>
              ))}
              <InfiniteScroll loadMore={getList} hasMore={hasMore} />
            </>
          )} 
        </PullToRefresh>
      </div>
      <Mask visible={cerVisible}>
        <div className="wallet-tip-modal">
          <div className="overlay-content-top">
            <div className="top-title">实名认证提示</div>
            <div className="top-content">为保障您的账号安全，需先通过实<br/>名认证，才能使用钱包功能</div>
          </div>
          <div className="overlay-content-bottom">
            <div className="vertical-bar"></div>
            <div className="left" onClick={() => history.push('/my')}>稍后再说</div>
            <div className="right" onClick={() => history.push('/my/certification')}>立即认证</div>
          </div>
        </div>
      </Mask>
      <Mask visible={tipVisible}>
        <div className="wallet-tip-modal">
          <div className="overlay-content-top">
            <div className="top-title">钱包使用提示</div>
            <div className="top-content">您的账号暂未设置支付密码，需先<br/>设置完成后才能使用钱包功能</div>
          </div>
          <div className="overlay-content-bottom">
            <div className="vertical-bar"></div>
            <div className="left" onClick={() => history.push('/my')}>稍后再说</div>
            <div className="right" onClick={setPassword}>设置密码</div>
          </div>
        </div>
      </Mask>
      <Mask visible={forbidVisible}>
        <div className="wallet-tip-modal">
          <div className="overlay-content-top">
            <div className="top-title">钱包冻结提示</div>
            <div className="top-content">您的钱包已被冻结，暂时无法使用<br/>钱包功能，详情可咨询客服（我的-<br/>联系客服）</div>
          </div>
          <div className="overlay-content-bottom">
            <div className="right" onClick={() => history.push('/my')}>我知道了</div>
          </div>
        </div>
      </Mask>
      <Mask visible={cashVisible} onMaskClick={() => setCashVisible(false)}>
        <div className="wallet-tip-modal">
          <div className="overlay-content-top">
            <div className="top-title">温馨提示</div>
            <div className="top-content">使用提现功能请先下载APP端</div>
          </div>
          <div className="overlay-content-bottom">
            <div className="vertical-bar"></div>
            <div className="left" onClick={() => setCashVisible(false)}>稍后再说</div>
            <div className="right" onClick={() => window.open('https://nft.372163.com/h5/share')}>立即下载</div>
          </div>
        </div>
      </Mask>
      <Mask visible={passwordVisible}>
        <div className="wallet-password-modal">
          <img className="icon-close" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-black-close.png" 
            onClick={closePassword}
          />
          <div className="title">{passwordStatusList[activeStatus].title}</div>
          <div className="content">{passwordStatusList[activeStatus].content}</div>
          <div className="input-super" onClick={handleClickInput}>
            <input 
              className="password-value-input"
              type="tel" 
              ref={inputRef}
              maxLength={6}
              value={inputValue}
              onChange={handleChangeInput}
            />
            <div className="box-list">
              {new Array(6).fill('').map((item, index) => (
                <div className="box-item" key={index}>
                  {(activeStatus === 0 || activeStatus === 4) ? (
                    <div className="text">{inputValue.slice(index, index + 1)}</div>
                  ) : (
                    <div className={`dot ${inputValue.length < index + 1 ? 'hide' : ''}`}></div>
                  )}
                </div>
              ))}
            </div>
          </div>
          {(activeStatus === 0 || activeStatus === 4) && (
            <div className="option-btn" onClick={handleSendCode}>{sending ? `重新发送（${count}）` : '重新发送'}</div>
          )}
          {activeStatus === 3 && (
            <div className="option-btn" onClick={handleForgetCode}>忘记密码</div>
          )}
        </div>
      </Mask>
    </div>
  )
}

export default Main
