/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-07-05 11:20:44
 */
import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import Main from './Main'
import Setting from './Setting'
import AboutUs from './AboutUs'
import Certification from './Certification'
import Record from './Record'
import Interest from './Interest'
import Foundry from './Foundry'

class My extends Component {
  render () {
    const { path } = this.props.match

    return (
      <div>
        <Switch>
          <Route exact path={path + '/setting'} component={Setting}/>
          <Route exact path={path + '/aboutus'} component={AboutUs}/>
          <Route exact path={path + '/certification'} component={Certification}/>
          <Route exact path={path + '/record'} component={Record}/>
          <Route exact path={path + '/interest'} component={Interest}/>
          <Route exact path={path + '/foundry'} component={Foundry}/>
          <Route component={Main} />
        </Switch>
      </div>
    )
  }
}

export default withRouter(My)
