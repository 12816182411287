/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-07-06 16:30:27
 */
import React, { useState, useEffect, useRef } from 'react'
import { Input, Toast, Mask } from 'antd-mobile'
import Header from '@/components/Header'
import api from '@/api'
import { dealMobile } from '@/libs/util'
import './index.scss'

let countTimer = null

function Code({ history }) {
  const mobile = localStorage.getItem('mobile')
  const [value, setValue] = useState('')
  const [enableSubmit, setEnbleSubmit] = useState(false)
  const [serviceVisible, setServiceVisible] = useState(false)
  const [initialCount] = useState(60)
  const [count, setCount] = useState(initialCount)
  const [sending, setSending] = useState(true)
  const codeRef = useRef(null)

  useEffect(() => {
    codeRef.current.focus()
    return () => {
      clearInterval(countTimer)
    }
  }, [])

  useEffect(() => {
    if (count >= 0 && sending) {
      countTimer = setTimeout(() => {
        setCount(count - 1)
      }, 1000)
    } else {
      setCount(initialCount)
      setSending(false)
    }
  }, [count, sending])

  const handleCode = val => {
    setValue(val)
    if (val.length === 6) {
      codeRef.current.nativeElement.setSelectionRange(0,0)
      codeRef.current.blur()
      setEnbleSubmit(true)
    }
  }

  const handleSend = () => {
    if (sending) return
    api.sendSms({ mobile }).then(() => {
      Toast.show({
        icon: 'success',
        content: '验证码已发送',
      })
      setSending(true)
      setValue('')
    })
  }

  const handleSubmit = () => {
    if (!enableSubmit) return
    api.abandon({ smsCode: value }).then(() => {
      localStorage.removeItem('token')
      localStorage.removeItem('mobile')
      Toast.show({
        icon: 'success',
        content: '提交成功',
        afterClose: () => history.go(-3)
      })
    }).catch(err => {
      if (err.code === 100003) {
        setServiceVisible(true)
      }
    })
  }

  return (
    <div className="abandon-code-container">
      <Header />
      <div className="title">输入验证码</div>
      <div className="tip">
        已发送到{dealMobile(mobile)}
        <span 
          className={`send-again ${sending ? '' : 'enable'}`}
          onClick={handleSend}
        >重新发送</span>
        {sending && `（${count}s）`}
      </div>
      <div className="content">
        <div className="code-input-super">
          <Input 
            type="tel" 
            ref={codeRef}
            maxLength={6}
            value={value}
            onChange={handleCode}
          />
          <div className="box-list">
            {new Array(6).fill('').map((item, index) => (
              <div className="box-item" key={index}></div>
            ))}
          </div>
        </div>
        <div 
          className={`confirm-btn ${enableSubmit ? 'enable' : 'disable'}`}
          onClick={handleSubmit} 
        >提交注销申请</div>
      </div>
      <Mask visible={serviceVisible} onMaskClick={() => setServiceVisible(false)}>
        <div className="modal-contact-content">
          <div className="mask-title">您的账号持有数字藏品<br />请先联系客服处理</div>
          <img 
            className="mask-img" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/contact-way.png" />
          <div className="mask-hint">长按保存</div>
        </div>
      </Mask>
    </div>
  )
}

export default Code
