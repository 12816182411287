/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-07-11 17:02:22
 */
import React, { useState, useEffect, useRef } from 'react'
import { Mask, Toast, ImageViewer } from 'antd-mobile'
import { copyText, dealHash, getCurrentTime } from '@/libs/util'
import Header from '@/components/Header'
import Support from '@/components/Support'
import api from '@/api'
import './index.scss'

function Detail({ history, location }) {
  const { state } = location
  const [detail, setDetail] = useState({})
  const [videoVisible, setVideoVisible] = useState(false)
  const [mediaVisible, setMediaVisible] = useState(false)
  const [avatarVisible, setAvatarVisible] = useState(false)
  const [errData, setErrData] = useState({})
  const [tipVisible, setTipVisible] = useState(false)
  const [imageVisible, setImageVisible] = useState(false)
  const videoRef = useRef(null)
  const mediaRef = useRef(null)

  useEffect(() => {
    api.getCollectionOne({ id: state.id }).then(res => {
      setDetail(res.data)
    })
    document.documentElement.scrollTop = 0
  }, [])

  const handlePlay = (setShow, ref) => {
    setShow(true)
    ref.current.play()
  }

  const handleClose = (setShow, ref) => {
    setShow(false)
    ref.current.pause()
    ref.current.currentTime = 0
  }

  const handleSend = () => {
    if (detail.subgiftId > 0) {
      history.push('/collection/subgift', { id: detail.subgiftId })
      return
    }
    api.checkSubgift({ id: detail.id }).then(() => {
      history.push('/collection/search', { id: detail.id })
    }).catch(err => {
      const { code, data } = err
      if (code === 2) {
        setErrData(data)
        setTipVisible(true)
      }
    })
  }

  const handleCertificate = () => {
    // 数据埋点
    window.aplus_queue.push({
      action: 'aplus.record',
      arguments: ['collectiondetail_certificate', 'CLK', {
        clickTime: getCurrentTime()
      }]
    })
    const { txHash } = detail
    if (txHash) {
      history.push('/collection/certificate', { hash: txHash })
    } else {
      Toast.show({
        content: '证书正在生成中',
      })
    }
  }

  const handleShining = () => {
    // 数据埋点
    window.aplus_queue.push({
      action: 'aplus.record',
      arguments: ['collectiondetail_ostentation', 'CLK', {
        clickTime: getCurrentTime()
      }]
    })
    history.push('/collection/shining', { detail })
  }

  const handleAvatar = () => {
    // 数据埋点
    window.aplus_queue.push({
      action: 'aplus.record',
      arguments: ['collectiondetail_generateavatar', 'CLK', {
        clickTime: getCurrentTime()
      }]
    })
    history.push('/collection/avatar', { pic: detail.productMainPic })
  }

  const handleShowCollection = () => {
    if (detail.productRenderPic) {
      history.push('/collection/show', { id: state.id })
    } else { // 创作者作品
      setImageVisible(true)
    }
  }

  return (
    <div className="collection-detail-container">
      <Header bgColor="transprent" />
      <div className="banner">
        <img 
          className="banner-picture" 
          src={detail.productRenderPic ? detail.productRenderPic : detail.productMainPic} 
        />
        <div className="banner-icon">
          <img 
            className="icon-3d" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-collection-3d.png" 
            onClick={handleShowCollection}
          />
          {(detail.productCategoryId === 3 || detail.productCategoryId === 4) && 
            <img 
              className="icon-play" 
              src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-collection-play.png"
              onClick={() => handlePlay(setMediaVisible, mediaRef)}
            />
          }
        </div>
        <div className="banner-name">{detail.productName}</div>
      </div>
      <div className="section">
        <div className="item">
          <div className="label">合约地址</div>
          <div className="hash-super">
            <div className="hash">{detail.productBlockHash && dealHash(detail.productBlockHash) || '生成中...'}</div>
            {detail.productBlockHash && <img 
              className="icon-copy" 
              src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/qukuailiandizhi.png" 
              onClick={() => copyText(detail.productBlockHash)}
            />}
          </div>
        </div>
        <div className="item">
          <div className="label">持有者</div>
          <div className="value">{detail.ownerName}</div>
        </div>
        <div className="item">
          <div className="label">收藏时间</div>
          <div className="value">{detail.txTime || '生成中...'}</div>
        </div>
        <div className="item">
          <div className="label">藏品编号</div>
          <div className="value">{detail.tokenNo}</div>
        </div>
        <div className="item">
          <div className="label">交易哈希值</div>
          <div className="hash-super">
            <div className="hash">{detail.txHash && dealHash(detail.txHash) || '生成中...'}</div>
            {detail.txHash && <img 
              className="icon-copy" 
              src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/qukuailiandizhi.png" 
              onClick={() => copyText(detail.txHash)}
            />}
          </div>
        </div>
      </div>
      <div className="section">
        <div className="title">作品故事</div>
        {detail.productStoryVideo && 
          <div className="video-super" onClick={() => handlePlay(setVideoVisible, videoRef)}>
            <img 
              className="poster" 
              src={detail.productMainPic}
            />
            <img 
              className="icon" 
              src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/product-video-play-icon.png" 
            />
          </div>
        }
        {detail.productStory && <div className="story">{detail.productStory}</div>}
      </div>
      <div className="section">
        <div className="item">
          <div className="label">创作者</div>
          <div className="value">{detail.productCreatorName}</div>
        </div>
      </div>
      <div className="support-super">
        <Support />
      </div>
      <div className="nav-list">
        <div className="item" onClick={handleShining}>
          <img className="icon-shining" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-translate-shining.png"/>
          炫耀
        </div>
        {detail.productIsSubgift === 1 && (
          <div className="item" onClick={handleSend}>
            <img className="icon-give" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-translate-give.png"/>
            转赠
          </div>
        )}
        <div className="item" onClick={handleCertificate}>
          <img className="icon-certificate" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-translate-certificate.png"/>
          证书
        </div>
        <div className="item" onClick={() => setAvatarVisible(!avatarVisible)}>
          <img className="icon-use" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-translate-use.png"/>
          使用
        </div>
        {avatarVisible && (
          <div className="create-avatar" onClick={handleAvatar}>
            <img className="icon-avatar" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-translate-avatar.png"/>
            生成头像
          </div>
        )}
      </div>
      <Mask 
        className="media-modal-super"
        visible={videoVisible} 
        onMaskClick={() => handleClose(setVideoVisible, videoRef)}
        style={{ '--z-index': 1000 }}
        forceRender
      >
        <video 
          className="media-player"
          src={detail.productStoryVideo}
          loop
          ref={videoRef}
          controls
        >
          您的浏览器不支持 video 标签。
        </video>
      </Mask>
      <Mask 
        className="media-modal-super"
        visible={mediaVisible} 
        onMaskClick={() => handleClose(setMediaVisible, mediaRef)}
        style={{ '--z-index': 1000 }}
        forceRender
      >
        {detail.productCategoryId === 3 &&
          <video 
            className="media-player"
            src={detail.productVideo}
            loop
            ref={mediaRef}
            controls
            controlsList="nodownload"
          >
            您的浏览器不支持 video 标签。
          </video>
        }
        {detail.productCategoryId === 4 &&
          <audio 
            className="media-player"
            src={detail.productAudio}
            loop
            ref={mediaRef}
            controls
            controlsList="nodownload"
          >
            您的浏览器不支持 audio 标签。
          </audio>
        }
      </Mask>
      <Mask 
        visible={tipVisible} 
        style={{ '--z-index': 1000 }}
      >
        <div className="give-tip-super">
          <img 
            className="icon-close" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/login-icon-close.png" 
            onClick={() => setTipVisible(false)}
          />
          <div className="title">温馨提示</div>
          <div className="desc">本数字藏品尚未满足可以转赠的条件</div>
          <div className="tip-title">转赠须知：</div>
          <div className="tip-text">· 您的数字藏品在购买满{errData.subgiftBuyTerm}天或受赠满{errData.subgiftPresentedTerm}天后，可以转赠给满18周岁的实名认证用户。</div>
          <div className="tip-text">·我们不支持任何形式的有偿转赠，请警惕欺诈风险。一旦发生异常行为，我们会对相关账户采取限制措施。</div>
          <div className="btn" onClick={() => setTipVisible(false)}>我知道了</div>
        </div>
      </Mask>
      <ImageViewer
        image={detail.productMainPic}
        visible={imageVisible}
        onClose={() => setImageVisible(false)}
      />
    </div>
  )
}

export default Detail
