/*
 * @Description: 我卖出的-订单详情
 * @Author: 杨志航
 * @Date: 2023-04-13 10:47:09
 */
import React, { useState, useEffect } from 'react'
import Header from '@/components/Header'
import { copyText } from '@/libs/util'
import api from '@/api'
import './index.scss'

function SellDetail({ location }) {
  const { state } = location
  const [detail, setDetail] = useState({})

  useEffect(() => {
    // 请求详情
    api.getSellOutDetail({ id: state.id }).then(res => {
      setDetail(res)
    })
  }, [])

  return (
    <div className="order-sell-done-container">
      <Header title="订单详情" />
      <div className="status-super">
        {detail.status === 1 && <div className="wait">出售中···</div>} 
        {detail.status === 4 && (
          <>
            <div className="desc">出售成功</div>
            <div className="tip">您可以在“我的钱包”中查看实际收入</div>
          </>
        )}
      </div>
      <div className="content-super">
        <div className="banner-super">
          <img className="main-pic" src={detail.mainPic} />
          <div className="name-super">
            <div className="name">{detail.productName}</div>
            <div className="token">
              <span className="num">#{detail.tokenNo?.split('/')[0]}</span>
              /{detail.tokenNo?.split('/')[1]}
            </div>
          </div>
        </div>
        <div className="calc-super">
          <div className="fee-list">
            <div className="item">
              <div className="label">出售单价</div>
              <div className="value">¥{detail.price}</div>
            </div>
            <div className="item">
              <div className="label">出售数量</div>
              <div className="value"><span className="mark">X</span> 1</div>
            </div>
            <div className="item">
              <div className="label">总计</div>
              <div className="value blod">¥{detail.price}</div>
            </div>
          </div>
          <div className="fee-list">
            {detail.technicalServiceFeePercentage != 0 && (
              <div className="item">
                <div className="label">技术服务费({detail.technicalServiceFeePercentage}%)</div>
                <div className="value">-¥{detail.technicalServiceFee}</div>
              </div>
            )}
            {detail.payChannelFeePercentage != 0 && (
              <div className="item">
                <div className="label">支付通道费({detail.payChannelFeePercentage}%)</div>
                <div className="value">-¥{detail.payChannelFee}</div>
              </div>
            )}
            {detail.blockchainServiceFeePercentage != 0 && (
              <div className="item">
                <div className="label">区块链服务费({detail.blockchainServiceFeePercentage}%)</div>
                <div className="value">-¥{detail.blockchainServiceFee}</div>
              </div>
            )}
            {detail.transactionServiceFeePercentage != 0 && (
              <div className="item">
                <div className="label">交易服务费({detail.transactionServiceFeePercentage}%)</div>
                <div className="value">-¥{detail.transactionServiceFee}</div>
              </div>
            )}
          </div>
          <div className="income-super">
            <div className="label">预计收入</div>
            <div className="value">¥{detail.preIncome}</div>
          </div>
        </div>
        <div className="info-list">
          <div className="item">
            <div className="label">创建时间</div>
            <div className="value">{detail.addTime}</div>
          </div>
          <div className="item">
            <div className="label">订单编号</div>
            <div className="value">
              {detail.number}
              <img 
                className="icon" 
                src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/qukuailiandizhi.png"
                onClick={() => copyText(detail.number)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SellDetail
