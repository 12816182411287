/*
 * @Description: 转赠状态页面
 * @Author: 杨志航
 * @Date: 2022-11-02 17:24:50
 */
import React, { useState, useEffect } from 'react'
import Header from '@/components/Header'
import { Mask } from 'antd-mobile'
import api from '@/api'
import './index.scss'

function Subgift({ history, location }) {
  const { state } = location
  const [detail, setDetail] = useState({})
  const [collection, setCollection] = useState({})
  const [sendInfo, setSendInfo] = useState({})
  const [userInfo, setUserInfo] = useState({})
  const [tipVisible, setTipVisible] = useState(false)

  useEffect(() => {
    // 获取详情
    getDetail()
    api.getUserInfo().then(res => {
      setUserInfo(res)
    })
  }, [])

  const getDetail = () => {
    api.getSubgiftDetail({ id: state.id }).then(res => {
      dealStatus(res)
      setDetail(res)
      setCollection(res.collection)
    })
  }

  const handleReceive = () => {
    if (userInfo.isCert === 2) {
      setTipVisible(true)
    } else {
      api.receiveSubgift({ id: detail.id }).then(() => {
        getDetail()
      }).catch(() => {
        getDetail()
      })
    }
  }

  const dealStatus = data => {
    const { isSender, status, expireTime, subgiftExpirePeriod } = data
    let info = null
    if (isSender) {
      switch (status) {
        case 1:
          info = {
            status: 'sending',
            name: '转赠中',
            tip: expireTime.slice(0, -3) + '前未领取将退还给您'
          }
          break
        case 2: 
          info = {
            status: 'success',
            name: '接收成功',
            tip: '藏品发放需要时间，可稍后查看'
          }
          break
        case 3: 
          info = {
            status: 'expire',
            name: '已过期',
            tip: '转赠已过期，可重新发起转赠'
          }
          break
      }
    } else {
      switch (status) {
        case 1:
          info = {
            status: 'waiting',
            name: '待接收',
            tip: '藏品待接收'
          }
          break
        case 2: 
          info = {
            status: 'success',
            name: '接收成功',
            tip: '藏品发放需要时间，可稍后查看'
          }
          break
        case 3: 
          info = {
            status: 'expire',
            name: '已过期',
            tip: `未在${subgiftExpirePeriod}小时内领取，已退还给对方`
          }
          break
      }
    }
    setSendInfo(info || {})
  }

  return (
    <div className="subgift-status-container">
      <Header title={detail.isSender ? '确认转赠' : '接收转赠'} />
      <div className="status-super">
        {sendInfo.status && 
          <img className="icon" src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-subgift-status-${sendInfo.status}.png`} />
        }
        <div className="name">{sendInfo.name}</div>
        <div className="tip">{sendInfo.tip}</div>
      </div>
      <div className="collection-super">
        <img className="main-pic" src={collection.productMainPic} />
        <div className="content">
          <div className="name">{collection.productName}</div>
          <div className="text">{collection.productCreatorName}</div>
          <div className="text">编号：{collection.tokenNo}</div>
          <div className="text">当前拥有者：{collection.ownerName}</div>
        </div>
      </div>
      <div className="info-list">
        <div className="item">
          <div className="label">赠送内容</div>
          <div className="value">{collection.productName}</div>
        </div>
        <div className="item">
          <div className="label">接收者</div>
          <div className="value">{detail.toUserName}</div>
        </div>
        <div className="item">
          <div className="label">转赠时间</div>
          <div className="value">{detail.addTime}</div>
        </div>
        {sendInfo.status === 'success' && (
          <div className="item">
            <div className="label">接收时间</div>
            <div className="value">{detail.toTime}</div>
          </div>
        )}
        {sendInfo.status !== 'waiting' && (
          <div className="item">
            <div className="label">订单编号</div>
            <div className="value">{detail.number}</div>
          </div>
        )}
      </div>
      {sendInfo.status === 'waiting' && (
        <div>
          <div className="btn-confirm" onClick={handleReceive}>确认接收</div>
          <div className="tip-text">若您{detail.subgiftExpirePeriod}小时内未领取，将退还给对方</div>
        </div>
      )}
      {sendInfo.status === 'expire' && (
        <div>
          <div className="btn-confirm" onClick={() => history.goBack()}>完成</div>
        </div>
      )}
      {sendInfo.status === 'success' && (
        <div className="btn-super">
          <div className="btn-complete" onClick={() => history.goBack()}>完成</div>
          <div className="btn-look" onClick={() => history.push('/collection')}>查看详情</div>
        </div>
      )}
      <Mask 
        visible={tipVisible} 
        onMaskClick={() => setTipVisible(false)} 
      >
        <div className="overlay-content">
          <div className="overlay-content-top">
            <div className="top-title">提示</div>
            <div className="top-content">应国家法规要求，<br/>购买/领取数字藏品需先进行实名认证</div>
          </div>
          <div className="overlay-content-bottom">
            <div className="vertical-bar"></div>
            <div className="left" onClick={() => setTipVisible(false)}>稍后再说</div>
            <div className="right" onClick={() => history.push('/my/certification')}>实名认证</div>
          </div>
        </div>
      </Mask>
    </div>
  )
}

export default Subgift
