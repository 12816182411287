/*
* @Description: 
* @Author: 杨志航<yangzhihang@372163.com>
* @Date: 2022-06-23 17:30:04
*/
import urls from './urls'
import { Toast } from 'antd-mobile'
import history from '@/libs/history'

const API = {}
let fetchTimer = null
const errorCodes = [
  100003, // 注销失败，存在藏品
  120000, // 商品已售罄或已下架
  120001, // 有待支付的订单
  120002, // 有已完成的订单
  120003, // 订单处理中
  2, // 受赠天数限制
]

for (let k in urls) {//eslint-disable-line
  API[k] = (query, isFile) => {
    // 有查询参数
    if (typeof query === 'object') {
      return fetcher(urls[k], query, isFile)
    }
    // 无查询参数 有loading
    if (typeof query === 'boolean') {
      return fetcher(urls[k], null, isFile)
    }
    // 无查询参数 无loading
    if (typeof query === 'undefined') {
      return fetcher(urls[k])
    }
    Toast.show({
      icon: 'fail',
      content: '参数错误：查询参数必须是objec类型',
    })
    console.error('参数错误：查询参数必须是objec类型')
  }
}

export const fetcher = (urlObj, params, isFile) => {
  const headers = new Headers()
  // 添加token
  const token = localStorage.getItem('token') 
  if (token) {
    headers.append('token', token)
  }
  headers.append('device', `{"os": "h5", "appType": "h5", "version": "${global.version}"}`)
  const customHeaders = urlObj.headers || {}
  for(let hName in customHeaders){
    headers.append(hName, customHeaders[hName])
  }

  const config = {
    method: urlObj.method || 'GET',
    credentials: 'include',
  }

  let url = urlObj.url

  if (urlObj.method.toUpperCase() === 'POST') {
    if (isFile) {
    const fd = new FormData()
    for (let key in params) {
      fd.append(key, params[key])
    }
    config.body = fd
    } else {
    headers.append('Content-Type', 'application/json')
    config.body = JSON.stringify(params)
    }
  }
  else if (params) {
    url += url.indexOf('?') === -1 ? '?' : '&'
    for (let k in params) {
      const val = params[k] === undefined ? '' : params[k]
      url += `${encodeURIComponent(k)}=${encodeURIComponent(val)}&`
    }
    url = url.slice(0, -1)
  }

  config.headers = headers

  Toast.show({
    icon: 'loading',
    content: '加载中…',
    duration: 0,
  })
  return fetch(url, config)
    .then(response => response.json())
    .then(result => {
      const { code, data, message } = result
      Toast.clear()
      // 成功
      if (code === 0) {
        return data || message
      } 
      // 页面需要处理的错误
      if (errorCodes.includes(code)) {
        return Promise.reject(result)
      } 
      // 未登录跳转到登录页面
      if (code === 100002) {
        Toast.show({
          icon: 'fail',
          content: message || '服务器发生错误，请联系管理员',
          afterClose: () => history.push('/login')
        })
        return Promise.reject(result)
      }
      // 其他错误情况
      if(!fetchTimer) {
        Toast.show({
          icon: 'fail',
          content: message || '服务器发生错误，请联系管理员',
        })
      }
      clearTimeout(fetchTimer)
      fetchTimer = setTimeout(() => {
        fetchTimer = null
      }, 1000)
      return Promise.reject(result)
    })
}

export const fetchOSSUpload = (url, params) => {
  const config = {
    method: 'POST',
  }
  const fd = new FormData()
  for (let key in params) {
    fd.append(key, params[key])
  }
  config.body = fd

  Toast.show({
    icon: 'loading',
    content: '加载中…',
    duration: 0,
  })

  return fetch(url, config)
    .then(response => response.json())
    .then(result => {
      Toast.clear()
      if (result.Status === 'Ok') {
        return result
      } else {
        Toast.show({
          icon: 'fail',
          content: result.message || '服务器发生错误，请联系管理员',
        })
      }
      return Promise.reject(result)
    })
    .catch(err => {
      Toast.clear()
      if(!fetchTimer) {
        Toast.show({
          icon: 'fail',
          content: err.message || '服务器发生错误，请联系管理员',
        })
      }
      clearTimeout(fetchTimer)
      fetchTimer = setTimeout(() => {
        fetchTimer = null
      }, 1000)
      return Promise.reject(err || { message: '服务器发生错误，请联系管理员' })
    })
}

export default API
