/*
 * @Description: 藏品记录
 * @Author: 杨志航
 * @Date: 2022-10-25 09:51:48
 */
import React, { useState, useEffect } from 'react'
import Header from '@/components/Header'
import { InfiniteScroll, PullToRefresh } from 'antd-mobile'
import { pagination } from '@/libs/constant'
import api from '@/api'
import './index.scss'

const statusList = ['trade', 'send', 'receive']

function Record() {
  const [page, setPage] = useState(pagination.page)
  const [perPage] = useState(pagination.perPage)
  const [recordList, setRecordList] = useState([])
  const [hasMore, setHasMore] = useState(true)

  useEffect(() => {
    // 请求列表
    getList()
  }, [])

  const getList = async curPage => {
    curPage = curPage || page
    const { list, total } = await api.getCollectionRecords({ page: curPage, perPage })
    const totalPage = Math.ceil(total / perPage)

    if (curPage < totalPage) {
      setPage(curPage + 1)
      setHasMore(true)
    } else {
      setHasMore(false)
    }

    if (curPage === 1) {
      setRecordList(list)
    } else {
      setRecordList([...recordList, ...list])
    }
  }

  return (
    <div className="record-container">
      <Header title="藏品记录" />
      <PullToRefresh onRefresh={() => getList(pagination.page)}>
        {recordList.length === 0 ? (
          <div className="enpty-super">
            <img className="enpty-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/welfare-enpty.png" />
            <div className="text">暂无数据</div>
          </div>
        ) : (
          <>
            <div className="record-list">
              {recordList.map((item, index) => (
                <div className="item" key={index}>
                  <div className="top-super">
                    <div className="content">
                      <img className="img" src={item.productMainPic} />
                      <div className="desc-super">
                        <div className="title">{item.productName}</div>
                        <div className="creator">{item.productCreatorName}</div>
                      </div>
                    </div>
                    <div className={`status ${statusList[item.subType - 1]}`}>{item.typeName}</div>
                  </div>
                  {item.subType === 1 && parseFloat(item.price) > 0 && (
                    <div className="row">
                      <div className="label">交易金额</div>
                      <div className="value">¥ {item.price}</div>
                    </div>
                  )}
                  {item.subType === 2 && (
                    <div className="row">
                      <div className="label">接收者</div>
                      <div className="value">{item.subgiftTo}</div>
                    </div>
                  )}
                  {item.subType === 3 && (
                    <div className="row">
                      <div className="label">转赠者</div>
                      <div className="value">{item.subgiftFrom}</div>
                    </div>
                  )}
                  <div className="row">
                    <div className="label">交易时间</div>
                    <div className="value">{item.txTime}</div>
                  </div>
                  <div className="row">
                    <div className="label">订单编号</div>
                    <div className="value">{item.trNumber}</div>
                  </div>
                </div>
              ))}
            </div>
            <InfiniteScroll loadMore={getList} hasMore={hasMore} />
          </>
        )} 
      </PullToRefresh>
    </div>
  )
}

export default Record
