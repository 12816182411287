/*
 * @Description: 藏品列表
 * @Author: 杨志航
 * @Date: 2023-02-17 13:53:09
 */
import React, { useState, useEffect } from 'react'
import HeaderWhite from '@/components/HeaderWhite'
import { Input } from 'antd-mobile'
import api from '@/api'
import './index.scss'

function Main({ history }) {
  const [value, setValue] = useState('')
  const [collectionList, setCollectionList] = useState([])

  useEffect(() => {
    // 修改打开APP按钮位置
    const btnEle = document.getElementById('appDownloadBtn')
    btnEle.style.top = '.30rem'
    // 请求列表
    getList()
    return () => {
      btnEle.style.top = '.70rem'
    }
  }, [])

  const getList = name => {
    let param = {}
    if (name) {
      param.productName = name
    }
    api.getPushList(param).then(res => {
      setCollectionList(res)
    })
  }

  const handleToggle = id => {
    const list = collectionList.map(item => {
      if (item.productId === id) {
        item.isOpen = !item.isOpen
      }
      return item
    })
    setCollectionList(list)
  }

  const handleClickToken = data => {
    if (data.consignmentId === 0) {
      history.push('/publish/detail', { id: data.userProductId })
    }
  }

  const handleInput = val => {
    const str = val.trim()
    setValue(str)
    if (str === '') {
      getList()
    }
  }

  return (
    <div className="search-collection-container">
      <HeaderWhite title="选择上架藏品" />
      <div className="banner-super">
        <div className="form-super">
          <img className="icon-search" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-black-search.png" />
          <Input 
            placeholder='输入藏品名称' 
            clearable 
            value={value}
            onChange={handleInput}
          />
          <div className="search-btn" onClick={() => getList(value)}>搜索</div>
        </div>
      </div>
      {collectionList.length === 0 ? (
        <div className="enpty-super">
          <img className="enpty-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/welfare-enpty.png" />
          <div className="text">暂无可寄售的藏品</div>
        </div>
      ) : (
        <div className="collection-list">
          {collectionList.map((item, index) => (
            <div className="item" key={index}>
              <div className="content-super">
                <div className="info-super" onClick={() => handleToggle(item.productId)}>
                  <div className='left'>
                    <img className="main-img" src={item.mainPic} />
                    <div className="name">{item.productName}</div>
                  </div>
                  <div className="right">
                    <div className="account">
                      <div>{item.consignmentNum}</div>
                      <div className="text">寄售中</div>
                    </div>
                    <div className="account">
                      <div className="number">{item.hasNum}</div>
                      <div className="text">持有数量</div>
                    </div>
                  </div>
                </div>
              </div>
              {item.isOpen && (
                <div className="token-super">
                  <div className="content-super">
                    <div className="tip">选择要上架的藏品编号</div>
                    <div className="token-list">
                      {item.children.map((v, i) => (
                        <div 
                          className={`token-item ${v.consignmentId === 1 ? 'disable' : ''}`} 
                          key={i}
                          onClick={() => handleClickToken(v)}
                        >#{v.tokenNo}</div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Main
