/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2023-02-17 11:39:37
 */
import React from 'react'
import Header from '@/components/Header'
import './index.scss'

function Sale () {

  return (
    <div className="agree-container">
      <Header title="寄售协议" />
      <img className="agree-main-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/agree-sale.png" />
    </div>
  )
}

export default Sale
