/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-07-11 11:15:36
 */
import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import Main from './Main'
import Detail from './Detail'
import CreateDetail from './CreateDetail'
import Show from './Show'
import Search from './Search'
import Confirm from './Confirm'
import Subgift from './Subgift'
import Certificate from './Certificate'
import Avatar from './Avatar'
import Shining from './Shining'

class Collection extends Component {
  render () {
    const { path } = this.props.match

    return (
      <div>
        <Switch>
          <Route exact path={path + '/detail'} component={Detail}/>
          <Route exact path={path + '/createDetail'} component={CreateDetail}/>
          <Route exact path={path + '/show'} component={Show}/>
          <Route exact path={path + '/search'} component={Search}/>
          <Route exact path={path + '/confirm'} component={Confirm}/>
          <Route exact path={path + '/subgift'} component={Subgift}/>
          <Route exact path={path + '/certificate'} component={Certificate}/>
          <Route exact path={path + '/avatar'} component={Avatar}/>
          <Route exact path={path + '/shining'} component={Shining}/>
          <Route component={Main} />
        </Switch>
      </div>
    )
  }
}

export default withRouter(Collection)
