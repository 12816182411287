/*
 * @Description: 
 * @Author: 刘晶雪
 * @Date: 2022-07-06 17:41:53
 */
import React, { useState, useEffect } from 'react'
import { InfiniteScroll, PullToRefresh } from 'antd-mobile'
import { pagination } from '@/libs/constant'
import Header from '@/components/Header'
import api from '@/api'
import './index.scss'

function Message({ history }) {
	const [page, setPage] = useState(pagination.page)
  const [perPage] = useState(pagination.perPage)
	const [messageList, setMessageList] = useState([])
	const [hasMore, setHasMore] = useState(true)

	useEffect(() => {
		getList()
	}, [])

	const getList = async curPage => {
		curPage = curPage || page
    const { list, total } = await api.getMessageList({ page: curPage, perPage })
    const totalPage = Math.ceil(total / perPage)

    if (curPage < totalPage) {
      setPage(curPage + 1)
      setHasMore(true)
    } else {
      setHasMore(false)
    }

    if (curPage === 1) {
      setMessageList(list)
    } else {
      setMessageList([...messageList, ...list])
    }
	}

	//全部已读
	const onReadAll = () => {
		api.setMessageReadAll().then(() => {
			getList()
		})
	}

	//跳转详情
	const onClickDetail = item => {
		const { id, msgId, productId } = item
		api.setMessageRead({ id }).then(() => {
			switch (msgId) {
				case 1:
					history.push(`/product?id=${productId}`)
					break
				case 2:
					history.push('/collection', { type: 1 })
					break
				case 3:
					history.push('/collection/subgift', { id: productId })
					break
				case 5:
					history.push('/announce/detail', { id: productId })
					break
				case 6:
				case 7:
					history.push('/collection')
					break
			}
		})
	}

	return (
		<div className="message-container">
      <Header title="消息" />
			{messageList.length > 0 && 
				<div className="read-all" onClick={onReadAll}>全部已读</div>
			}
			<PullToRefresh onRefresh={() => getList(pagination.page)}>
				{messageList.length === 0 ? (
					<div className="enpty-super">
						<img className="enpty-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/welfare-enpty.png" />
						<div className="text">暂无消息</div>
					</div>
				) : (<>
					<div>
						{
							messageList.map((item,index) => {
								return 	<div className="message-container-content" key={index}>
									<div className="content-time">{item.sendTime}</div>
									<div className="content-box" onClick={() => onClickDetail(item)}>
										<div className="content-box-title">系统消息
											{item.readed === 1 && <img className="content-box-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-red-dot.png" />}
										</div>
										<div className="content-box-center">
											<img className="content-box-img" src={item.img} />
											<div className="content-box-describe">
												<div className="describe-title">{item.title}</div>
												<div className="describe-content">{item.content}</div>
											</div>
										</div>
										<div className="content-box-bottom">
											<div className="title-left">查看详情</div>
											<img className="icon-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-left-arrow.png" />
										</div>
									</div>
								</div>
							})
						}
					</div>
					<InfiniteScroll loadMore={getList} hasMore={hasMore} />
				</>
				)}
			</PullToRefresh>
		</div>
	)
}

export default Message
