/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-07-08 15:37:58
 */
import React, { useState, useEffect } from 'react'
import { Input, Toast } from 'antd-mobile'
import Header from '@/components/Header'
import { dealMobile } from '@/libs/util'
import api from '@/api'
import './index.scss'

function Certification({ history }) {
  const [realName, setRealName] = useState('')
  const [idNum, setIdNum] = useState('')
  const [enable, setEnable] = useState(false)

  useEffect(() => {
    if (realName !== '' && idNum !== '') {
      setEnable(true)
    } else {
      setEnable(false)
    }
  }, [realName, idNum])

  const handleSubmit = () => {
    if (!enable) return
    api.setCertification({
      realName,
      idNum,
    }).then(() => {
      Toast.show({
        icon: 'success',
        content: '认证成功',
        afterClose: () => history.goBack(),
      })
    })
  }

  return (
    <div className="certification-container">
      <Header title="实名认证" />
      <div className="container-list">
        <div className="list-left">手机号</div>
        <div className="list-right">{dealMobile(localStorage.getItem('mobile'))}</div>
      </div>
      <div className="container-list">
        <div className="list-left">真实姓名</div>
        <div className="list-right">
          <Input 
            placeholder="请输入真实姓名" 
            value={realName} 
            onChange={val => setRealName(val)}
          />
        </div>
      </div>
      <div className="container-list">
        <div className="list-left">身份证号</div>
        <div className="list-right">
          <Input 
            placeholder="请输入身份证号"
            value={idNum}
            onChange={val => setIdNum(val.trim())}
          />
        </div>
      </div>
      <div className="setting-container-log">
        <div 
          className={`confirm-btn ${enable ? 'enable' : 'disable'}`}
          onClick={handleSubmit} 
        >提交</div>
        <div className="btn-tip">
          <img className="icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-certification.png" />
          信息安全保障中，信息仅用于身份确认
        </div>
        <div className="desc">
          <div className="title">认证须知：</div>
          <div className="text">· 按照法律法规要求，进一步使用区块链服务需要提供身份信息，请填写真实、有效的信息。</div>
          <div className="text">· 请您确认注册手机号与备案实名信息匹配，否则可能导致认证失败，每天仅有3次认证机会。</div>
          <div className="text">· 实名认证是平台对注册用户真实性验证流程，平台保证每一位用户所提交的身份信息的安全性与隐私，所有信息都将被加密存储。</div>
        </div>
      </div>
    </div>
  )
}

export default Certification
