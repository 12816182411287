/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-10-24 16:33:22
 */
import React, { useState, useEffect, useRef } from 'react'
import Header from '@/components/Header'
import api from '@/api'
import './index.scss'

function Validate({ history, location }) {
  const { state: { mobile } } = location
  const [value, setValue] = useState('')
  const inputRef = useRef(null)

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  const handleCode = evt => {
    const val = evt.target.value
    setValue(val)
    if (val.length === 6) {
      let params = { oldPassword: val }
      api.optPassword(params).then(() => {
        history.push('/password/set', { oldPassword: val })
      }).catch(() => {
        setValue('')
        inputRef.current.focus()
      })
    }
  }

  const handleSend = () => {
    api.sendSms({ mobile }).then(() => {
      history.push('/password/code', { mobile })
    })
  }

  const handleClick = () => {
    inputRef.current.focus()
    inputRef.current.setSelectionRange(100, 100)
  }

  return (
    <div className="password-validate-container">
      <Header title="设置密码" />
      <div className="set-title">输入当前操作密码</div>
      <div className="tip">
        忘记密码？使用
        <span className="forget" onClick={handleSend}>
          忘记密码
          <img className="icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-forget-arrow.png" />
        </span>
      </div>
      <div className="content" onClick={handleClick}>
        <input 
          className="password-value-input"
          type="tel" 
          ref={inputRef}
          maxLength={6}
          value={value}
          onChange={handleCode}
        />
        <div className="box-list">
          {new Array(6).fill('').map((item, index) => (
            <div className="box-item" key={index}>
              <div className={`dot ${value.length < index + 1 ? 'hide' : ''}`}></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Validate
