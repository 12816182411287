/*
 * @Description: 用户区块链地址
 * @Author: 杨志航
 * @Date: 2022-11-07 17:05:48
 */
import React, { useState, useEffect } from 'react'
import { copyText } from '@/libs/util'
import Header from '@/components/Header'
import './index.scss'

function User({ location }) {
	const [detail, setDetail] = useState({})
	const [accountVisible, setAccountVisible] = useState(true)
	const [logsVisible, setLogsVisible] = useState(true)

	useEffect(() => {
		setDetail(location.state.data)
	}, [])

	return (
		<div className="hash-user-container">
      <Header title="区块链信息查询" />
			<div className="blockchain-detail">
				<div className="detail-content">
					<img className="top-bg" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/certificate-top-bg.png" />
					<img className="content-bg" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/certificate-content-bg.png" />
					<img className="bottom-bg" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/certificate-bottom-bg.png" />
					<div className="detail-content-title">数文链 / 无限数藏</div>
					<img className="detail-content-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/blockchain-tit-top.png" />
					<div className="detail-content-subhead">区块链查证信息概览</div>
					<img className="detail-content-img detail-content-img-bottom" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/blockchain-tit-top.png" />
          <div className={`detail-title ${accountVisible ? '' : 'down'}`} onClick={() => setAccountVisible(!accountVisible)}>
            账号信息
						<img className="icon-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/certificate-title-arrow.png" />
					</div>
					{accountVisible && (
						<div className="info-list">
							<div className="item">
								<div className="label">区块链地址</div>
								<div className="value">
									{detail.blockHash}
									<img 
										onClick={() => copyText(detail.blockHash)} 
										className="icon-copy" 
										src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-hash-copy.png" 
									/>
								</div>
							</div>
							<div className="item">
								<div className="label">注册时间</div>
								<div className="value">{detail.blockUpTime}</div>
							</div>
						</div>
					)}
          <div className={`detail-title ${logsVisible ? '' : 'down'}`} onClick={() => setLogsVisible(!logsVisible)}>
            拥有藏品信息
						<img className="icon-arrow down" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/certificate-title-arrow.png" />
					</div>
					{logsVisible && (
						<div className="logs-list">
							{detail.logs && detail.logs.length === 0 && (
								<div className='empty'>暂无藏品</div>
							)}
							{detail.logs && detail.logs.map((item, index) => (
								<div className="item" key={index}>
									<div className="info-item">
                    <div className="label">藏品名称</div>
                    <div className="value">{item.productName}</div>
                  </div>
                  <div className="info-item">
                    <div className="label">交易哈希值</div>
                    <div className="value">
                      {item.txHash}
                      <img 
                        onClick={() => copyText(item.txHash)} 
                        className="icon-copy" 
                        src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-hash-copy.png" 
                      />
                    </div>
                  </div>
                  <div className="info-item">
                    <div className="label">收藏时间</div>
                    <div className="value">{item.txTime}</div>
                  </div>
								</div>
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default User
