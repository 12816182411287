/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-09-14 15:25:57
 */
import React, { useState, useEffect, useRef } from 'react'
import { Toast } from 'antd-mobile'
import Header from '@/components/Header'
import { copyText, getDeviceType, getCurrentTime } from '@/libs/util'
import CanvasTool from '@/libs/CanvasTool'
import api from '@/api'
import './index.scss'

function Main({ history }) {
  const [detail, setDetail] = useState({})
  const [poster, setPoster] = useState('')
  const canvasRef = useRef(null)

  useEffect(() => {
    api.getUserInvite().then(async data => {
      Toast.show({
        icon: 'loading',
        content: '加载中…',
        duration: 0,
      })
      setDetail(data)
      // 获得canvas元素
      const canvas = canvasRef.current
      // 获得2d上下文对象
      const ctx = canvas.getContext("2d")
      // 检测支持性
      if(!ctx) return
      const tool = new CanvasTool({ ctx })
      // 绘制画布
      await canvasDraw(tool, data)
      // 设置海报图片链接
      setPoster(canvas.toDataURL('image/jpeg'))
      Toast.clear()
      // 是否登录
      const token = localStorage.getItem('token')
      let userId = ''
      if (token) {
        const res = await api.getUserInfo()
        userId = res.id
      }
      // 数据埋点
      const param = {
        userId,
        deviceType: getDeviceType(),
        clickTime: getCurrentTime()
      }
      window.aplus_queue.push({
        action: 'aplus.record',
        arguments: ['invitefriends_open', 'CLK', param]
      })
    })
    
  }, [])

  const canvasDraw = async (tool, data) => {
    const posterImage = 'https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/invite-poster-bg.png'
    const logoImage = 'https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/poster-nft-logo.png'
    
    await tool.drawImage(posterImage, 0, 0, 686, 1028)
    await tool.drawImage(logoImage, 24, 30, 56, 56)
    tool.drawText({
      text: '无限数藏',
      color: '#FFFFFF',
      size: 40,
      x: 88,
      y: 30,
      bold: true,
    })
    await tool.drawImage(data.qrCode, 24, 758, 200, 200)
    tool.drawText({
      text: `【${data.nickname}】`,
      color: '#FFFFFF',
      size: 32,
      x: 254,
      y: 758,
      maxWidth: 445,
      bold: true,
    })
    tool.drawText({
      text: '邀请您一起加入无限数藏',
      color: '#FFFFFF',
      size: 28,
      x: 274,
      y: 840,
      bold: true,
    })
    tool.drawText({
      text: `专属邀请码：${data.inviteCode}`,
      color: '#FFFFFF',
      size: 28,
      x: 274,
      y: 918,
      bold: true,
    })
  }

  const handleCopy = () => {
    copyText(detail.inviteUrl, '已复制链接')
  }

  return (
    <div className="invite-container">
      <Header title="邀请好友" />
      <div className="invite-list" onClick={() => history.push('/invite/record')}>邀请记录</div>
      <div className="poster-super">
        {poster !== '' && <img className="poster-img" src={poster} />}
      </div>
      <div className="poster-tip">长按保存图片</div>
      <canvas 
        className="invite-canvas"
        width="686"
        height="1028"
        ref={canvasRef}
      >
        你的浏览器不支持 canvas，请升级你的浏览器。
      </canvas>
      <div className="btn-super">
        <div className="btn-copy" onClick={handleCopy}>复制链接</div>
      </div>
    </div>
  )
}

export default Main
