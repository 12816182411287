/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-07-04 11:24:19
 */
import React, { useState, useEffect, useRef } from 'react'
import { productCategory, sellStatus, pagination, defaultHomeTab, entranceMenus } from '@/libs/constant'
import { formatHomeTime, getCurrentTime } from '@/libs/util'
import { Swiper, InfiniteScroll, PullToRefresh, Tabs, Toast } from 'antd-mobile'
import TabBar from '@/components/TabBar'
import api from '@/api'
import './index.scss'

let fixedTop = 0

function Home({ history }) {
  document.title = '无限数藏'
  const token = localStorage.getItem('token')
  const [page, setPage] = useState(pagination.page)
  const [perPage] = useState(pagination.perPage)
  const [productList, setProductList] = useState([])
  const [bannerList, setBannerList] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [loginVisible, setLoginVisible] = useState(false)
  const [certVisible, setCertVisible] = useState(false)
  const [saleVisible, setSaleVisible] = useState(false)
  const [channelList, setChannelList] = useState([])
  const [activeIndex, setActiveIndex] = useState(0)
  const [isTop, setIsTop] = useState(false)
  const [userInfo, setUserInfo] = useState({})
  const tabsRef = useRef(null)
  const navRef = useRef(null)

  useEffect(() => {
    initPage()
    // 还原tab状态
    const tabIndex = localStorage.getItem('tabIndex')
    if (typeof tabIndex === 'string') {
      const index = parseInt(tabIndex)
      api.getBannerList().then(res => {
        setBannerList(res.list)
        const tabs = defaultHomeTab.concat(res.channelList)
        setChannelList(tabs)
        if (tabs.length >= index + 1) {
          getList({
            curPage: 1,
            id: tabs[index].id
          })
          setActiveIndex(index)
        } else {
          getList()
        }
      })
    } else {
      // 请求banner, channel列表
      api.getBannerList().then(res => {
        setBannerList(res.list)
        setChannelList(defaultHomeTab.concat(res.channelList))
      })
      // 请求商品列表
      getList()
    }
    document.addEventListener('scroll', handleScroll, false)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (channelList.length > 0) {
      fixedTop = tabsRef.current.offsetTop - navRef.current.offsetHeight
    }
  }, [channelList])

  const handleScroll = () => {
    const scrollTop = Math.max(document.body.scrollTop, document.documentElement.scrollTop)
    if (scrollTop >= fixedTop) {
      setIsTop(true)
    } else {
      setIsTop(false)
    }
  }

  const initPage = async () => {
    // 是否登录
    if (token) {
      // 是否实名认证
      const res = await api.getUserInfo()
      setUserInfo(res)
      if (res && res.isCert === 2) {
        setCertVisible(true)
      }
    } else {
      setLoginVisible(true)
    }
    // 是否开启寄售入口
    api.getConfig().then(res => {
      if (res.consignment === 1) {
        setSaleVisible(true)
      }
    })
  }

  const getList = async params => {
    const curPage = (params && params.curPage) || page
    const id = (params && params.id) || 0
    let res = null
    if (parseInt(id) === 0) {
      res = await api.getProductList({ page: curPage, perPage })
    } else {
      res = await api.getChannelProductList({ page: curPage, perPage, id })
    }
    const { list, total } = res
    const totalPage = Math.ceil(total / perPage)

    if (curPage < totalPage) {
      setPage(curPage + 1)
      setHasMore(true)
    } else {
      setHasMore(false)
    }

    if (curPage === 1) {
      setProductList(list)
    } else {
      setProductList([...productList, ...list])
    }
  }

  const handleRefresh = () => {
    api.getBannerList().then(res => {
      setBannerList(res.list)
      const tabs = defaultHomeTab.concat(res.channelList)
      setChannelList(tabs)
      if (tabs.length >= activeIndex + 1) {
        getList({
          curPage: 1,
          id: tabs[activeIndex].id
        })
      } else {
        getList({
          curPage: 1,
          id: 0
        })
        setActiveIndex(0)
      }
    })
  }

  // 滚动到锚点
  const scrollToAnchor = id => {
    const anchorElement = document.getElementById(id)
    if(anchorElement) { 
      anchorElement.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const StatusView = ({ status, issuesTime }) => {
    let view = null
    switch (status) {
      case 1: 
        view = <>
          <img className="fire-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-sell-fire.png" />
          热销中
        </>
        break
      case 2: 
        view = <>
          <img className="time-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-sell-time.png" />
          {formatHomeTime(issuesTime)} 开售
        </>
        break
      case 3: 
        view = <>已售罄</>
        break
      case 4: 
        view = <>已结束</>
        break
    }
    return view
  }

  const clickBanner = data => {
    const { name, isJump, jumpType, jumpUrl, isCheckLogin, isCheckRealName } = data
    if (isJump === 2) return
    // 数据埋点
    const param = {
      bannerName: name,
      clickTime: getCurrentTime()
    }
    window.aplus_queue.push({
      action: 'aplus.record',
      arguments: ['banner_clicks', 'CLK', param]
    })
    switch (jumpType) {
      case 1:
        history.push(`/product?id=${jumpUrl}`)
        break
      case 2:
        // 是否需要登录
        if (isCheckLogin === 1 && !token) {
          history.push('/login')
        } else {
          // 是否需要实名认证
          if (isCheckRealName === 1 && userInfo.isCert === 2) {
            history.push('/my/certification')
          } else {
            window.location = jumpUrl
          }
        }
        break
      case 3:
        history.push(`/topic?id=${jumpUrl}`)
        break
      case 4:
        // 是否登录
        if (token) {
          history.push(`/welfare/detail?id=${jumpUrl}`)
        } else {
          history.push('/login')
        }
        break
      case 5:
        // 是否登录
        if (token) {
          history.push('/compose', { id: jumpUrl })
        } else {
          history.push('/login')
        }
        break
      case 6:
        history.push('/announce/detail', { id: jumpUrl })
    }
  }

  const handleTabChange = key => {
    const index = channelList.findIndex(item => item.id === parseInt(key))
    // 储存tab状态
    localStorage.setItem('tabIndex', index)
    setActiveIndex(index)
    setHasMore(false)
    getList({
      curPage: 1,
      id: key,
    })
    // 页面滚动到tab栏
    const scrollTop = Math.max(document.body.scrollTop, document.documentElement.scrollTop)
    if (scrollTop > fixedTop) {
      document.documentElement.scrollTop = fixedTop
      document.body.scrollTop = fixedTop
    }
  }

  const handleMore = async () => {
    await getList({
      id: channelList[activeIndex].id
    })
  }

  const handleClickItem = item => {
    if (item.type === 2) { // 专题
      history.push(`/topic?id=${item.id}`)
    } else { // 商品或盲盒
      history.push(`/product?id=${item.id}`)
    }
  }

  const clickEntrance = item => {
    switch(item.key) {
      case 'compose':
        if (token) {
          api.getSynthetic().then(res => {
            if (res.total > 0) {
              history.push('/compose')
            } else {
              Toast.show({
                content: '暂无活动'
              })
            }
          })
        } else {
          history.push('/login')
        }
        break
      case 'airdrop':
        history.push('/welfare')
        break
    }
  }

  return (
    <div className="home-container" id="top">
      <div className="nav-super" onClick={() => scrollToAnchor('top')} ref={navRef}>
        无限数藏
      </div>
      <div className="home-content">
        <PullToRefresh onRefresh={handleRefresh}>
          {bannerList.length > 0 && (
            <Swiper className="swiper-super" loop autoplay >
              {bannerList.map((item, index) => (
                <Swiper.Item key={index}>
                  <img className="swiper-img" src={item.pic} onClick={() => clickBanner(item)} />
                </Swiper.Item>
              ))}
            </Swiper>
          )}
          <div className="entrance-super">
            {entranceMenus.map((item, index) => (
              <div className="entrance-item" key={index} onClick={() => clickEntrance(item)}>
                <img className="icon" src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/home-entrance-${item.key}.png`} />
                {item.title}
              </div>
            ))}
            {saleVisible && (
              <div className="entrance-item" onClick={() => history.push('/sale')}>
                <img className="icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/home-entrance-sale.png" />
                寄售
              </div>
            )}
            <div className="entrance-item" onClick={() => history.push('/announce')}>
              <img className="icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/home-entrance-announce.png" />
              官方公告
            </div>
          </div>
          {channelList.length > 0 && (
            <div className={`tabs-super ${isTop ? 'top-fixed' : ''}`} ref={tabsRef}>
              <Tabs 
                activeKey={channelList[activeIndex].id.toString()} 
                onChange={key => handleTabChange(key)}
              >
                {channelList.map(item => <Tabs.Tab title={item.name} key={item.id}/>)}
              </Tabs>
            </div>
          )}
          {isTop && <div className="tabs-block"></div>}
          {productList.length === 0 ? (
            <div className="enpty-super">
              <img className="enpty-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/home-enpty.png" />
              {activeIndex === 0 ? '暂无商品' : '暂无内容'}
            </div>
          ) : (
            <>
              <div className="product-list">
                {productList.map((item, index) => (
                  <div className="product-item" key={index} onClick={() => handleClickItem(item)}>
                    {item.type === 2 ? ( // 专题
                      <>
                        <img className="topic-img" src={item.mainPic} />
                        <div className="content-super">
                          <div className="topic-title">{item.name}</div>
                          <div className="content">{item.content}</div>
                        </div>
                      </>
                    ) : ( // 商品或盲盒
                      <>
                        <img className="main-img" src={item.mainPic} />
                        <div className="content-super">
                          <div className="title">
                            {item.name}
                            {productCategory[item.categoryId - 1] && <img className="category-img" src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/category-${productCategory[item.categoryId - 1]}.png`} />}
                          </div>
                          <div className="tag-super">
                            <div className="limit-super">
                              <div className="limit-label">限量</div>
                              <div className="limit-num">{item.issuesNum}份</div>
                            </div>
                            {item.tagNames && item.tagNames.map((item, index) => (
                              <div className={`tag-${index + 1}`} key={index}>{item}</div>
                            ))}
                          </div>
                          <div className="creator-super">
                            <img className="avatar" src={item.creatorLogo} />
                            {item.creatorName}
                          </div>
                          <div className="price">
                            ¥ {item.price}
                          </div>
                        </div>
                        <div className={`sell-status-super ${sellStatus[item.status - 1]}`}>
                          <StatusView status={item.status} issuesTime={item.issuesTime} />
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
              <InfiniteScroll loadMore={handleMore} hasMore={hasMore} />
            </>
          )} 
        </PullToRefresh>
      </div>
      {loginVisible && <div className="tip-super">
        <div>登录后才可以购买数字藏品哦</div>
        <div className="btn-super">
          <div className="btn" onClick={() => history.push('/login')}>登录</div>
          <img 
            className="icon-close" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-close.png" 
            onClick={() => setLoginVisible(false)}
          />
        </div>
      </div>}
      {certVisible && <div className="tip-super">
        <div>实名认证后后才可以购买数字藏品哦</div>
        <div className="btn-super">
          <div className="btn" onClick={() => history.push('/my/certification')}>去认证</div>
          <img 
            className="icon-close" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-close.png" 
            onClick={() => setCertVisible(false)}
          />
        </div>
      </div>}
      <TabBar active={0} />
    </div>
  )
}

export default Home
