/*
 * @Description: 专题页面
 * @Author: 杨志航
 * @Date: 2022-09-30 13:37:59
 */
import React, { useState, useEffect } from 'react'
import Header from '@/components/Header'
import { InfiniteScroll, PullToRefresh } from 'antd-mobile'
import { sellStatus, pagination } from '@/libs/constant'
import { parseSearch, formatHomeTime, copyText } from '@/libs/util'
import api from '@/api'
import './index.scss'

function Topic({ history, location }) {
  const search = parseSearch(location.search)
  const [detail, setDetail] = useState({})
  const [page, setPage] = useState(pagination.page)
  const [perPage] = useState(pagination.perPage)
  const [productList, setProductList] = useState([])
  const [hasMore, setHasMore] = useState(true)
  
  useEffect(() => {
    getList()
  }, [])

  const getList = async curPage => {
    curPage = curPage || page
    const res = await api.getTopic({ id: search.id, page: curPage, perPage })
    const totalPage = Math.ceil(res.total / perPage)

    if (curPage < totalPage) {
      setPage(curPage + 1)
      setHasMore(true)
    } else {
      setHasMore(false)
    }

    if (curPage === 1) {
      setDetail(res)
      setProductList(res.productList)
    } else {
      setProductList([...productList, ...res.productList])
    }
  }

  const StatusView = ({ status, issuesTime }) => {
    let view = null
    switch (status) {
      case 1: 
        view = <>
          <img className="fire-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-sell-fire.png" />
          热销中
        </>
        break
      case 2: 
        view = <>
          <img className="time-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-sell-time.png" />
          {formatHomeTime(issuesTime)} 开售
        </>
        break
      case 3: 
        view = <>已售罄</>
        break
      case 4: 
        view = <>已结束</>
        break
    }
    return view
  }

  const handleCopy = () => {
    let url = window.location.href
    if (url.indexOf('&share=1') === -1) {
      url = url + '&share=1'
    }
    copyText(url, '已复制专题链接')
  }

  const handleBack = () => {
    if (search.share) {
      history.push('/home')
    } else {
      history.goBack()
    }
  }

  return (
    <div className="topic-container" style={{ backgroundColor: detail.backColor}}>
      <Header onClick={handleBack} />
      <img 
        className="icon-share" 
        src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-product-share.png" 
        onClick={handleCopy}
      />
      <img className="topic-banner" src={detail.mainPic} />
      <div className="topic-content">
        <div className="info" style={{ backgroundColor: detail.topicColor}}>
          <div className="name">{detail.name}</div>
          <div className="desc">{detail.content}</div>
          {detail.conPic && <img className="pic" src={detail.conPic} />}
        </div>
        <PullToRefresh onRefresh={() => getList(1)}>
          {productList.length > 0 && (
            <>
              <div className="product-list">
                {productList.map((item, index) => (
                  <div 
                    className="product-item" 
                    key={index} 
                    onClick={() => history.push(`/product?id=${item.id}`)} 
                    style={{ backgroundColor: detail.topicColor}}
                  >
                    <img className="main-img" src={item.mainPic} />
                    <div className="content-super">
                      <div className="title">{item.name}</div>
                      <div className="tag-super">
                        <div className="limit-super">
                          <div className="limit-label">限量</div>
                          <div className="limit-num">{item.issuesNum}份</div>
                        </div>
                      </div>
                      <div className="price">
                        ¥ {item.price}
                      </div>
                    </div>
                    <div className={`sell-status-super ${sellStatus[item.status - 1]}`}>
                      <StatusView status={item.status} issuesTime={item.issuesTime} />
                    </div>
                  </div>
                ))}
              </div>
              <InfiniteScroll loadMore={getList} hasMore={hasMore} />
            </>
          )}
        </PullToRefresh>
      </div>
    </div>
  )
}

export default Topic
