/*
 * @Description: 钱包
 * @Author: 杨志航
 * @Date: 2023-02-10 13:55:13
 */
import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import Main from './Main'
import Detail from'./Detail'

class Wallet extends Component {
  render () {
    const { path } = this.props.match

    return (
      <div>
        <Switch>
          <Route exact path={path + '/detail'} component={Detail}/>
          <Route component={Main} />
        </Switch>
      </div>
    )
  }
}

export default withRouter(Wallet)
