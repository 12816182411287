/*
 * @Description: 藏品
 * @Author: 杨志航
 * @Date: 2022-07-11 11:17:29
 */
import React, { useState, useEffect, useRef } from 'react'
import { productCategory, pagination } from '@/libs/constant'
import { InfiniteScroll, PullToRefresh, Toast, Tabs, Mask, Swiper } from 'antd-mobile'
import { Link } from 'react-router-dom'
import { parseSearch } from '@/libs/util'
import TabBar from '@/components/TabBar'
import api from '@/api'
import './index.scss'

const collectionTypes = [
  { key: 'create', title: '创作' },
  { key: 'collection', title: '藏品' },
]

function Main({ history, location }) {
  document.title = '无限数藏'
  const { state } = location
  const [page, setPage] = useState(pagination.page)
  const [perPage] = useState(pagination.perPage)
  const [collectionList, setCollectionList] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [publishVisible, setPublishVisible] = useState(false)
  const [createCount, setCreateCount] = useState(0)
  const [count, setCount] = useState(0)
  const [activeType, setActiveType] = useState(0)
  const token = localStorage.getItem('token')
  const [colWidth] = useState(166)
  const [leftList, setLeftList] = useState([])
  const [rightList, setRightList] = useState([])
  const [leftHeight, setLeftHeight] = useState(0)
  const [rightHeight, setRightHeight] = useState(0)
  const [conMaskVisible, setConMaskVisible] = useState(false)
  const [cerVisible, setCerVisible] = useState(false)
  const [cerId, setCerId] = useState('')
  const [cerEnable, setCerEnable] = useState('')
  const [isChangeTab, setIsChangeTab] = useState(false)
  const swiperRef = useRef(null)
  
  useEffect(() => {
    // 是否登录
    if (!token) {
      history.push('/login?route=home')
      return
    }
    // 获取列表
    const pageStorage = localStorage.getItem('collectionType')
    if (pageStorage) {
      const collectionType = JSON.parse(pageStorage)
      localStorage.removeItem('collectionType')
      setActiveType(collectionType.type)
      swiperRef.current.swipeTo(collectionType.type)
      if (collectionType.type === 0) {
        getList({ type: 0 })
      } else if (collectionType.type === 1) {
        getList({ type: 1 })
      }
    } else {
      if (state) {
        setActiveType(state.type)
        getList({ type: state.type })
      } else {
        getList({ type: 0 })
      }
    }
    // 是否开启寄售发布入口
    api.getConfig().then(res => {
      if (res.consignmentTab === 1) {
        setPublishVisible(true)
      }
    })
    // 初始total
    headTotal()

    document.documentElement.scrollTop = 0
  }, [])

  const headTotal = () => {
    api.getMyCreateList().then(res => {
      setCreateCount(res.total)
    })
    api.getCollectionList().then(res => {
      setCount(res.total)
    })
  }

  const getList = async (params = {}) => { 
    const curPage = params.page ?? page
    let obj = { page: curPage, perPage }
    let url = ''

    if (params.type === 0) {
      url = 'getMyCreateList'
    } else if (params.type === 1) {
      url = 'getCollectionList'
    }

    const { list, total } = await api[url](obj)
    const totalPage = Math.ceil(total / perPage)
    
    if (params.type === 0) {
      const arr = list.map(item => {
        const { width, height } = parseSearch(item.mainPic)
        item.height = Math.round(colWidth * (height / width)) + 80
        return item
      })
      handleWaterfall(arr, curPage)
    }

    if (curPage < totalPage) {
      setPage(curPage + 1)
      setHasMore(true)
    } else {
      setHasMore(false)
    }

    if (curPage === 1) {
      setCollectionList(list)
    } else {
      setCollectionList([...collectionList, ...list])
    }
  }

  const handleWaterfall = (list, curPage) => {
    let lh = 0,
      rh = 0,
      ll = [],
      rl = [];
    if (curPage > 1) {
      lh = leftHeight
      rh = rightHeight
      ll = leftList
      rl = rightList
    }

    list.forEach(item => {
      if (lh <= rh) {
        ll.push(item)
        lh = lh + item.height
      } else {
        rl.push(item)
        rh = rh + item.height
      }
    })
    setLeftHeight(lh)
    setRightHeight(rh)
    setLeftList(ll)
    setRightList(rl)
  }

  // 创作跳转详情
  const handleCreateItem = item => {
    api.getProductOne({ id: item.id }).then(res => {
      const { checkStatus } = res.data
      if (checkStatus === 1 || checkStatus === 3) {
        history.push('/collection/createDetail', { id: item.id })
      } else {
        history.push(`/product?id=${item.id}`)
      }
    })
  }

  const handleClickItem = item => {
    localStorage.setItem('collectionType', JSON.stringify({ type: activeType }))
    if (activeType === 0) {
      handleCreateItem(item) // 创作详情
    } else {
      if (item.subgiftId > 0) {
        history.push('/collection/subgift', { id: item.subgiftId })
      } else if (item.txHash === '') {
        Toast.show({
          content: '藏品正在发放中，请稍后'
        })
      } else {
        history.push('/collection/detail', { id: item.id })
      }
    }
  }

  const changeType = key => {
    setIsChangeTab(true)
    const index = collectionTypes.findIndex(item => item.key === key)
    setActiveType(index)
    document.documentElement.scrollTop = 0
    handleClickMask()
    setHasMore(false)
    setCollectionList([])
    swiperRef.current.swipeTo(index)
    
    if (key === 'create') {
      getList({ page: 1, type: 0 })
    } else if (key === 'collection') {
      getList({ page: 1, type: 1 })
    }
  }

  const handleSwiperChange = index => {
    if (isChangeTab) {
      setIsChangeTab(false)
      return
    }
    setActiveType(index)
    setHasMore(false)
    setCollectionList([])
    getList({ page: 1, type: index })
  }

  const StatusView = ({ status, checkStatus }) => {
    let view = null
    switch (checkStatus) {
      case 1:
        view = <div className="sell-status-super one">
          审核中
        </div>
        break
      case 2:
        if (status === 1) {
          view = <div className="sell-status-super two-audit">
            寄售中
          </div>
        } else if (status === 3) {
          view = <div className="sell-status-super two-finish">
            已售罄
          </div>
        } else if (status === 4) {
          view = <div className="sell-status-super two-stop">
            已停售
          </div>
        }
        break
      case 3:
        view = <div className="sell-status-super three">
          审核不通过
        </div>
        break
    }
    return view
  }

  // 点击省略号
  const onOption = (evt, id) => {
    evt.stopPropagation()
    const arrLeft = leftList.map((v, j) => {
      if (v.id === id) {
        v.showOpt = true
      } else {
        v.showOpt = false
      }
      return v
    })

    const arrRightList = rightList.map((v, j) => {
      if (v.id === id) {
        v.showOpt = true
      } else {
        v.showOpt = false
      }
      return v
    })
    setLeftList(arrLeft)
    setRightList(arrRightList)
    setConMaskVisible(true)
  }

  // 点击停售/寄售
  const clickOptionText = (evt, id, enable) => {
    evt.stopPropagation()
    setCerVisible(true)
    setCerId(id)
    setCerEnable(enable)
  }

  // 停售/寄售
  const onConfirmsell = () => {
    api.getSetEnable({ id: cerId, enable: cerEnable }).then(res => {
      setCerVisible(false)
      getList({ page: 1, type: 0 })
      const msg = cerEnable === 1 ? '寄售成功' : '停售成功'
      Toast.show({
        icon: 'success',
        content: msg,
        duration: 1000,
        afterClose: () => {
          setConMaskVisible(false)
        },
      })
    })
  }

  const onOff = () => {
    handleClickMask()
    setCerVisible(false)
  }

  // 点击蒙层
  const handleClickMask = () => {
    const arrLeft = leftList.map(item => {
      item.showOpt = false
      return item
    })
    const arrRightList = rightList.map(item => {
      item.showOpt = false
      return item
    })
    setLeftList(arrLeft)
    setRightList(arrRightList)
    setConMaskVisible(false)
  }

  const Item = ({ item }) => (
    <div className="item" onClick={() => handleClickItem(item)}>
      <div className="item-main-img">
        <img className="main-img" src={item.mainPic} />
        {
          item.checkStatus === 2 && (item.status === 1 || item.status === 4) &&
          <img
            className="icon-option"
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/collection-omit-icon.png"
            onClick={(evt) => onOption(evt, item.id)}
          />
        }
        {
          item.showOpt &&
          <div className='main-btn' onClick={evt => clickOptionText(evt, item.id, item.status === 1 ? 2 : 1)}>
            <img
              className="icon"
              src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/collection-${item.status === 1 ? 'stop' : 'audit'}-icon.png`}
            />
            <span>{item.status === 1 ? '停售' : '寄售'}</span>
          </div>
        }
      </div>
      <div className="info-super">
        <div className="name">{item.name}</div>
        <div className="data-super">
          <div className="price">¥{item.price}</div>
          <div className="hot-super">
            {productCategory[item.categoryId - 1] &&
              <img
                className="icon"
                src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/category-${productCategory[item.categoryId - 1]}.png`}
              />
            }
          </div>
        </div>
      </div>
      <StatusView status={item.status} checkStatus={item.checkStatus} />
    </div>
  )

  return (
    <div className="collection-container" id="top">
      {publishVisible && collectionList.length > 0 && activeType === 1 && (
        <img 
          className="entrance-publish" 
          src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/collection-entrance-publish.png" 
          onClick={() => history.push('/publish')}
        />
      )}
      <div className="tabs-super">
        <Tabs 
          activeKey={collectionTypes[activeType].key} 
          onChange={key => changeType(key)}
        >
          {collectionTypes.map(item => <Tabs.Tab title={(
            <div className="title-super">
              <div className="title">{item.title}</div>
              <div className="count">{item.key == 'create' ? createCount : count}</div>
            </div>
          )} key={item.key}/>)}
        </Tabs>
      </div>
      <div className="content-super-con">
        <PullToRefresh onRefresh={() => getList({ page: 1, type: activeType })}>
          <Swiper 
            direction='horizontal' 
            indicator={() => null} 
            ref={swiperRef} 
            defaultIndex={activeType} 
            onIndexChange={index => handleSwiperChange(index)}
          >
            {collectionTypes.map((item, index) => (
              <Swiper.Item key={item.key}>
                {activeType === index && (
                  <>
                    {collectionList.length === 0 ? (
                      <div className="enpty-super">
                        <img className="enpty-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/welfare-enpty.png" />
                        <div className="text">{activeType === 0 ? '哎呀，暂时没有作品信息' : '暂无藏品'}</div>
                        {activeType === 1 && <Link className="link" to="/home">去逛逛</Link>}
                      </div>
                    ) : (
                      <>
                        {activeType === 0 ?
                          <div className="create-list">
                            {conMaskVisible && <div className="content-mask" onClick={handleClickMask}></div>}
                            <div className="list">
                              {leftList.map((item, index) => <Item  key={index} item={item} />)}
                            </div>
                            <div className="list">
                              {rightList.map((item, index) => <Item  key={index} item={item} />)}
                            </div>
                          </div>
                          :
                          <div className="product-list">
                            {collectionList.map((item, index) => (
                              <div
                                className="product-item"
                                key={index}
                                onClick={() => handleClickItem(item)}
                              >
                                {item.subgiftId > 0 && (
                                  <div className="mask-super">
                                    <img className="icon-sending" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-subgift-status-sending.png" />
                                    转赠中
                                  </div>
                                )}
                                {item.txHash === '' && (
                                  <div className="mask-super">
                                    <img className="icon-providing" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-collection-providing.png" />
                                    发放中
                                  </div>
                                )}
                                <img className="main-img" src={item.productMainPic} />
                                <div className="content-super">
                                  <div className="title">
                                    {item.productName}
                                  </div>
                                  <div className="hash">
                                    {item.productBlockHash}
                                  </div>
                                  <div className="creator">
                                    {item.productCreatorName}
                                    {productCategory[item.productCategoryId - 1] &&
                                      <img
                                        className="category-img"
                                        src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/category-${productCategory[item.productCategoryId - 1]}.png`}
                                      />
                                    }
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        }
                        <InfiniteScroll loadMore={() => getList({ type: activeType })} hasMore={hasMore} />
                      </>
                    )}
                  </>
                )}
              </Swiper.Item>
            ))}
          </Swiper>
        </PullToRefresh>
      </div>
      <TabBar active={2} />
      <Mask visible={cerVisible} onMaskClick={() => setCerVisible(false)} >
        <div className="overlay-content">
          <div className="overlay-content-center">
            <div className="top-title">{`确认${cerEnable === 1 ? '寄售' : '停售'}该作品`}</div>
          </div>
          <div className="overlay-content-bottom">
            <div className="vertical-bar"></div>
            <div className="left" onClick={onOff}>取消</div>
            <div className="right" onClick={onConfirmsell}>确定</div>
          </div>
        </div>
      </Mask>
    </div>
  )
}

export default Main
