/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-07-11 09:23:00
 */
import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import Service from './Service'
import Secret from './Secret'
import Useage from './Useage'
import Baofu from './Baofu'
import Sale from './Sale'

class Agree extends Component {
  render () {
    const { path } = this.props.match

    return (
      <div>
        <Switch>
          <Route exact path={path + '/service'} component={Service}/>
          <Route exact path={path + '/secret'} component={Secret}/>
          <Route exact path={path + '/useage'} component={Useage}/>
          <Route exact path={path + '/baofu'} component={Baofu}/>
          <Route exact path={path + '/sale'} component={Sale}/>
        </Switch>
      </div>
    )
  }
}

export default withRouter(Agree)
