/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-09-15 13:44:09
 */
import React, { useState, useEffect } from 'react'
import { Mask, Toast, Input } from 'antd-mobile'
import Header from '@/components/Header'
import { getBankLogo } from '@/libs/util'
import api from '@/api'
import './index.scss'

let countTimer = null

function Main({ history, location }) {
	const { state } = location
  const [backVisible, setBackVisible] = useState(false)
  const [cardVisible, setCardVisible] = useState(false)
  const [codeVisible, setCodeVisible] = useState(false)
	const [detail, setDetail] = useState({})
  const [cardList, setCardList] = useState([])
  const [activeIndex, setActiveIndex] = useState(null)
  const [orderNo, setOrderNo] = useState('')
  const [uniqueCode, setUniqueCode] = useState('')
  const [smsCode, setSmsCode] = useState('')
  const [initialCount] = useState(60)
  const [count, setCount] = useState(initialCount)
  const [sending, setSending] = useState(false)
  const [confirmEnable, setConfirmEnble] = useState(false)

	useEffect(() => {
    const { productId, airdropId, consignmentId } = state
    let url = ''
    let obj = {}
    if (consignmentId) {
      // 寄售
      url = 'getProductOne'
      obj = { id: productId, consignmentId }
    } else if (productId) {
      // 商品
      url = 'getProductOne'
      obj = { id: productId }
    } else if (airdropId) {
      // 空投
      url = 'getAirdropDetail'
      obj = { id: airdropId }
    }
    // 获取商品信息
    api[url](obj).then(res => {
      // 空投详情为res
      setDetail(res.data || res)
    })
    // 获取银行卡列表
    api.getBankCardList({ perPage: 1000 }).then(res => {
      const { total, list } = res
      setCardList(list)
      if (total > 0) {
        setActiveIndex(0)
      }
    })
    return () => {
      // 页面关闭清除countdown
      clearInterval(countTimer)
    }
	}, [])

  useEffect(() => {
    if (count >= 0 && sending) {
      countTimer = setTimeout(() => {
        setCount(count - 1)
      }, 1000)
    } else {
      setCount(initialCount)
      setSending(false)
    }
  }, [count, sending])

  useEffect(() => {
		if (smsCode.length === 6) {
      setConfirmEnble(true)
    } else {
      setConfirmEnble(false)
    }
	}, [smsCode])

  const handlePay = orderNo => {
    api.orderPay({ orderNo, payType: 4, cardId: cardList[activeIndex].id }).then(res => {
      setUniqueCode(res.uniqueCode)
      setCodeVisible(true)
      setSending(true)
      setSmsCode('')
    })
  }

	const handleSubmit = () => {
    // 没有绑定银行卡
    if (activeIndex === null) {
      Toast.show({
        icon: 'fail',
        content: '请选择绑定的银行卡',
      })
      return
    }
    if (orderNo === '') {
      // 未创建订单，先创建订单，再发送验证码
      const { productId, airdropId, consignmentId } = state
      let url = ''
      let obj = {}
      if (consignmentId) {
        // 寄售
        url = 'orderCreate'
        obj = { consignmentId }
      } else if (productId) {
        // 商品
        url = 'orderCreate'
        obj = { productId }
      } else if (airdropId) {
        // 空投
        url = 'airdropGet'
        obj = { id: airdropId }
      }
      // 创建订单
      api[url](obj).then(data => {
        setOrderNo(data.orderNo)
        handlePay(data.orderNo)
      }).catch(err => {
        if (err.code === 120000) {
          Toast.show({
            icon: 'fail',
            content: err.message || '服务器发生错误，请联系管理员',
            afterClose: () => history.goBack(),
          })
        }
      })
    } else {
      // 已创建订单，直接发送验证码
      handlePay(orderNo)
    }
	}

  const codeModalClose = () => {
    setCodeVisible(false)
    clearInterval(countTimer)
  }

  const handleConfirm = () => {
    if (!confirmEnable) return
    api.orderConfirm({ uniqueCode, smsCode }).then(() => {
      setUniqueCode('')
      setSmsCode('')
      codeModalClose()
      history.push('/payment/paystatus', { orderNo, status: 'success' })
    }).catch(err => {
      if (err.code === 120003) {
        history.push('/payment/paystatus', { orderNo, status: 'pending' })
      } else {
        history.push('/payment/paystatus', { orderNo, status: 'fail' })
      }
    })
  }

  const handleSendCode = () => {
    if (sending) return
    api.orderPay({ orderNo, payType: 4, cardId: cardList[activeIndex].id }).then(res => {
      setUniqueCode(res.uniqueCode)
      setSending(true)
      setSmsCode('')
    })
  }

	return (
		<div className="payment-container">
			<Header title="订单支付" onClick={() => setBackVisible(true)} />
			<div className="section">
				<div className="title-top">
					<img className="title-top-icon" src={detail.creatorLogo} />
					<div>{detail.creatorName}</div>
				</div>
				<div className="title-bottom">
					<img className="title-bottom-icon" src={state.productId ? detail.mainPic : detail.pic} />
					<div className="introduce">
						<div className="pay-title">{state.productId ? detail.name : detail.productName}</div>
						<div className="quantity">x 1</div>
						<div className="price">￥ {detail.price}</div>
					</div>
				</div>
			</div>
			<div className="section">
				<div className="mode-payment-title">支付方式</div>
				<div className="mode-payment-content">
					<img className="mode-payment-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-paytype-bank.png" />
					<div>银行卡支付</div>
				</div>
        {cardList.length === 0 ? (
          <div className="add-bankcard" onClick={() => history.push('/payment/bindcard')}>
            添加银行卡
            <img className="arrow-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-right-arrow.png" />
          </div>
        ) : (
          <div className="bankcard-super">
            <div className="block">
              <img className="bank-icon" src={cardList.length > 0 && activeIndex !== null ? getBankLogo(cardList[activeIndex].bankName) : ''} />
              {cardList.length > 0 && activeIndex !== null && `${cardList[activeIndex].bankName}（${cardList[activeIndex].cardNo}）`}
            </div>
            <div className="block" onClick={() => setCardVisible(true)}>
              更换
              <img className="arrow-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-right-arrow.png" />
            </div>
          </div>
        )}
			</div>
			<div className="footer">
				<div className="footer-name">实付金额：<span>￥ {detail.price}</span></div>
				<div className="footer-button" onClick={handleSubmit}>确认支付</div>
			</div>
			<Mask 
        visible={backVisible} 
        onMaskClick={() => setBackVisible(false)} 
      >
        <div className="overlay-content">
          <div className="overlay-content-center">
            <div className="top-title">确定放弃本次支付？</div>
          </div>
          <div className="overlay-content-bottom">
            <div className="vertical-bar"></div>
            <div className="left" onClick={() => setBackVisible(false)}>继续支付</div>
            <div className="right" onClick={() => history.goBack()}>放弃</div>
          </div>
        </div>
      </Mask>
      <Mask
        visible={cardVisible} 
        onMaskClick={() => setCardVisible(false)} 
      >
        <div className="card-selector">
          <img 
            className="icon-close" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-close.png" 
            onClick={() => setCardVisible(false)}
          />
          <div className="title">
            选择银行卡
          </div>
          <div className="list-super">
            {cardList.map((item, index) => (
              <div className="item" key={index} onClick={() => setActiveIndex(index)}>
                <div className="card-info">
                  <img className="bank-icon" src={getBankLogo(item.bankName)} />
                  {`${item.bankName}（${item.cardNo}）`}
                </div>
                {activeIndex === index && 
                  <img className="choose-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-bankcard-choose.png" />
                }
              </div>
            ))}
          </div>
          <div className="add-super" onClick={() => history.push('/payment/bindcard')}>
            <img className="add-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-bankcard-add.png" />
            添加银行卡
          </div>
        </div>
      </Mask>
      <Mask
        visible={codeVisible} 
        onMaskClick={codeModalClose} 
      >
        <div className="code-super">
          <img 
            className="icon-close" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/login-icon-close.png" 
            onClick={codeModalClose}
          />
          <div className="title">支付验证</div>
          <div className="content">
            <div className="phone">{cardList.length > 0 && activeIndex !== null && cardList[activeIndex].mobile}</div>
            <Input 
              type="tel"
              placeholder='请输入验证码' 
              clearable 
              maxLength={6}
              value={smsCode}
              onChange={val => setSmsCode(val.trim())}
            />
          </div>
          <div 
            className={`countdown-btn ${sending ? '' : 'enable'}`}
            onClick={handleSendCode}
          >{sending ? `${count}s后重试` : '获取验证码'}</div>
          <div 
            className={`confirm-btn ${confirmEnable ? 'enable' : ''}`}
            onClick={handleConfirm}
          >确定</div>
        </div>
      </Mask>
		</div>
	)
}

export default Main
