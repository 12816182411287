/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-07-12 11:37:40
 */
import React from "react";
import { Html } from "@react-three/drei";

export default function Loader() {
  return (
    <Html center style={{ whiteSpace: 'nowrap', color: 'red' }}>
      <div className="spinner"></div>
    </Html>
  );
}
