/*
 * @Description: 登录
 * @Author: 杨志航
 * @Date: 2022-07-06 16:28:23
 */
import React, { useState, useEffect } from 'react'
import { regExt } from '@/libs/constant'
import { parseSearch } from '@/libs/util'
import { Input, Toast } from 'antd-mobile'
import api from '@/api'
import CaptchaCreator from '@/libs/CaptchaCreator'
import './index.scss'

let countTimer = null

function Login({ history, location }) {
  const search = parseSearch(location.search)
  const [mobile, setMobile] = useState('')
  const [smsCode, setSmsCode] = useState('')
  const [inviteCode, setInviteCode] = useState('')
  const [isAgree, setIsAgree] = useState(false)
  const [enable, setEnble] = useState(false)
  const [captcha, setCaptcha] = useState(null)
  const [captchaData, setCaptchaData] = useState(null)
  const [initialCount] = useState(60)
  const [count, setCount] = useState(initialCount)
  const [sending, setSending] = useState(false)
  const [sendAgain, setSendAgain] = useState(false)

  useEffect(() => {
    // 初始化滑块验证
    // const captchaObj = new CaptchaCreator({
    //   callback: data => setCaptchaData(data)
    // })
    // setCaptcha(captchaObj)
    // 恢复保存的值
    const memory = localStorage.getItem('loginState')
    if (memory) {
      const state = JSON.parse(memory)
      setMobile(state.mobile)
      setSmsCode(state.smsCode)
      setInviteCode(state.inviteCode)
      setIsAgree(state.isAgree === '1')
      setSendAgain(state.sendAgain === '1')
    }
    // 邀请链接设置邀请码
    if (search.inviteCode) {
      setInviteCode(search.inviteCode)
    }
    return () => {
      // captchaObj.destroy()
      clearTimeout(countTimer)
    }
  }, [])

  useEffect(() => {
    if (mobile.length === 11 && smsCode.length === 6) {
      setEnble(true)
    } else {
      setEnble(false)
    }
  }, [mobile, smsCode])

  useEffect(() => {
    if (count >= 0 && sending) {
      countTimer = setTimeout(() => {
        setCount(count - 1)
      }, 1000)
    } else {
      setCount(initialCount)
      setSending(false)
    }
  }, [count, sending])

  // useEffect(() => {
  //   if (captchaData) {
  //     handleCaptcha(captchaData)
  //   }
  // }, [captchaData])

  // 处理滑块验证返回
  const handleCaptcha = data => {
    const { ret, errorCode, ticket, randstr } = data
    if (ret !== 0) return
    if (errorCode) {
      Toast.show({
        icon: 'fail',
        content: '加载失败，请重试',
      })
      return
    }
    let params = { mobile, smsCode, ticket, randStr: randstr }
    if (inviteCode) {
      params.inviteCode = inviteCode
    }
    api.login(params).then(res => {
      localStorage.setItem('token', res.token)
      localStorage.setItem('mobile', mobile)
      localStorage.removeItem('loginState')
      Toast.show({
        icon: 'success',
        content: '登录成功',
        duration: 1000,
        afterClose: () => {
          if (search.inviteCode) {
            history.push('/home')
          } else {
            history.goBack()
          }
        } 
      })
    })
  }

  const handleSubmit = () => {
    if (!enable) return
    if (!regExt.phone.test(mobile)) {
      Toast.show({
        icon: 'fail',
        content: '手机号格式不正确',
      })
      return
    }
    if (!isAgree) {
      Toast.show({
        content: '请阅读并勾选用户服务协议、隐私政策',
      })
      return
    }
    // captcha.show()
    let params = { mobile, smsCode }
    if (inviteCode) {
      params.inviteCode = inviteCode
    }
    api.login(params).then(res => {
      localStorage.setItem('token', res.token)
      localStorage.setItem('mobile', mobile)
      localStorage.removeItem('loginState')
      Toast.show({
        icon: 'success',
        content: '登录成功',
        duration: 1000,
        afterClose: () => {
          if (search.inviteCode) {
            history.push('/home')
          } else {
            history.goBack()
          }
        } 
      })
    })
  }

  const handleBack = () => {
    if (search.inviteCode) {
      history.push('/home')
    } else if(search.route) {
      history.push(`/${search.route}`)
    } else {
      history.goBack()
    }
  }

  const handleSendCode = () => {
    if (sending) return
    if (mobile === '') {
      Toast.show({
        icon: 'fail',
        content: '请输入手机号',
      })
      return
    }
    if (!regExt.phone.test(mobile)) {
      Toast.show({
        icon: 'fail',
        content: '手机号格式不正确',
      })
      return
    }
    api.sendSms({ mobile }).then(() => {
      setSending(true)
      setSendAgain(true)
    })
  }

  const handleLink = url => {
    const state = {
      mobile,
      smsCode,
      inviteCode,
      isAgree: isAgree ? '1' : '0',
      sendAgain: sendAgain ? '1' : '0',
    }
    localStorage.setItem('loginState', JSON.stringify(state))
    history.push(url)
  }

  return (
    <div className="login-container">
      <div className="top-super">
        <img 
          className="icon-close" 
          src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/login-icon-close.png" 
          onClick={handleBack}
        />
      </div>
      <div className="header">
        <div className="title">登录无限数藏</div>
        <img className="banner" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/login-header-banner.png" />
      </div>
      <div className="content">
        <Input 
          type="tel" 
          placeholder='请输入手机号' 
          clearable 
          maxLength={11}
          value={mobile}
          onChange={val => setMobile(val.trim())}
        />
        <div className="sms-code-super">
          <Input 
            type="tel" 
            placeholder='请输入验证码' 
            maxLength={6}
            value={smsCode}
            onChange={val => setSmsCode(val.trim())}
          />
          <div className="extra-super" onClick={handleSendCode}>
            {sending ? (
              <div className="extra second">{count}s</div>
            ) : (
              <div className="extra btn">{sendAgain ? '重新发送' : '获取验证码'}</div>
            )}
          </div>
        </div>
        <Input 
          type="text" 
          placeholder='请输入邀请码（选填）' 
          maxLength={6}
          value={inviteCode}
          onChange={val => setInviteCode(val.trim())}
          disabled={!!search.inviteCode}
        />
        <div className="agree-super">
          <img 
            className="icon-checkbox" 
            src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/login-icon-${isAgree ? 'agree' : 'disagree'}.png`}
            onClick={() => setIsAgree(!isAgree)}
          />
          我已阅读并同意
          <span className="link" onClick={() => handleLink('/agree/service')}>《用户服务协议》</span>
          及<span className="link" onClick={() => handleLink('/agree/secret')}>《隐私政策》</span>
        </div>
        <div 
          className={`code-btn ${enable ? 'enable' : 'disable'}`}
          onClick={handleSubmit}
        >登录</div>
      </div>
    </div>
  )
}

export default Login
