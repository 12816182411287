/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-07-12 11:37:40
 */
import React, { useRef, useEffect } from "react";
import { useGLTF } from "@react-three/drei";
import { useThree } from '@react-three/fiber';
import { Box3, Vector3 } from 'three';

export default function Model(props) {
  const { controls, url } = props
  const group = useRef()
  const gltf = useGLTF(url || "https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/book.glb")
  const scene = gltf.scene || gltf.scenes[0]
  const { camera } = useThree()

  const setContent = (scene, controls, camera) => {
    const box = new Box3().setFromObject(scene);
    const size = box.getSize(new Vector3()).length();
    const center = box.getCenter(new Vector3());
    scene.position.x += (scene.position.x - center.x);
    scene.position.y += (scene.position.y - center.y);
    scene.position.z += (scene.position.z - center.z);
    controls.maxDistance = size * 10;
    camera.near = size / 100;
    camera.far = size * 100;
    camera.updateProjectionMatrix();
    // camera.position.copy(center);
    camera.position.x += size / 10;
    camera.position.y += size / 10;
    camera.position.z += size / 0.8;
    // camera.lookAt(center);
    controls.saveState()
  }

  useEffect(() => {
    setContent(scene, controls.current, camera)
    return () => {
      // 位置还原
      scene.position.x = 0
      scene.position.y = 0
      scene.position.z = 0
    }
  }, [])

  return (
    <mesh 
      ref={group} 
      {...props} 
      dispose={null}
    >
      <primitive object={scene} />
    </mesh>
  );
}
