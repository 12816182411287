/*
 * @Description: 寄售
 * @Author: 杨志航
 * @Date: 2023-02-21 14:05:42
 */
import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import Main from './Main'
import Order from './Order'
import SellWait from './SellWait'
import SellDone from './SellDone'
import BuyDetail from './BuyDetail'

class Sale extends Component {
  render () {
    const { path } = this.props.match

    return (
      <div>
        <Switch>
          <Route exact path={path + '/order'} component={Order}/>
          <Route exact path={path + '/sellwait'} component={SellWait}/>
          <Route exact path={path + '/selldone'} component={SellDone}/>
          <Route exact path={path + '/buydetail'} component={BuyDetail}/>
          <Route component={Main} />
        </Switch>
      </div>
    )
  }
}

export default withRouter(Sale)
