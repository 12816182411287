/*
 * @Description: 账单详情
 * @Author: 杨志航
 * @Date: 2023-02-15 13:34:41
 */
import React, { useState, useEffect } from 'react'
import HeaderWhite from '@/components/HeaderWhite'
import api from '@/api'
import './index.scss'

const statusIcons = ['undo', 'success', 'fail']

function Detail({ history, location }) {
  const { state } = location
  const [detail, setDetail] = useState({})

  useEffect(() => {
    api.getBillInfo({ id: state.id }).then(res => {
      setDetail(res)
    })
  }, [])

  return (
    <div className="bill-detail-container">
      <HeaderWhite />
      <div className="bill-money-super">
        <div className="title">{detail.title}</div>
        {Number(detail.amount) > 0 && (
          <div className="amount">
            <div className="prev">+</div>
            <div className="mark">¥</div>
            {detail.amount}
          </div>
        )}
        {Number(detail.amount) < 0 && (
          <div className="amount">
            <div className="prev">-</div>
            <div className="mark">¥</div>
            {detail.amount?.slice(1)}
          </div>
        )}
        <div className="balance">余额 ¥{detail.balance}</div>
        <img className="border" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/bill-process-border.png" />
      </div>
      {detail.type === 2 && (
        <div className="bill-process-super">
          <div className="label">当前状态</div>
          <div className="process-list">
            <img className="line" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/bill-process-line.png" />
            <div className="item">
              <div className="name">发起提现</div>
              <div>{detail.applyTime}</div>
              <img className="dot" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/bill-process-dot-done.png" />
            </div>
            <div className={`item ${detail.cashStatus === 1 ? 'active' : ''}`}>
              <div className="name">平台审核中</div>
              <img className="dot" src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/bill-process-dot-${detail.cashStatus === 1 ? 'success' : 'done'}.png`} />
            </div>
            <div className={`item ${detail.cashStatus !== 1 ? 'active' : ''}`}>
              <div className="name">{detail.cashStatus !== 3 ? '提现成功' : '提现失败'}</div>
              {detail.cashStatus !== 1 && <div>{detail.finishTime}</div>}
              <img className="dot" src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/bill-process-dot-${statusIcons[detail.cashStatus - 1]}.png`} />
            </div>
          </div>
          <img className="border" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/bill-process-border.png" />
        </div>
      )}
      {detail.type === 1 && (
        <div className="bill-info-super">
          <div className="item">
            <div className="label">订单金额</div>
            <div className="value">¥{detail.price}</div>
          </div>
          <div className="item">
            <div className="label">订单收入</div>
            <div className="value">¥{detail.preIncome}</div>
          </div>
          {detail.technicalServiceRate != 0 && (
            <div className="item">
              <div className="label">技术服务费{detail.technicalServiceRate}%</div>
              <div className="value">¥{detail.technicalServiceFee}</div>
            </div>
          )}
          {detail.payChannelRate != 0 && (
            <div className="item">
              <div className="label">支付通道费{detail.payChannelRate}%</div>
              <div className="value">¥{detail.payChannelFee}</div>
            </div>
          )}
          {detail.blockchainServiceRate != 0 && (
            <div className="item">
              <div className="label">区块链服务费{detail.blockchainServiceRate}%</div>
              <div className="value">¥{detail.blockchainServiceFee}</div>
            </div>
          )}
          {detail.transactionServiceRate != 0 && (
            <div className="item">
              <div className="label">交易服务费{detail.transactionServiceRate}%</div>
              <div className="value">¥{detail.transactionServiceFee}</div>
            </div>
          )}
          <div className="item">
            <div className="label">交易时间</div>
            <div className="value">{detail.addTime}</div>
          </div>
          <div className="item">
            <div className="label">交易单号</div>
            <div className="value">{detail.orderNo}</div>
          </div>
        </div>
      )}
      {detail.type === 2 && (
        <div className="bill-info-super">
          <div className="item">
            <div className="label">提现金额</div>
            <div className="value">¥{Number(detail.amount) > 0 ? detail.amount : detail.amount?.slice(1)}</div>
          </div>
          {detail.cashStatus === 2 && (
            <div className="item">
              <div className="label">到账金额</div>
              <div className="value">¥{detail.received}</div>
            </div>
          )}
          <div className="item">
            <div className="label">申请时间</div>
            <div className="value">{detail.applyTime}</div>
          </div>
          {detail.cashStatus === 2 && (
            <div className="item">
              <div className="label">到账时间</div>
              <div className="value">{detail.finishTime}</div>
            </div>
          )}
          <div className="item">
            <div className="label">提现单号</div>
            <div className="value">{detail.number}</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Detail
