/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2023-01-09 14:06:54
 */
import React, { useState, useEffect } from 'react'
import HeaderWhite from '@/components/HeaderWhite'
import api from '@/api'
import { Mask, Toast, InfiniteScroll } from 'antd-mobile'
import { pagination, machinePics } from '@/libs/constant'
import './index.scss'

let machineTimer = null

function Compose({ history, location }) {
  const { state } = location
  const [detail, setDetail] = useState({})
  const [materialList, setMaterialList] = useState([])
  const [selectList, setSelectList] = useState([])
  const [addVisible, setAddVisible] = useState(false)
  const [arrowVisible, setArrowVisible] = useState(false)
  const [maxNum, setMaxNum] = useState(0)
  const [activeId, setActiveId] = useState(0)
  const [ruleVisible, setRuleVisible] = useState(false)
  const [page, setPage] = useState(pagination.page)
  const [perPage] = useState(pagination.perPage)
  const [hasMore, setHasMore] = useState(true)
  const [btnText, setBtnText] = useState('')
  const [successVisible, setSuccessVisible] = useState(false)
  const [failVisible, setFailVisible] = useState(false)
  const [award, setAward] = useState({})
  const [machineSrc, setMachineSrc] =useState(machinePics.static)
  const [tipVisible, setTipVisible] = useState(false)
  const [playing, setPlaying] = useState(false)
  
  useEffect(() => {
    if (state && state.id) {
      getComposeInfo({ currentId: state.id, flag: 2 })
    } else {
      getComposeInfo()
    }
    // 提前加载合成gif图
    const newImage = new Image()
    newImage.src = machinePics.dynamic
    return () => {
      clearTimeout(machineTimer)
    }
  }, [])

  const getComposeInfo = (params = {}) => {
    api.getSynthetic(params).then(res => {
      const { total, data } = res
      const { type, materialNum, status, isLimited, totalNum, hadNum } = data
      let list = []
      setDetail(data)
      if (total > 1) {
        setArrowVisible(true)
      }
      if (type === 1) {
        list = data.materialList.map(item => {
          item.having.forEach((v, i) => {
            if (i + 1 <= item.num) {
              v.checked = true
            } else {
              v.checked = false
            }
          })
          return item
        })
        setMaterialList(list)
      } else if (type === 2) {
        setMaxNum(materialNum)
        list = Array(materialNum).fill({ id: 0 })
        setMaterialList(list)
      }
      // 设置按钮文案
      let text = ''
      switch (true) {
        case status === 2:
          text = '活动未开始'
          break
        case status === 3:
          text = '活动结束'
          break
        case totalNum - hadNum === 0:
          text = '已兑完'
          break
        case isLimited === 1:
          text = '已达上限'
          break
        default:
          text = getText(type, materialNum, list)
      }
      setBtnText(text)
    })
  }

  const getText = (type, materialNum, list) => {
    let text = ''
    switch (type) {
      case 1:
        let m = 0, n = 0
        list.forEach(item => {
          m += item.num
          item.having.forEach(v => {
            if (v.checked) n++
          })
        })
        if (m > n) {
          text = '材料不足'
        } else {
          text = '立即合成'
        }
        break
      case 2:
        if (list.filter(item => item.id !== 0).length < materialNum) {
          text = '材料不足'
        } else {
          text = '立即合成'
        }
        break
    }
    return text
  }

  const getList = async curPage => {
    curPage = curPage || page
    const { list, total } = await api.getMaterialList({ id: detail.id, page: curPage, perPage })
    const totalPage = Math.ceil(total / perPage)

    if (curPage < totalPage) {
      setPage(curPage + 1)
      setHasMore(true)
    } else {
      setHasMore(false)
    }

    if (curPage === 1) {
      setSelectList(list)
    } else {
      setSelectList([...selectList, ...list])
    }
  }

  const handleClickMaterial = item => {
    const { having, num, productId } = item
    const list = JSON.parse(JSON.stringify(having))
    setMaxNum(num)
    setActiveId(productId)
    setSelectList(list)
    setAddVisible(true)
  }

  const handleSelectMaterial = () => {
    if (selectList.length === 0) {
      getList()
    } else {
      const list = selectList.map(item => {
        let checked = false
        materialList.forEach(v => {
          if (v.id === item.id) {
            checked = true
          }
          item.checked = checked
        })
        return item
      })
      setSelectList(list)
    }
    setAddVisible(true)
  }

  const handleClickCollection = item => {
    const { id, checked } = item
    if (maxNum === selectList.filter(v => v.checked).length && !checked) {
      Toast.show({
        content: `最多选择${maxNum}个，请取消其他藏品勾选后再勾选`
      })
      return
    }
    const list = selectList.map(v => {
      if (v.id === id) {
        v.checked = !v.checked
      }
      return v
    })
    setSelectList(list)
  }

  const handleConfirm = () => {
    const { type, materialNum, status, isLimited, totalNum, hadNum } = detail
    let list = []
    if (type === 1) {
      list = materialList.map(item => {
        if (item.productId === activeId) {
          item.having = selectList
        }
        return item
      })
      setMaterialList(list)
    } else if (type === 2) {
      list = selectList.filter(item => item.checked)
      if (list.length === maxNum) {
        setMaterialList(list)
      } else {
        list = list.concat(Array(maxNum - list.length).fill({ id: 0 }))
        setMaterialList(list)
      }
    }
    // 设置按钮文案
    if (status === 1 && isLimited === 0 && totalNum - hadNum !== 0) {
      setBtnText(getText(type, materialNum, list))
    }
    setAddVisible(false)
  }

  const handlePagenation = deriction => {
    resetActivity({
      currentId: detail.id,
      flag: deriction === 'prev' ? 0 : 1,
    })
  }

  const resetActivity = params => {
    setSelectList([])
    setPage(1)
    getComposeInfo(params)
  }

  const handleCompose = () => {
    if (btnText !== '立即合成') return
    setTipVisible(true)
  }

  const handleSubmit = () => {
    setTipVisible(false)
    setPlaying(true)
    const { id, type } = detail
    let selectedIds = []
    if (type === 1) {
      materialList.forEach(item => {
        item.having.forEach(v => {
          if (v.checked) {
            selectedIds.push(v.id)
          }
        })
      })
    } else if (type === 2) {
      materialList.forEach(item => {
        selectedIds.push(item.id)
      })
    }
    api.syntheticPlay({ 
      id, 
      materialList: selectedIds 
    }).then(res => {
      setMachineSrc(machinePics.dynamic)
      machineTimer = setTimeout(() => {
        setMachineSrc(machinePics.static)
        if (res.success === 1) {
          setAward(res.award)
          setSuccessVisible(true)
        } else {
          setFailVisible(true)
        }
        resetActivity({ currentId: detail.id, flag: 2 })
        setPlaying(false)
      }, 2200)
    }).catch(() => setPlaying(false))
  }

  return (
    <div className="compose-container">
      <HeaderWhite title="合成机" />
      <div className="compose-banner-super">
        <img className="machine" src={machineSrc} />
        <div className="content">
          <div className="title">{detail.name}</div>
          <div className="count-super">
            <div className="text">
              <div className="label">限量</div>
              <div className="number">{detail.totalNum}份</div>
            </div>
            <div className="text">
              <div className="label">剩余</div>
              <div className="number">{detail.totalNum - detail.hadNum}份</div>
            </div>
          </div>
          <div 
            className={`compose-btn ${btnText !== '立即合成' && 'disable'}`}
            onClick={handleCompose}
          >{btnText}</div>
        </div>
        <img 
          className="rule" 
          src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/compose-rule-btn.png" 
          onClick={() => setRuleVisible(true)}
        />
        {arrowVisible && (
          <div className="arrow-super">
            <img 
              className="arrow prev" 
              src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/compose-arrow-btn.png" 
              onClick={() => handlePagenation('prev')}
            />
            <img 
              className="arrow" 
              src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/compose-arrow-btn.png" 
              onClick={() => handlePagenation('next')}
            />
          </div>
        )}
      </div>
      <div className="compose-material-super">
        <div className="material-bg"></div>
        <div className="material-title">消耗材料</div>
        {detail.type === 1 && (
          <div className="material-list">
            {materialList.map((item, index) => (
              <div 
                className={`item ${item.having && item.having.filter(v => v.checked).length < item.num && 'disable'}`} 
                key={index} 
                onClick={() => handleClickMaterial(item)}
              >
                <div className="need">{item.having && item.having.filter(v => v.checked).length}/{item.num}</div>
                <img className="pic" src={item.productMainPic} />
                <div className="name">{item.productName}</div>
              </div>
            ))}
          </div>
        )}
        {detail.type === 2 && (
          <div className="material-list">
            {materialList.map((item, index) => (
              <div 
                className={`item ${item.id === 0 && 'disable'}`} 
                key={index} 
                onClick={handleSelectMaterial}
              >
                {item.id === 0 ? (
                  <img className="pic" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/compose-add-pic.png" />
                ) : (
                  <img className="pic" src={item.productMainPic} />
                )}
                <div className="name">{item.id === 0 ? '—' : item.productName}</div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="compose-intro-super">
        <div className="intro-title">合成须知：</div>
        <div className="text">请您仔细阅读并同意以下规则后操作，以免造成损失</div>
        <div className="text">1、合成所需材料需上链完成后方可使用，发放中/转赠中藏品不可用做合成材料；</div>
        <div className="text">2、合成是不可逆的，一经合成原材料将被回收；</div>
        <div className="text">3、合成前请您仔细了解合成玩法、权益及条件，以便您做出理性决策</div>
      </div>
      <Mask visible={addVisible} onMaskClick={() => setAddVisible(false)}>
        <div className="compose-add-modal">
          <div className="title">添加材料</div>
          <img 
            className="icon-close" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/login-icon-close.png" 
            onClick={() => setAddVisible(false)}
          />
          {selectList.length === 0 ? (
            <div className="enpty-super">
              <img className="icon-enpty" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/welfare-enpty.png" />
              <div className="enpty-tip">没有可添加的材料</div>
            </div>
          ) : (
            <div className="content-super">
              <div className="collection-list">
                {selectList.map((item, index) => (
                  <div className="item" key={index} onClick={() => handleClickCollection(item)}>
                    {maxNum === selectList.filter(item => item.checked).length && !item.checked && (
                      <div className="disable-mask"></div>
                    )}
                    <div className="info-super">
                      <img className="img" src={item.productMainPic} />
                      <div className="text-super">
                        <div className="name">{item.productName}</div>
                        <div className="no">藏品编号：{item.tokenNo}</div>
                      </div>
                    </div>
                    <img className="icon-check" src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-compose-${item.checked ? 'checked': 'uncheck'}.png`} />
                  </div>
                ))}
                {detail.type === 2 && <InfiniteScroll loadMore={getList} hasMore={hasMore} />}
              </div>
              <div className="confirm-btn" onClick={handleConfirm}>
                确定（{selectList.filter(item => item.checked).length}）
              </div>
            </div>
          )}
        </div>
      </Mask>
      <Mask visible={ruleVisible} onMaskClick={() => setRuleVisible(false)}>
        <div className="compose-rule-modal">
          <div className="title">- 合成说明 -</div>
          <img 
            className="icon-close" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-close.png" 
            onClick={() => setRuleVisible(false)}
          />
          <div className="rule-info" dangerouslySetInnerHTML={{__html: detail.desc}}></div>
        </div>
      </Mask>
      <Mask visible={successVisible} style={{ backgroundColor: 'rgba(0, 0, 0, .9)' }}>
        <div className="compose-success-modal">
          <img className="title-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/compose-success-title.png" />
          <div className="top-tip">恭喜你获得</div>
          <img className="award-img" src={award.mainPic} />
          <div className="award-name">{award.name}</div>
          <div className="bottom-tip">数字藏品正在发放中，可稍后在“藏品”查看</div>
          <div className="continue-btn" onClick={() => setSuccessVisible(false)}>继续合成</div>
          <img 
            className="icon-close" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/compose-result-close.png" 
            onClick={() => setSuccessVisible(false)}
          />
          <img className="star-mask" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/compose-success-bg.png" />
        </div>
      </Mask>
      <Mask visible={failVisible} style={{ backgroundColor: 'rgba(0, 0, 0, .9)' }}>
        <div className="compose-fail-modal">
          <img className="title-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/compose-fail-title.png" />
          <div className="top-tip">哎呀，失败了</div>
          <div className="bottom-tip">好运虽会迟到，但总会到</div>
          <div className="continue-btn" onClick={() => setFailVisible(false)}>再试一次</div>
          <img 
            className="icon-close" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/compose-result-close.png" 
            onClick={() => setFailVisible(false)}
          />
        </div>
      </Mask>
      <Mask visible={tipVisible} onMaskClick={() => setTipVisible(false)}>
        <div className="overlay-content">
          <div className="overlay-content-top">
            <div className="top-title">合成提示</div>
            <div className="top-content">合成后被销毁的材料将不予退回，<br />合成不可撤销，是否确定继续操作？</div>
          </div>
          <div className="overlay-content-bottom">
            <div className="vertical-bar"></div>
            <div className="left" onClick={() => setTipVisible(false)}>取消合成</div>
            <div className="right" onClick={handleSubmit}>继续合成</div>
          </div>
        </div>
      </Mask>
      <Mask visible={playing} style={{ backgroundColor: 'transparent' }}></Mask>
    </div>
  )
}

export default Compose
