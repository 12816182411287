/*
 * @Description: 
 * @Author: 杨志航<yangzhihang@372163.com>
 * @Date: 2021-11-23 08:26:18
 */

import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Routes from './routes'
import { noBtnRouters } from './libs/constant'

function App({ location }) {
  const { pathname } = location
  const btnEle = document.getElementById('appDownloadBtn')

  useEffect(() => {
    // 下载app按钮点击事件
    btnEle.addEventListener('click', () => window.open('https://nft.372163.com/h5/share'))
    // 设置rem基础值
    const html = document.querySelector('html');    
    const width = html.getBoundingClientRect().width
    html.style.fontSize = width / 3.75 + 'px';
    // 去掉page loading
    const root = document.getElementById('app')
    root.style.backgroundImage = 'none'
    root.style.minHeight = 'initial'
  }, [])

  useEffect(() => {
    if (noBtnRouters.some(router => pathname.indexOf(router) > -1)) {
      btnEle.style.display = 'none'
    } else {
      btnEle.style.display = 'block'
    }
  }, [pathname])

  return (
    <Routes/>
  )
}

export default withRouter(App)
