/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-07-06 16:28:23
 */
/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-07-08 15:37:58
 */
import React from 'react'
import Header from '@/components/Header'
import { dealMobile } from '@/libs/util'
import api from '@/api'
import './index.scss'

function Main({ history }) {
  const mobile = localStorage.getItem('mobile')
  const handleSubmit = () => {
    api.sendSms({ mobile }).then(() => {
      history.push('/abandon/code')
    })
  }

  return (
    <div className="abandon-container">
      <Header title="注销账号" />
      <div className="content">
        <div className="label">当前账号</div>
        <div className="mobile">{dealMobile(mobile)}</div>
        <div className="desc">
          <div className="title">注销须知：</div>
          <div className="text">· 注销成功后，您的个人资料、购买记录、藏品等数据都会清空且不可重新获取，您将无法再以该账号登录本APP。</div>
          <div className="text">· 账号注销不可逆，即使您使用相同手机号再次注册，也无法访问您之前的数字藏品。</div>
          <div className="text">· 注销账号有30天犹豫期，30天内您仍可以继续登录该账号，登录成功则停止注销流程，您可以重新申请注销。</div>
        </div>
        <div className="confirm-btn" onClick={handleSubmit}>我已了解，继续</div>
      </div>
    </div>
  )
}

export default Main
