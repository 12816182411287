/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-11-08 11:33:31
 */
import React, { useState, useEffect, useRef } from 'react'
import { Toast } from 'antd-mobile'
import CanvasTool from '@/libs/CanvasTool'
import './index.scss'

function Shining({ history, location }) {
  const [poster, setPoster] = useState('')
  const canvasRef = useRef(null)

  useEffect(async () => {
    Toast.show({
      icon: 'loading',
      content: '加载中…',
      duration: 0,
    })
    // 获得canvas元素
    const canvas = canvasRef.current
    // 获得2d上下文对象
    const ctx = canvas.getContext("2d")
    // 检测支持性
    if(!ctx) return
    const tool = new CanvasTool({ ctx })
    // 绘制画布
    await canvasDraw(tool, location.state.detail)
    // 设置海报图片链接
    setPoster(canvas.toDataURL('image/png'))
    Toast.clear()
  }, [])

  const canvasDraw = async (tool, data) => {
    const posterImage = 'https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/collection-shining-bg.png'
    const dashedImage = 'https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/collection-shining-dashed.png'
    const logoImage = 'https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/poster-nft-logo.png'
    
    await tool.drawImage(posterImage, 0, 0, 640, 896)
    tool.drawText({
      text: data.productName,
      color: '#FFFFFF',
      size: 32,
      x: 40,
      y: 40,
      bold: true,
    })
    tool.drawText({
      text: `创作者：${data.productCreatorName}`,
      color: '#979797',
      size: 28,
      x: 40,
      y: 94,
    })
    await tool.drawImage(data.productMainPic, 140, 174, 360, 360)
    tool.drawText({
      text: '收藏时间',
      color: '#979797',
      size: 28,
      x: 38,
      y: 574,
      bold: true,
    })
    tool.drawText({
      text: '持有者',
      color: '#979797',
      size: 28,
      x: 284,
      y: 574,
      bold: true,
    })
    tool.drawText({
      text: '编号',
      color: '#979797',
      size: 28,
      x: 524,
      y: 574,
      bold: true,
    })
    tool.drawText({
      text: data.txTime ? data.txTime.slice(0, 10).replace(/-/g, '.') : '生成中...',
      color: '#FFFFFF',
      size: 28,
      x: 28,
      y: 624,
    })
    tool.drawText({
      text: data.ownerName,
      color: '#FFFFFF',
      size: 28,
      x: 256,
      y: 624,
      maxWidth: 216,
    })
    tool.drawText({
      text: data.tokenNo,
      color: '#FFFFFF',
      size: 28,
      x: 508,
      y: 624,
    })
    await tool.drawImage(dashedImage, 24, 704, 590, 2)
    await tool.drawImage(logoImage, 24, 746, 80, 80)
    tool.drawText({
      text: '无限数藏',
      color: '#FFFFFF',
      size: 28,
      x: 124,
      y: 766,
      bold: true,
    })
    tool.drawText({
      text: '打开无限数藏APP，探索更多数字藏品',
      color: '#88B6FD',
      size: 24,
      x: 24,
      y: 830,
    })
    await tool.drawImage(data.shareLink, 496, 746, 120, 120)
  }

  return (
    <div className="collection-shining-container">
      <div className="top-super">
        <img 
          className="icon-close" 
          src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/login-icon-close.png" 
          onClick={() => history.goBack()}
        />
      </div>
      <div className="poster-super">
        {poster !== '' && <img className="poster-img" src={poster} />}
      </div>
      <div className="poster-tip">长按保存图片</div>
      <canvas 
        className="invite-canvas"
        width="640"
        height="896"
        ref={canvasRef}
      >
        你的浏览器不支持 canvas，请升级你的浏览器。
      </canvas>
    </div>
  )
}

export default Shining
