/*
 * @Description: 作品标签
 * @Author: 朱燕
 * @Date: 2023-04-03 15:12:25
 */
import React, { useState, useEffect } from 'react'
import { Checkbox, Grid, Toast } from 'antd-mobile'
import Header from '@/components/Header'
import api from '@/api'
import './index.scss'

function Tags({ history }) {
  const [value, setValue] = useState('')
  const [tagsList, setTagsList] = useState([])

  useEffect(() => {
    api.getProductTags().then(res => {
      setTagsList(res)
    })
  }, [])

  useEffect(() => {
    if (tagsList.length > 0) {
      const data = JSON.parse(localStorage.getItem('productData'))
      setValue(data.tags.map(item => item.id))
    }
  }, [tagsList])

  const handleSubmit = () => {
    if (value === '') {
      Toast.show({
        icon: 'fail',
        content: `请选择作品标签`
      })
      return
    }
    const data = JSON.parse(localStorage.getItem('productData'))
    data.tags = tagsList.filter(item => value.includes(item.id))
    localStorage.setItem('productData', JSON.stringify(data))
    history.goBack()
  }

  return (
    <div className="creator-tags-container">
      <Header title="作品标签" />
      <div className="item">
        <div className="label-super">
          <span className="require-star">*</span>
          选择作品标签
        </div>
        <Checkbox.Group
          value={value}
          onChange={val => {
            if (val.length > 3) {
              Toast.show({
                content: `最多勾选3个标签`
              })
              return
            }
            setValue(val)
          }}
        >
          <Grid columns={3} style={{ '--gap-horizontal': '12px' }}>
            {tagsList.map(item =>
              <Grid.Item key={item.id} className="creator-tags-btn">
                <Checkbox
                  value={item.id}
                  style={{
                    '--icon-size': '12px',
                    '--font-size': '12px',
                    '--gap': '6px',
                  }}
                >
                  {item.name}
                </Checkbox>
              </Grid.Item>
            )}
          </Grid>
        </Checkbox.Group>
      </div>
      <div className="submit-super">
        <div className="submit-btn" onClick={handleSubmit}>提交</div>
      </div>
    </div>
  )
}

export default Tags
