/*
 * @Description: 支付结果页
 * @Author: 杨志航
 * @Date: 2022-09-15 13:53:07
 */
import React, { useEffect, useState } from 'react'
import { Toast, Mask } from 'antd-mobile'
import api from '@/api'
import './index.scss'

function PayStatus({ history, location }) {
	const { state } = location
	const [detail, setDetail] = useState({})
  const [successVisible, setSuccessVisible] = useState(false)
  const [boxVisible, setBoxVisible] = useState(false)

	useEffect(() => {
		Toast.clear()
		api.getOrderInfo({ orderNo: state.orderNo }).then(res => {
			setDetail(res)
			// 展示盲盒动画
			if (state.status === 'success' && res.type === 3) {
				setBoxVisible(true)
				setTimeout(() => {
					setBoxVisible(false)
					setSuccessVisible(true)
				}, 1600)
			}
		})
	}, [])

	const handleGoDetail = () => {
		if (detail.type === 4) { // 寄售
			history.push('/sale/buydetail', { orderNo: detail.orderNo })
		} else {
			history.push('/order/buydetail', { orderNo: detail.orderNo })
		}
	}

	return (
		<div className="pay-status-container">
			{state.status === 'success' && (
				<>
					<img className="icon-pay-success" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-pay-success.png" />
					<div className="title">交易成功</div>
					<div className="subtitle">数字藏品正在发放中，稍后可在“藏品”中查</div>
				</>
			)}
			{state.status === 'pending' && (
				<>
					<img className="icon-pay-pending" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-pay-pending.png" />
					<div className="title">处理中</div>
					<div className="subtitle">您的支付订单正在处理中，若已确认支付成功，可稍后刷新订单状态查看，切勿重复支付</div>
				</>
			)}
			{state.status === 'fail' && (
				<>
					<img className="icon-pay-fail" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-pay-fail.png" />
					<div className="title">交易失败</div>
					<div className="subtitle">请重新尝试支付哦</div>
				</>
			)}
      <div className="button-super">
        <div className="button" onClick={handleGoDetail}>订单详情</div>
        <div className="button cancel" onClick={() => history.push('/home')}>返回首页</div>
      </div>
			<Mask visible={successVisible} style={{ backgroundColor: 'rgba(0, 0, 0, .9)' }}>
        <div className="compose-success-modal">
          <div className="top-tip">太棒啦～恭喜你获得</div>
          <div className="award-name">{detail.obtainCollectionName}</div>
          <div className="bottom-tip">数字藏品正在发放中，可稍后在“藏品”查看</div>
          <img className="award-img" src={detail.obtainCollectionPic} />
          <div className="continue-btn" onClick={() => history.replace(`/product?id=${detail.sourceId}&share=1`)}>继续购买盲盒</div>
          <img 
            className="icon-close" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/compose-result-close.png" 
            onClick={() => setSuccessVisible(false)}
          />
          <img className="star-mask" src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/manghe-star-mask-${detail.obtainCollectionLevel === 'UR' ? 'high' : 'low'}.png`} />
        </div>
      </Mask>
			<Mask visible={boxVisible} style={{ backgroundColor: 'rgba(0, 0, 0, .9)' }}>
        <div className="open-box-modal">
          <div className="top-tip">恭喜你获得<span>一个盲盒</span></div>
          <div className="bottom-tip">正在打开…</div>
          <img 
            className="animation" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-open-blindbox.gif" 
          />
        </div>
      </Mask>
		</div>
	)
}

export default PayStatus
