/*
 * @Description: 隐私协议
 * @Author: 杨志航
 * @Date: 2022-06-15 14:36:51
 */
import React, { useEffect } from 'react'
import Header from '@/components/Header'
import './index.scss'

function Secret ({ history }) {
  useEffect(() => {
    document.documentElement.scrollTop = 0
  }, [])

  return (
    <div className="agree-container">
      <Header title="隐私协议" />
      <img className="agree-main-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/agree-secret.png" />
      <div className="useage-link" onClick={() => history.push('/agree/useage')}></div>
      <div className="sdk-link" onClick={() => history.push('/agree/useage', { id: 'sdk' })}></div>
    </div>
  )
}

export default Secret
