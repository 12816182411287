/*
 * @Description: 
 * @Author: 刘晶雪
 * @Date: 2022-07-06 16:42:53
 */
import React from 'react'
import Header from '@/components/Header'
import './index.scss'

function AboutUs({ history }) {
  const { version } = global

  return (
    <div className="about-us-container">
      <Header title="关于我们" />
      <div className="about-us-container-content">
        <img className="about-us-container-icon-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/home-nft-logo.png" />
        <div className="about-us-container-name">无限数藏</div>
        <div className="about-us-container-version">版本号：V{version}</div>
      </div>
      <div className="about-us-container-footer">
        <div className="license-list">
          <div className="license-item" onClick={() => window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011202003539')}>
            <img className="police-logo" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/police-logo.png" />
            京公网安备 11011202003539号
          </div>
          <div className="license-item" onClick={() => window.open('https://beian.miit.gov.cn/')}>京ICP备2021041162号-2</div>
        </div>
        <div className="agree-super">
          <div className="agree-item" onClick={() => history.push('/agree/service')}>《用户服务协议》</div>
          <div onClick={() => history.push('/agree/secret')}>《隐私协议》</div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
