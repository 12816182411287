/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-09-15 15:24:44
 */
import React from 'react'
import Header from '@/components/Header'
import './index.scss'

function Baofu () {

  return (
    <div className="agree-baofu-container">
      <Header title="宝付协议" />
      <div className="agree-title">宝付网络科技（上海）有限公司-协议/认证支付服务协议</div>
      <img className="agree-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/agree-baofu.png" />
    </div>
  )
}

export default Baofu
