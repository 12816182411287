/*
 * @Description: 
 * @Author: 杨志航<yangzhihang@372163.com>
 * @Date: 2021-11-26 10:59:39
 */

export const regExt = {
  phone: /^1[3456789]\d{9}$/,
  mail: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
  account: /^([A-Za-z0-9\.\@\!\#\$\%\^\&\*\(\)]){6,50}$/, // 允许输入6-50位英文、数字、符号，英文不区分大小写
  identity: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
  bankNo: /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/,
  nickname: /^[\u4e00-\u9fa5_a-zA-Z0-9_]{2,12}/,
  positiveInt: /^[1-9]\d*$/,
}

export const pagination = {
  page: 1, // 当前页
  perPage: 20, // 每页数量
}

export const tabBarMenus = [
  { key: 'home', title: '首页' }, 
  { key: 'creator', title: '创作者' }, 
  { key: 'collection', title: '藏品' }, 
  { key: 'my', title: '我的' },
]

export const entranceMenus = [
  { key: 'compose', title: '合成' }, 
  { key: 'airdrop', title: '空投' }, 
]

export const productCategory = ['image', '3d', 'video', 'music', 'folder']

export const sellStatus = ['sell-fire', 'sell-time', 'sell-enpty', 'sell-enpty']

export const orderTypes = [
  { key: 'sell', title: '我卖出的' },
  { key: 'buy', title: '我买入的' },
]

export const sellTabs = [
  { key: 'all', title: '全部', status: 0, statusName: '' },
  { key: 'wait', title: '寄售中', status: 1, statusName: '寄售中' },
  { key: 'done', title: '已卖出', status: 4, statusName: '交易成功' },
]

export const buyTabs = [
  { key: 'all', title: '全部', status: 0, statusName: '' },
  { key: 'wait', title: '待付款', status: 1, statusName: '待付款' },
  { key: 'done', title: '已完成', status: 2, statusName: '交易成功' },
  { key: 'cancel', title: '已取消', status: 3, statusName: '交易关闭' },
]

export const payTypeNames = ['微信', '支付宝', 'iOS支付', '银行卡', '无需支付']

export const myMenus = [
  { key: 'order', title: '我的订单', link: '/order' },
  { key: 'wallet', title: '钱包', link: '/wallet' },
  { key: 'record', title: '藏品记录', link: '/my/record' },
  { key: 'blockchain', title: '区块链信息查询', link: '/hash' },
  { key: 'interest', title: '我的收藏', link: '/my/interest' },
  { key: 'foundry', title: '我的铸造卡', link: '/my/foundry' },
  { key: 'invite', title: '邀请好友', link: '/invite' },
  { key: 'setting', title: '设置', link: '/my/setting' },
  { key: 'aboutus', title: '关于', link: '/my/aboutus' },
]

export const bankNames = [
  { key: 'zhongguo', title: '中国银行' },
  { key: 'jiaotong', title: '交通银行' },
  { key: 'pufa', title: '浦发银行' },
  { key: 'huaxia', title: '华夏银行' },
  { key: 'minsheng', title: '民生银行' },
  { key: 'guangfa', title: '广发银行' },
  { key: 'xingye', title: '兴业银行' },
  { key: 'pingan', title: '平安银行' },
  { key: 'zheshang', title: '浙商银行' },
  { key: 'hengfeng', title: '恒丰银行' },
  { key: 'bohai', title: '渤海银行' },
  { key: 'shanghai', title: '上海银行' },
  { key: 'beijing', title: '北京银行' },
]

// 部分页面去掉下载app
export const noBtnRouters = [
  '/my/setting', 
  '/my/certification', 
  '/abandon', 
  '/password', 
  '/collection/show', 
]

export const defaultHomeTab = [{
  id: 0,
  name: '数字藏品',
}]

export const machinePics = {
  static: 'https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/compose-machine-static.png',
  dynamic: 'https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/compose-machine-dynamic.gif'
}
