/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-03-20 21:41:57
 */
import { Toast } from 'antd-mobile'
import copy from 'copy-to-clipboard'
import { bankNames } from '@/libs/constant'
/**
 * 回调节流
 *
 * @export
 * @param {*} action 回调
 * @param {*} delay 等待的时间
 * @param {*} context this指针
 * @param {Boolean} iselapsed 是否等待上一次
 * @returns {Function}
 */
export const throttle = (action, delay, context, iselapsed) => {
    let timeout = null;
    let lastRun = 0;
    return function () {
        if (timeout) {
            if (iselapsed) {
                return;
            } else {
                clearTimeout(timeout);
                timeout = null;
            }
            // return;
        }
        let elapsed = Date.now() - lastRun;
        let args = arguments;
        if (iselapsed && elapsed >= delay) {
            runCallback();
        } else {
            timeout = setTimeout(runCallback, delay);
        }
        /**
         * 执行回调
         */
        function runCallback() {
            lastRun = Date.now();
            timeout = false;
            action.apply(context, args);
        }
    };
}

// 格式化首页预售时间
export const formatHomeTime = time => time.slice(5).slice(0, -3)

// 隐藏手机号
export const dealMobile = str => {
    if (str) {
        return str.slice(0, 3) + '****' + str.slice(-4)
    } else {
        return ''
    }
}

// 隐藏哈希值
export const dealHash = str => {
  if (str) {
      return str.slice(0, 9) + '...' + str.slice(-9)
  } else {
      return ''
  }
}

/**
 * @description:  生成uuid
 * @param {*}     temp 生成的模版
 * @return {*}    uuid
 */
 export const generateUUID = (temp = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx') => {
    var d = new Date().getTime();
    var uuid = temp.replace(/[xy]/g, function(c) {
      var r = (d + Math.random()*16)%16 | 0;
      d = Math.floor(d/16);
      return (c=='x' ? r : (r&0x3|0x8)).toString(16);
    });
    return uuid;
  }

// 补0
export const repair = num => {
  return num < 10 ? '0' + num : num
}

// 复制文本
export const copyText = (text, msg) => {
  copy(text)
  Toast.show({
    icon: 'success',
    content: msg || '已复制',
  })
}

// 解析search查询字符串
export const parseSearch = path => {
    const search = path.slice(path.indexOf('?') + 1)
  
    var qs = (search.length > 0 ? search : ""),
      items = qs.length ? qs.split("&") : [],
      args = {},
      item = null,
      name = null,
      value = null;
  
    for (var i = 0, len = items.length; i < len; i++) {
      item = items[i].split("=")
      name = decodeURIComponent(item[0])
      value = decodeURIComponent(item[1])
      if (name.length) {
        args[name] = value
      }
    }
    return args
}

// 获取银行图片
export const getBankLogo = name => {
  const bank = bankNames.find(item => item.title.indexOf(name) > -1)
  if (bank) {
    return `https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-bank-${bank.key}.png`
  } else {
    return 'https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-product-sell-stop.png'
  }
}

// 获取当前时间YYYY-MM-DD hh:mm:ss
export const getCurrentTime = () => {
  var date = new Date();//当前时间
  var year = date.getFullYear() //返回指定日期的年份
  var month = repair(date.getMonth() + 1);//月
  var day = repair(date.getDate());//日
  var hour = repair(date.getHours());//时
  var minute = repair(date.getMinutes());//分
  var second = repair(date.getSeconds());//秒
  
  //当前时间 
  var curTime = year + "-" + month + "-" + day
          + " " + hour + ":" + minute + ":" + second;
  return curTime;
}

// 获取设备类型
export const getDeviceType = () => {
  const ua = navigator.userAgent
  const deviceType = ua.slice(ua.indexOf('(') + 1, ua.indexOf(')'))
  return deviceType
}

// 获取年月
export const getYearMonth = date => {
  const y = date.getFullYear()
  const m = date.getMonth()
  return y + '-' + repair(m + 1)
}
