/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-11-08 09:10:30
 */
import React, { useState, useEffect } from 'react'
import { Toast } from 'antd-mobile'
import Header from '@/components/Header'
import api from '@/api'
import './index.scss'

function Avatar({ history, location }) {
  const [mainPic, setMainPic] = useState('')

  useEffect(() => {
    setMainPic(location.state.pic)
  }, [])

  const setAvatar = () => {
    if (!mainPic) return
    api.setUpdateInfo({
      avatar: mainPic
    }).then(() => {
      Toast.show({
        icon: 'success',
        content: '设置成功',
        duration: 1000,
        afterClose: () => history.goBack()
      })
    })
  }

  return (
    <div className="create-avatar-container">
      <Header title="设置头像" bgColor="transprent" />
      <div className="avatar-super">
        {mainPic && <img className="pic" src={mainPic} />}
      </div>
      <div className="confirm-btn" onClick={setAvatar}>设置为头像</div>
    </div>
  )
}

export default Avatar
