/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-06-16 13:49:06
 */
import React from 'react'
import Header from '@/components/Header'
import './index.scss'

function Service ({ history }) {

  return (
    <div className="agree-container">
      <Header title="用户协议" />
      <img className="agree-main-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/agree-service.png" />
      <div className="secret-link" onClick={() => history.push('/agree/secret')}></div>
    </div>
  )
}

export default Service
