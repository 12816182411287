/*
 * @Description: 我的收藏
 * @Author: 杨志航
 * @Date: 2023-03-21 13:35:50
 */
import React, { useEffect, useState } from 'react'
import Header from '@/components/Header'
import { Input, InfiniteScroll, PullToRefresh } from 'antd-mobile'
import { pagination } from '@/libs/constant'
import api from '@/api'
import './index.scss'

function Interest({ history }) {
  const [value, setValue] = useState('')
  const [page, setPage] = useState(pagination.page)
  const [perPage] = useState(pagination.perPage)
  const [productList, setProductList] = useState([])
  const [hasMore, setHasMore] = useState(true)

  useEffect(() => {
    // 修改打开APP按钮位置
    const btnEle = document.getElementById('appDownloadBtn')
    btnEle.style.top = '.30rem'
    // 请求列表
    getList()
    return () => {
      btnEle.style.top = '.70rem'
    }
  }, [])

  const getList = async params => {
    const curPage = (params && params.page) || page
    const name = (params && params.name) || ''
    let obj = { page: curPage, perPage }
    if (name) {
      obj.name = name
    }
    const { list, total } = await api.getFavoriteList(obj)
    const totalPage = Math.ceil(total / perPage)

    if (curPage < totalPage) {
      setPage(curPage + 1)
      setHasMore(true)
    } else {
      setHasMore(false)
    }

    if (curPage === 1) {
      setProductList(list)
    } else {
      setProductList([...productList, ...list])
    }
  }

  const handleRefresh = () => {
    getList({ page: 1 })
    setValue('')
  }

  const handleInput = val => {
    const str = val.trim()
    setValue(str)
    if (str === '') {
      getList({ page: 1 })
    }
  }

  return (
    <div className="my-interest-container">
      <Header title="我的收藏" />
      <div className="search-wrapper">
        <div className="form-super">
          <img className="icon-search" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-search.png" />
          <Input 
            type="text" 
            placeholder='输入藏品名称' 
            clearable 
            value={value}
            onChange={handleInput}
          />
          <div className="search-btn" onClick={() => getList({ page: 1, name: value })}>搜索</div>
        </div>
      </div>
      <PullToRefresh onRefresh={handleRefresh}>
        {productList.length === 0 ? (
          <div className="enpty-super">
            <img className="enpty-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/welfare-enpty.png" />
            <div className="text">暂时没有收藏信息</div>
          </div>
        ) : (
          <>
            <div className="product-list">
              {productList.map((item, index) => (
                <div className="item" key={index} onClick={() => history.push(`/product?id=${item.id}`)}>
                  <div className="info-super">
                    <img className="main-img" src={item.mainPic} />
                    <div className="info">
                      <div className="name">{item.name}</div>
                      <div className="price">¥{item.price}</div>
                    </div>
                  </div>
                  <div className="count">在售数量：{item.onSaleNum}</div>
                </div>
              ))}
            </div>
            <InfiniteScroll loadMore={getList} hasMore={hasMore} />
          </>
        )}
      </PullToRefresh>
    </div>
  )
}

export default Interest
