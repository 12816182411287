/*
 * @Description: 公告详情
 * @Author: 朱燕
 * @Date: 2023-04-04 15:34:10
 */
import React, { useState, useEffect } from 'react'
import Header from '@/components/Header'
import { Mask } from 'antd-mobile'
import api from '@/api'
import './index.scss'

function Detail({ history, location }) {
  const token = localStorage.getItem('token')
  const { state } = location
  const [detail, setDetail] = useState({})
  const [cerModalVisible, setCerModalVisible] = useState(false)

  useEffect(() => {
    // 获取公告详情
    getDetail()

  }, [])

  const getDetail = () => {
    api.getAnnouncementDetail({ id: state.id }).then(res => {
      setDetail(res)
    })
  }

  const clickLook = (jumpType, jumpId) => {
    // 1-藏品详情 2-空投活动 3-合成活动 4-邀请好友
    switch (jumpType) {
      case 1:
        history.push(`/product?id=${jumpId}`)
        break
      case 2:
        // 是否登录
        if (token) {
          history.push(`/welfare/detail?id=${jumpId}&origin=${1}`)
        } else {
          history.push('/login')
        }
        break
      case 3:
        // 是否登录
        if (token) {
          history.push('/compose', { id: jumpId })
        } else {
          history.push('/login')
        }
        break
      case 4:
        // 是否登录
        if (token) {
          // 是否实名认证
          api.getUserInfo().then(res => {
            if (res && res.isCert === 2) {
              setCerModalVisible(true)
            } else {
              history.push('/invite')
            }
          })
        } else {
          history.push('/login')
        }
    }
  }

  return (
    <div className="announce-detail-container">
      {detail.typeName && <Header title={'【' + detail.typeName + '】' + detail.title} />}
      <div className="detail-time">
        <img className="detail-time-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/announce-time-icon.png" />
        <span className="detail-time-text">{detail.publishTime}</span>
      </div>
      <div className="section">
        <div className="section-content" dangerouslySetInnerHTML={{ __html: detail.content }}></div>
      </div>
      {
        detail.isJump === 1 &&
        <div className="submit-super">
          <div className="submit-btn" onClick={() => clickLook(detail.jumpType, detail.jumpId)}>去查看</div>
        </div>
      }
      <Mask
        visible={cerModalVisible}
        onMaskClick={() => setCerModalVisible(false)}
      >
        <div className="overlay-content">
          <div className="overlay-content-top">
            <div className="top-title">实名认证提示</div>
            <div className="top-content">请您先完成实名认证</div>
          </div>
          <div className="overlay-content-bottom">
            <div className="vertical-bar"></div>
            <div className="left" onClick={() => setCerModalVisible(false)}>稍后再说</div>
            <div className="right" onClick={() => history.push('/my/certification')}>去认证</div>
          </div>
        </div>
      </Mask>
    </div>
  )
}

export default Detail
