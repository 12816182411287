/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-08-08 11:34:06
 */
import React, { useState, useEffect, useRef } from 'react'
import Header from '@/components/Header'
import { Mask } from 'antd-mobile'
import LotteryMachine from '@/libs/lotteryMachine'
import { parseSearch } from '@/libs/util'
import api from '@/api'
import './index.scss'

let carouselTimer = null
let lotteryMachine = null

function Main({ history, location }) {
  const search = parseSearch(location.search)
  const [playing, setPlaying] = useState(false)
  const [isRolling, setIsRolling] = useState(false)
  const [tipVisible, setTipVisible] = useState(false)
  const [rewordVisible, setRewordVisible] = useState(false)
  const [detail, setDetail] = useState({})
  const [tipText, setTipText] = useState('')
  const [modalBg, setModalBg] = useState(1)
  const [rewordPic, setRewordPic] = useState('')
  const [cerVisible, setCerVisible] = useState(false)
  const [loginVisible, setLoginVisible] = useState(false)
  const [isCert, setIsCert] = useState(false)
  const awardRef = useRef(null)
  const textSuperRef = useRef(null)
  const textRef = useRef(null)

  useEffect(() => {
    //app登录token
    if (search.token) {
      localStorage.setItem('token', search.token)
    }

    const token = localStorage.getItem('token')

    if (token) {
      //需要实名认证
      api.getUserInfo({ timestamp: Date.now() }).then(data => {
        if (data && data.isCert === 1) {
          setIsCert(true)
        }
      })
      // 活动详情
      getDetail()
    } else {
      setLoginVisible(true)
    }

    // 创建摇蛋机
    lotteryMachine = new LotteryMachine()

    // 启动跑马灯
    const superWidth = textSuperRef.current.clientWidth
    const textWidth = textRef.current.clientWidth
    const distant = textWidth - superWidth + 30

    if (textWidth > superWidth) {
      carouselTimer = setInterval(() => {
        const left = Math.abs(textRef.current.offsetLeft)
        if (left < distant) {
          textRef.current.style.left = `-${left + 1}px`
        } else {
          textRef.current.style.left = 0
        }
      }, 50)
    }
    return () => {
      clearInterval(carouselTimer)
      lotteryMachine.distroy()
    }
  }, [])

  const getDetail = () => {
    api.getActivityDetail({ id: search.id || 1, timestamp: Date.now() }).then(res => {
      document.title = res.name
      const { effectStartTime, effectEndTime } = res
      const now = new Date()
      const startTime = new Date(effectStartTime.replace(/-/g, '/'))
      const endTime = new Date(effectEndTime.replace(/-/g, '/'))
      if (startTime > now) {
        res.status = 1
      } else if (now > endTime) {
        res.status = 3
      } else {
        res.status = 2
      }
      setDetail(res)
    })
  }
  
  const startAnimation = () => {
    if (playing) {
      return false;
    }
    setPlaying(true)
    setIsRolling(false)
    lotteryMachine.play()
  }
  
  const stopAnimation = () => {
    setPlaying(false)
    setIsRolling(true)
    lotteryMachine.stop()
  }

  const handleOne = () => {
    if (!isCert) {
      setCerVisible(true)
      return
    }
    if (detail.status === 1) {
      setTipText('本次抽奖活动暂未开始哦～')
      setModalBg(3)
      setTipVisible(true)
      return
    } else if (detail.status === 3) {
      setTipText('本次抽奖活动已结束下次早点来哦～')
      setModalBg(2)
      setTipVisible(true)
      return
    }
    if (detail.isLimitPartNum === 1 && detail.lastSurplusNum === 0) {
      setTipText('您已参与过该活动～')
      setModalBg(1)
      setTipVisible(true)
      return
    }
    startAnimation()
    setTimeout(() => {
      stopAnimation()
      api.activityPlay({ id: search.id }).then(res => {
        if (res) {
          if (res.award) {
            setModalBg(4)
            setRewordPic(res.award.mainPic)
            setRewordVisible(true)
          } else {
            if (res.inBlackList === 1) {
              setTipText('您暂时没有参与资格～')
              setModalBg(1)
              setTipVisible(true)
            } else {
              setModalBg(5)
              setRewordVisible(true)
            }
          }
        }
        // 显示提示后再更新详情
        setTimeout(() => {
          getDetail()
        }, 2000)
      })
    }, 2000)
  }

  return (
    <div className="lottery-container" style={{ paddingTop: !search.token ? '.44rem' : 0 }}>
      {!search.token && 
        <Header title={detail.name} onClick={() => history.push('/home')} />
      }
      <div className="lottery-super">
        <div className="lottery">
          <img className="cap" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/cap.png" />
          <div className="balls-wrapper"><canvas id="balls"></canvas></div>
          <img className="cover" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/cover.png" />
        </div>
        <div className="base">
          <img className="bg" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/base.png" />
          <img className={`award ${isRolling ? 'rolling' : ''}`} src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/ball-red.png" ref={awardRef} />
          <img className="bg mask" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/base.png" />
          <div className="one" onClick={handleOne}>
            <img className={`turn ${playing ? 'active' : ''}`} src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/turn.png" />
            <div className="btn">扭一次</div>
          </div>
        </div>
      </div>
      <div className="carousel-super" style={{ top: !search.token ? '.78rem' : '.34rem', display: 'none' }}>
        <img className="icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-carousel-message.png" />
        <div className="text-super" ref={textSuperRef}>
          <div 
            className="text" 
            ref={textRef}
          >点点欧气爆棚，扭到了开心小A；
          藏家_4288aa93欧气爆棚，扭到了疯狂小A；
          藏家_c2355398欧气爆棚，扭到了忙碌小A；
          藏家_91159c7e欧气爆棚，扭到了协作小A；
          藏家_784b064d欧气爆棚，扭到了元气小A；
          藏家_e86a6aa2欧气爆棚，扭到了开心小A；</div>
        </div>
      </div>
      <div className="button-super" style={{ top: !search.token ? '1.36rem' : '.92rem' }}>
        <img 
          className="btn" 
          src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/lottery-reword-btn.png" 
          onClick={() => history.push('/lottery/reword', { type: search.token ? 'app' : 'web' })}
        />
        <img 
          className="btn" 
          src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/lottery-rule-btn.png" 
          onClick={() => history.push('/lottery/rule', { type: search.token ? 'app' : 'web', id: search.id })}
        />
      </div>
      <div className="bottom-super">
        {detail.isShowSurplusNum === 1 && 
          <div className="play-number">— 剩余{detail.lastSurplusNum}次 —</div>
        }
        <div className="reword-box">
          <div className="list">
            {detail.actPrize && detail.actPrize.map((item, index) => (
              <div className="item" key={index}>
                <img className="pic" src={item.mainPic} />
                <div>
                  <div className="name">{item.name}</div>
                  <div className='num'>{item.releaseNum}份</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {detail.isShowPartNumber === 1 &&
          <div className="play-person">— {detail.partNumber}人已参与 —</div>
        }
      </div>
      <Mask visible={tipVisible} onMaskClick={() => setTipVisible(false)}>
        <div className="modal-tip-content" style={{ backgroundImage: `url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/lottery-mask-bg-${modalBg}.png)` }}>
          <div className="mask-title">{tipText}</div>
          <img 
            className="mask-img" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/lottery-mask-btn.png" 
            onClick={() => setTipVisible(false)}
          />
        </div>
      </Mask>
      <Mask visible={rewordVisible}>
        <div className="modal-reword-content" style={{ backgroundImage: `url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/lottery-mask-bg-${modalBg}.png)` }}>
          {modalBg === 4 &&
            <img className="mask-img" src={rewordPic} />
          }
          <div className="mask-btn" onClick={() => setRewordVisible(false)}></div>
        </div>
      </Mask>
      <Mask 
        visible={cerVisible} 
        onMaskClick={() => setCerVisible(false)} 
      >
        <div className="overlay-content">
          <div className="overlay-content-top">
            <div className="top-title">请先进行实名认证</div>
            <div className="top-content">为保障您的权益，避免交易版权纠纷，需要您先实名认证后才可以参加活动</div>
          </div>
          <div className="overlay-content-bottom">
            <div className="vertical-bar"></div>
            <div className="left" onClick={() => setCerVisible(false)}>稍后再说</div>
            <div className="right" onClick={() => history.push('/my/certification')}>去实名</div>
          </div>
        </div>
      </Mask>
      <Mask visible={loginVisible}>
        <div className="overlay-content">
          <div className="overlay-content-top">
            <div className="top-title">请先登录</div>
            <div className="top-content">需要您先登录后才可以参加活动</div>
          </div>
          <div className="overlay-content-bottom">
            <div className="right" onClick={() => history.push('/login')}>去登录</div>
          </div>
        </div>
      </Mask>
    </div>
  )
}

export default Main
