/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-09-14 16:42:38
 */
import React, { useState, useEffect } from 'react'
import Header from '@/components/Header'
import { InfiniteScroll, PullToRefresh } from 'antd-mobile'
import { pagination } from '@/libs/constant'
import api from '@/api'
import './index.scss'

function Record() {
  const [page, setPage] = useState(pagination.page)
  const [perPage] = useState(pagination.perPage)
  const [productList, setProductList] = useState([])
  const [hasMore, setHasMore] = useState(true)

  useEffect(() => {
    // 请求商品列表
    getList()
  }, [])

  const getList = async curPage => {
    curPage = curPage || page
    const { list, total } = await api.getInviteList({ page: curPage, perPage })
    const totalPage = Math.ceil(total / perPage)

    if (curPage < totalPage) {
      setPage(curPage + 1)
      setHasMore(true)
    } else {
      setHasMore(false)
    }

    if (curPage === 1) {
      setProductList(list)
    } else {
      setProductList([...productList, ...list])
    }
  }

  const handleRefresh = () => {
    // 更新商品列表
    getList(pagination.page)
  }

  return (
    <div className="invite-record-container">
      <Header title="邀请记录" />
      <div className="content">
        <PullToRefresh onRefresh={handleRefresh}>
          {productList.length === 0 ? (
            <div className="enpty-super">
              <img className="enpty-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/welfare-enpty.png" />
              <div className="text">您还没有邀请记录</div>
            </div>
          ) : (
            <>
              <div className="list-super">
                <div className="head-super">
                  <div>手机号</div>
                  <div>邀请时间</div>
                  <div>状态</div>
                </div>
                {productList.map((item, index) => (
                  <div className="item" key={index}>
                    <div>{item.mobile}</div>
                    <div>{item.addTime.slice(0, 10).replace('-', '年').replace('-', '月') + '日'}</div>
                    <div>{item.isCert === 1 ? '已实名' : '已注册'}</div>
                  </div>
                ))}
              </div>
              <InfiniteScroll loadMore={getList} hasMore={hasMore} />
            </>
          )} 
        </PullToRefresh>
      </div>
    </div>
  )
}

export default Record
