/*
 * @Description: 官方公告
 * @Author: 朱燕
 * @Date: 2023-04-04 13:20:43
 */
import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import Main from './Main'
import Detail from './Detail'

class Announce extends Component {
  render () {
    const { path } = this.props.match

    return (
      <div>
        <Switch>
          <Route exact path={path + '/detail'} component={Detail}/>
          <Route component={Main} />
        </Switch>
      </div>
    )
  }
}

export default withRouter(Announce)
