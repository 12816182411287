/*
 * @Description: 我的铸造卡
 * @Author: 刘晶雪
 * @Date: 2023-04-04 14:45:46
 */
import React, { useState, useEffect } from 'react'
import Header from '@/components/Header'
import { InfiniteScroll, PullToRefresh } from 'antd-mobile'
import { pagination } from '@/libs/constant'
import api from '@/api'
import './index.scss'

function Foundry() {
	const [page, setPage] = useState(pagination.page)
	const [perPage] = useState(pagination.perPage)
	const [cardList, setCardList] = useState([])
	const [hasMore, setHasMore] = useState(true)
	const [lastNum, setLastNum] = useState('')

	useEffect(() => {
		// 请求列表
		getList()
	}, [])

	const getList = async (params = {}) => {
		const curPage = params.page ?? page
		const { list, total, lastNum } = await api.getCastCardList({ page: curPage, perPage })
		const totalPage = Math.ceil(total / perPage)
		setLastNum(lastNum)
		if (curPage === 1) {
			setCardList(list)
		} else {
			setCardList([...cardList, ...list])
		}

		if (curPage < totalPage) {
			setPage(curPage + 1)
			setHasMore(true)
		} else {
			setHasMore(false)
		}
	}

	// 更新商品列表
	const handleRefresh = () => {
		getList({
			page: pagination.page
		})
	}

	return (
		<div className="foundry-container">
			<Header title="我的铸造卡" />
			<div className="foundry-top-super">
				<div className="foundry-banner-super">
					<div className="banner-label">剩余铸造次数</div>
					<div className="banner-value">
						<span className='banner-value-one'>{lastNum}</span>
						<span className='banner-value-two'> 次</span>
					</div>
				</div>
			</div>
			<div className="foundry-list-super">
				<div className="list-title">铸造卡变化明细</div>
				<div className="list-content">
					<PullToRefresh onRefresh={handleRefresh}>
						{cardList.length === 0 ? (
							<div className="enpty-super">
								<img className="enpty-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/welfare-enpty.png" />
								<div className="text">暂无数据</div>
							</div>
						) : (
							<>
								{cardList.map((item, index) => (
									<div className="list-item" key={index}>
										<div className="info-left">
											<div className="title">{item.remark}</div>
											<div className="time">{item.addTime}</div>
										</div>
										<div className={`info-right ${item.num > 0 ? 'red' : 'green'}`}>{item.num > 0 ? '+' : ''}{item.num}</div>
									</div>
								))}
								<InfiniteScroll loadMore={getList} hasMore={hasMore} />
							</>
						)}
					</PullToRefresh>
				</div>
			</div>
		</div>
	)
}

export default Foundry
