/*
 * @Description: 白色头部
 * @Author: 杨志航
 * @Date: 2023-02-17 14:22:13
 */
import React from 'react'
import { withRouter } from 'react-router-dom'
import './index.scss'

function HeaderWhite ({
  history,
  title =  '',
  bgColor = '#FBFBFB',
  onClick,
}) {
  
  const handleClick = () => {
    if (onClick) {
      onClick()
    } else {
      history.goBack()
    }
  }

  return (
    <div className="header-white-container" style={{ backgroundColor: bgColor }}>
      <div onClick={handleClick} className="icon-arrow"></div>
      <div className="title">{title}</div>
      <div className="blank"></div>
    </div>
  )
}

export default withRouter(HeaderWhite)
