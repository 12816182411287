/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-09-09 10:21:54
 */
import React, { useState, useEffect } from 'react'
import Header from '@/components/Header'
import { InfiniteScroll, PullToRefresh } from 'antd-mobile'
import { sellStatus, pagination } from '@/libs/constant'
import api from '@/api'
import './index.scss'

function Main({ history }) {
  const [page, setPage] = useState(pagination.page)
  const [perPage] = useState(pagination.perPage)
  const [productList, setProductList] = useState([])
  const [hasMore, setHasMore] = useState(true)

  useEffect(() => {
    // 请求商品列表
    getList()
  }, [])

  const getList = async curPage => {
    curPage = curPage || page
    const { list, total } = await api.getAirdropList({ page: curPage, perPage })
    const totalPage = Math.ceil(total / perPage)

    if (curPage < totalPage) {
      setPage(curPage + 1)
      setHasMore(true)
    } else {
      setHasMore(false)
    }

    if (curPage === 1) {
      setProductList(list)
    } else {
      setProductList([...productList, ...list])
    }
  }

  const handleRefresh = () => {
    // 更新商品列表
    getList(pagination.page)
  }

  const StatusView = ({ status }) => {
    let view = null
    switch (status) {
      case 1: 
        view = <>
          <img className="time-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-welfare-will.png" />
          未开始
        </>
        break
      case 2: 
        view = <>
          <img className="fire-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-welfare-fire.png" />
          进行中
        </>
        break
      case 3: 
        view = <>
          <img className="time-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-welfare-time.png" />
          已结束
        </>
        break
    }
    return view
  }

  return (
    <div className="welfare-container">
      <Header title="空投活动" onClick={() => history.push('/home')} />
      <div className="welfare-content">
        <PullToRefresh onRefresh={handleRefresh}>
          {productList.length === 0 ? (
            <div className="enpty-super">
              <img className="enpty-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/welfare-enpty.png" />
              <div className="text">暂无空投活动</div>
              <div className="link" onClick={() => history.push('/home')}>去逛逛</div>
            </div>
          ) : (
            <>
              <div className="product-list">
                {productList.map((item, index) => (
                  <div className="product-item" key={index} onClick={() => history.push(`/welfare/detail?id=${item.id}`)}>
                    <img className="main-img" src={item.pic} />
                    <div className="content-super">
                      <div className="title">{item.name}</div>
                      <div className="time">活动时间：{`${item.startTime.slice(0, 10)} ~ ${item.endTime.slice(0, 10)}`}</div>
                    </div>
                    <div className={`sell-status-super ${sellStatus[item.status - 1]}`}>
                      <StatusView status={item.status} />
                    </div>
                  </div>
                ))}
              </div>
              <InfiniteScroll loadMore={getList} hasMore={hasMore} />
            </>
          )} 
        </PullToRefresh>
      </div>
    </div>
  )
}

export default Main
