/*
 * @Description: 
 * @Author: 杨志航<yangzhihang@372163.com>
 * @Date: 2022-07-08 09:15:00
 */
import React, { useEffect, useState, useRef } from 'react'
import { Mask, Toast } from 'antd-mobile'
import { generateUUID } from '@/libs/util'
import api, { fetchOSSUpload } from '@/api'
import './index.scss'

function ImageUploader ({
  imageUrl,
  size = 200,
  onChange,
}) {
  const [src, setSrc] = useState('https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/default-avatar.png')
  const [visible, setVisible] = useState(false)
  const inputRef = useRef(null)
  const [OSSData, setOSSData] = useState({});

  useEffect(() => {
    //获取浏览器的userAgent,并转化为小写
    const ua = navigator.userAgent.toLowerCase() 
    //判断是否是苹果手机，是则是true
    const isIos = (ua.indexOf('iphone') != -1) || (ua.indexOf('ipad') != -1) 

    const isWeixin = ua.match(/MicroMessenger/i)=="micromessenger" //微信判断
    // const isQQInstalled = ua.indexOf(' qq')>-1 && ua.indexOf('mqqbrowser') < 0 //qq内置浏览器
    const isQQ = ua.indexOf('mqqbrowser')> -1 && ua.indexOf(" qq")< 0 //qq浏览器

    if (isQQ || isIos || isWeixin) inputRef.current.removeAttribute('capture')
  }, [])

  useEffect(() => {
    if (imageUrl) {
      setSrc(imageUrl)
    }
  }, [imageUrl])

  const changeImage = async () => {
    let file = inputRef.current.files[0]
    if (!file) return
    if (size) {
      const isLessSize = file.size / 1024 / 1024 < size;
      if (!isLessSize) {
        Toast.show({
          icon: 'fail',
          content: `文件大小必须小于 ${size}MB！`,
        })
        return
      }
    }
    let data = OSSData
    if (!data.expire || data.expire * 1000 < Date.now()) {
      data = await api.getOssToken()
      setOSSData(data)
    }
    const suffix = file.name.slice(file.name.lastIndexOf('.'))
    const filename = generateUUID() + suffix
    const parmas ={
      name: file.name,
      key: data.dir + filename,
      OSSAccessKeyId: data.accessid,
      policy: data.policy,
      Signature: data.signature,
      callback: data.callback,
      success_action_status: '200',
      file,
    }
    setVisible(true)
    fetchOSSUpload(data.host, parmas).then(res => {
      const url = data.host+ '/' + data.dir + filename;
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = evt => {
        setSrc(evt.target.result)
        setVisible(false)
      }
      if (onChange) {
        onChange(url)
      }
    }).catch(err => {
      setVisible(false)
    })
  }

  return (
    <div className="upload-block">
      <img className="image" src={src} />
      <input 
        className="input" 
        ref={inputRef} 
        type="file" 
        onChange={changeImage} 
        accept="image/*"
        capture="camera"
        name="file"
      />
      <Mask visible={visible} />
    </div>
  )
}

export default ImageUploader
