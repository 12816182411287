/*
 * @Description: 发布作品描述、介绍
 * @Author: 朱燕
 * @Date: 2023-04-03 11:12:25
 */
import React, { useState, useEffect } from 'react'
import { TextArea, Mask, Toast } from 'antd-mobile'
import Header from '@/components/Header'
import api from '@/api'
import './index.scss'

function Describe({ history, location }) {
  const { state } = location
  const [value, setValue] = useState('')
  const [hintVisible, setHintVisible] = useState(false)
  const [hintText, setHintText] = useState('')
  const [wordVisible, setWordVisible] = useState(false)
  const [wordText, setWordText] = useState('')
  const [title, setTitle] = useState('')

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('productData'))
    setValue(state.type === 1 ? data.desc : data.creatorDesc)
    setTitle(state.type === 1 ? '作品描述' : '创作者介绍')
  }, [])

  const handleSubmit = () => {
    if (value === '') {
      Toast.show({
        icon: 'fail',
        content: `请填写${title}`
      })
      return
    }
    api.getTestKeyWords({ str: value }).then(res => {
      const { contact, keyWords } = res
      if (contact.length > 0) {
        setHintText(contact.join(','))
        setHintVisible(true)
        return
      }
      if (keyWords.length > 0) {
        setWordText(keyWords.join(','))
        setWordVisible(true)
        return
      }
      const data = JSON.parse(localStorage.getItem('productData'))
      if (state.type === 1) {
        data.desc = value
      } else if (state.type === 2) {
        data.creatorDesc = value
      }
      localStorage.setItem('productData', JSON.stringify(data))
		  history.goBack()
    })
  }

  return (
    <div className="creator-describe-container">
      <Header title={title} />
      <div className="item">
        <div className="label-super">
          <span className="require-star">*</span>{title}
        </div>
        <TextArea
          value={value}
          rows={14}
          maxLength={500}
          showCount
          onChange={val => setValue(val)}
          placeholder="温馨提示：请勿在此预留微信、邮箱、手机等一切站外联络方式，否则会影响审核通过成功率，情节严重的，直接封号处理"
        />
      </div>
      <div className="submit-super">
        <div className="submit-btn" onClick={handleSubmit}>提交</div>
      </div>

      {/* 包含联系方式信息 */}
      <Mask visible={hintVisible} onMaskClick={() => setHintVisible(false)}>
        <div className="overlay-content">
          <div className="overlay-content-top">
            <div className="top-title">内容不合规提示</div>
            <div className="top-content">您的作品描述包含联系方式信息：<br /><span className="describe-mask-error">【{hintText}】</span> ，请修改后再提交</div>
          </div>
          <div className="overlay-content-bottom">
            <div className="vertical-bar"></div>
            <div className="left" onClick={() => setHintVisible(false)}>再看看</div>
            <div className="right" onClick={() => setHintVisible(false)}>立即修改</div>
          </div>
        </div>
      </Mask>

      {/* 包含敏感词汇 */}
      <Mask visible={wordVisible} onMaskClick={() => setWordVisible(false)}>
        <div className="overlay-content">
          <div className="overlay-content-top">
            <div className="top-title">内容不合规提示</div>
            <div className="top-content">您的作品描述包含敏感词汇：<br /><span className="describe-mask-error">【{wordText}】</span> ，请修改后再提交</div>
          </div>
          <div className="overlay-content-bottom">
            <div className="vertical-bar"></div>
            <div className="left" onClick={() => setWordVisible(false)}>再看看</div>
            <div className="right" onClick={() => setWordVisible(false)}>立即修改</div>
          </div>
        </div>
      </Mask>
    </div>
  )
}

export default Describe
