/*
 * @Description: 合约地址
 * @Author: 杨志航
 * @Date: 2022-11-07 16:21:30
 */
import React, { useState, useEffect } from 'react'
import { copyText } from '@/libs/util'
import Header from '@/components/Header'
import './index.scss'

function Collection({ location }) {
	const [detail, setDetail] = useState({})
	const [collectionVisible, setCollectionVisible] = useState(true)

	useEffect(() => {
		setDetail(location.state.data)
	}, [])

	return (
		<div className="hash-collection-container">
      <Header title="区块链信息查询" />
			<div className="blockchain-detail">
				<div className="detail-content">
					<img className="top-bg" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/certificate-top-bg.png" />
					<img className="content-bg" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/certificate-content-bg.png" />
					<img className="bottom-bg" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/certificate-bottom-bg.png" />
					<div className="detail-content-title">数文链 / 无限数藏</div>
					<img className="detail-content-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/blockchain-tit-top.png" />
					<div className="detail-content-subhead">区块链查证信息概览</div>
					<img className="detail-content-img detail-content-img-bottom" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/blockchain-tit-top.png" />
					<div className={`detail-title ${collectionVisible ? '' : 'down'}`} onClick={() => setCollectionVisible(!collectionVisible)}>
						藏品信息
						<img className="icon-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/certificate-title-arrow.png" />
					</div>
					{collectionVisible && (
						<div className="info-list">
							<div className="item">
								<div className="label">藏品名称</div>
								<div className="value">{detail.name}</div>
							</div>
							<div className="item">
								<div className="label">合约地址</div>
								<div className="value">
									{detail.blockHash}
									<img 
										onClick={() => copyText(detail.blockHash)} 
										className="icon-copy" 
										src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-hash-copy.png" 
									/>
								</div>
							</div>
							<div className="item">
								<div className="label">发行时间</div>
								<div className="value">{detail.issuesTime}</div>
							</div>
							<div className="item">
								<div className="label">创作者</div>
								<div className="value">{detail.creatorName}</div>
							</div>
							<div className="item">
								<div className="label">发行方</div>
								<div className="value">{detail.issuerName}</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default Collection
