/*
 * @Description: 我买入的-订单详情
 * @Author: 杨志航
 * @Date: 2022-07-14 09:38:13
 */
import React, { useState, useEffect } from 'react'
import { Mask, Input } from 'antd-mobile'
import { CountDown } from 'react-vant'
import { buyTabs, payTypeNames } from '@/libs/constant'
import { repair, copyText, getBankLogo } from '@/libs/util'
import Header from '@/components/Header'
import api from '@/api'
import './index.scss'

let countTimer = null

function BuyDetail({ history, location }) {
	const { state } = location
	const [detail, setDetail] = useState({})
  const [modalVisible, setModalVisible] = useState(false)
  const [cardVisible, setCardVisible] = useState(false)
  const [codeVisible, setCodeVisible] = useState(false)
  const [cardList, setCardList] = useState([])
  const [activeIndex, setActiveIndex] = useState(null)
  const [orderNo, setOrderNo] = useState('')
  const [uniqueCode, setUniqueCode] = useState('')
  const [smsCode, setSmsCode] = useState('')
  const [confirmEnable, setConfirmEnble] = useState(false)
  const [initialCount] = useState(60)
  const [count, setCount] = useState(initialCount)
  const [sending, setSending] = useState(false)
  const { canPayment } = global

	useEffect(() => {
    // 设置订单号
    setOrderNo(state.orderNo)
    // 获取订单详情
		api.getOrderInfo({ orderNo: state.orderNo }).then(res => {
			setDetail(res)
      // 获取银行卡列表
      if (canPayment && res.status === 1) {
        api.getBankCardList({ perPage: 1000 }).then(res => {
          const { total, list } = res
          setCardList(list)
          if (total > 0) {
            setActiveIndex(0)
          }
        })
      }
		})
    return () => {
      // 页面关闭清除countdown
      clearInterval(countTimer)
    }
	}, [])

  useEffect(() => {
    if (count >= 0 && sending) {
      countTimer = setTimeout(() => {
        setCount(count - 1)
      }, 1000)
    } else {
      setCount(initialCount)
      setSending(false)
    }
  }, [count, sending])

  useEffect(() => {
		if (smsCode.length === 6) {
      setConfirmEnble(true)
    } else {
      setConfirmEnble(false)
    }
	}, [smsCode])

  const getDetail = () => {
    api.getOrderInfo({ orderNo: state.orderNo }).then(res => {
			setDetail(res)
		})
  }

  const handleCancel = () => {
    api.orderCancel({ orderNo }).then(() => {
      setModalVisible(false)
      getDetail()
		})
  }

  const handleSubmit = () => {
    if (canPayment) {
      // 没有绑定银行卡
      if (activeIndex === null) {
        Toast.show({
          icon: 'fail',
          content: '请选择绑定的银行卡',
        })
        return
      }
      api.orderPay({ orderNo, payType: 4, cardId: cardList[activeIndex].id }).then(res => {
        setUniqueCode(res.uniqueCode)
        setCodeVisible(true)
        setSending(true)
        setSmsCode('')
      })
    } else {
      window.open('https://nft.372163.com/h5/share')
    }
	}

  const codeModalClose = () => {
    setCodeVisible(false)
    clearInterval(countTimer)
  }

  const handleConfirm = () => {
    if (!confirmEnable) return
    api.orderConfirm({ uniqueCode, smsCode }).then(() => {
      setUniqueCode('')
      setSmsCode('')
      codeModalClose()
      history.push('/payment/paystatus', { orderNo, status: 'success' })
    }).catch(err => {
      if (err.code === 120003) {
        history.push('/payment/paystatus', { orderNo, status: 'pending' })
      } else {
        history.push('/payment/paystatus', { orderNo, status: 'fail' })
      }
    })
  }

  const handleSendCode = () => {
    if (sending) return
    api.orderPay({ orderNo, payType: 4, cardId: cardList[activeIndex].id }).then(res => {
      setUniqueCode(res.uniqueCode)
      setSending(true)
      setSmsCode('')
    })
  }

  const handleTimeout = () => {
    codeModalClose()
    setCardVisible(false)
    setModalVisible(false)
    getDetail()
  }

	return (
    <div className="order-detail-container">
      <Header title="订单详情" />
      <div className="detail-tip">
        {detail.status === 1 ? (
          <>
            <div className="count-down">
              <div className="label">支付剩余时间</div>
              <CountDown 
                time={detail.lastPaySeconds * 1000} 
                format="HH:mm:ss"
                onFinish={handleTimeout}
              >
                {(timeData) => (
                  <>
                    <span className="block">{repair(timeData.hours)}</span>
                    <span className="colon">:</span>
                    <span className="block">{repair(timeData.minutes)}</span>
                    <span className="colon">:</span>
                    <span className="block">{repair(timeData.seconds)}</span>
                  </>
                )}
              </CountDown>
            </div>
            <div className="name wait">{detail.status && buyTabs[detail.status].statusName}</div>
          </>
        ) : (
          <div className="name">{detail.status && buyTabs[detail.status].statusName}！</div>
        )}
        {detail.status === 3 && detail.closeReason !== '' &&
          <div className="reason">关闭原因：{detail.closeReason}</div>
        }
      </div>
      <div className="section">
        {detail.status === 1 && (
          <div className="title-top">
            <img className="title-top-icon" src={detail.creatorLogo} />
            <div>{detail.creatorName}</div>
          </div>
        )}
        <div className="title-bottom">
          <img className="title-bottom-icon" src={detail.productPic} />
          <div className="introduce">
            {detail.status === 1 && (
              <>
                <div className="pay-title">{detail.productName}</div>
                <div className="quantity">x {detail.amount}</div>
                <div className="price">￥ {detail.price}</div>
              </>
            )}
            {detail.status === 2 && (
              <>
                <div>
                  <div className="title">{detail.productName}</div>
                  {detail.type === 3 ? (
                    <div className="hash">开出藏品：{detail.obtainCollectionName}</div>
                  ) : (
                    <div className="hash">{detail.blockHash || '编码生成中…'}</div>
                  )}
                </div>
                <div className="name">{detail.creatorName}</div>
              </>
            )}
            {detail.status === 3 && (
              <>
                <div className="title">{detail.productName}</div>
                <div className="name">{detail.creatorName}</div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="section">
        <div className="item">
          <div className="label">订单类型</div>
          <div className="value">{detail.orderType}</div>
        </div>
        <div className="item">
          <div className="label">订单金额</div>
          <div className="value">¥ {detail.price}</div>
        </div>
        <div className="item">
          <div className="label">交易数量</div>
          <div className="value">{detail.amount}</div>
        </div>
        <div className="item">
          <div className="label">创建时间</div>
          <div className="value">{detail.addTime}</div>
        </div>
        {detail.status === 3 && (
          <div className="item">
            <div className="label">取消时间</div>
            <div className="value">{detail.closeTime}</div>
          </div>
        )}
        {detail.status === 2 && (
          <div className="item">
            <div className="label">付款时间</div>
            <div className="value">{detail.payType === 5 ? '-' : detail.payTime}</div>
          </div>
        )}
        <div className="item">
          <div className="label">订单编号</div>
          <div className="hash-super">
            <div className="hash">{detail.orderNo}</div>
            <img 
              className="name-qukuailian" 
              src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/qukuailiandizhi.png" 
              onClick={() => copyText(detail.orderNo)}
            />
          </div>
        </div>
        {detail.status === 2 && (
          <>
            <div className="item">
              <div className="label">支付方式</div>
              <div className="value">{payTypeNames[detail.payType - 1]}</div>
            </div>
            <div className="item">
              <div className="label">支付流水号</div>
              <div className="value">{detail.payNo || '-'}</div>
            </div>
          </>
        )}
      </div>
      {detail.status === 1 && canPayment && (
        <div className="section">
          <div className="mode-payment-title">支付方式</div>
          <div className="mode-payment-content">
            <img className="mode-payment-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-paytype-bank.png" />
            <div>银行卡支付</div>
          </div>
          {cardList.length === 0 ? (
            <div className="add-bankcard" onClick={() => history.push('/payment/bindcard')}>
              添加银行卡
              <img className="arrow-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-right-arrow.png" />
            </div>
          ) : (
            <div className="bankcard-super">
              <div className="block">
                <img className="bank-icon" src={cardList.length > 0 && activeIndex !== null ? getBankLogo(cardList[activeIndex].bankName) : ''} />
                {cardList.length > 0 && activeIndex !== null && `${cardList[activeIndex].bankName}（${cardList[activeIndex].cardNo}）`}
              </div>
              <div className="block" onClick={() => setCardVisible(true)}>
                更换
                <img className="arrow-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-right-arrow.png" />
              </div>
            </div>
          )}
        </div>
      )}
      {detail.status === 1 && (
        <div className="footer">
          <div className="button cancel" onClick={() => setModalVisible(true)}>取消订单</div>
          <div className="button" onClick={handleSubmit}>{canPayment ? '立即购买' : '下载APP购买'}</div>
        </div>
      )}
      <Mask 
        visible={modalVisible} 
        onMaskClick={() => setModalVisible(false)} 
      >
        <div className="overlay-content">
          <div className="overlay-content-center">
            <div className="top-title">确定取消订单吗？</div>
          </div>
          <div className="overlay-content-bottom">
            <div className="vertical-bar"></div>
            <div className="left" onClick={() => setModalVisible(false)}>再想想</div>
            <div className="right" onClick={handleCancel}>确定取消</div>
          </div>
        </div>
      </Mask>
      <Mask
        visible={cardVisible} 
        onMaskClick={() => setCardVisible(false)} 
      >
        <div className="card-selector">
          <img 
            className="icon-close" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-close.png" 
            onClick={() => setCardVisible(false)}
          />
          <div className="title">
            选择银行卡
          </div>
          <div className="list-super">
            {cardList.map((item, index) => (
              <div className="item" key={index} onClick={() => setActiveIndex(index)}>
                <div className="card-info">
                  <img className="bank-icon" src={getBankLogo(item.bankName)} />
                  {`${item.bankName}（${item.cardNo}）`}
                </div>
                {activeIndex === index && 
                  <img className="choose-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-bankcard-choose.png" />
                }
              </div>
            ))}
          </div>
          <div className="add-super" onClick={() => history.push('/payment/bindcard')}>
            <img className="add-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-bankcard-add.png" />
            添加银行卡
          </div>
        </div>
      </Mask>
      <Mask
        visible={codeVisible} 
        onMaskClick={codeModalClose} 
      >
        <div className="code-super">
          <img 
            className="icon-close" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/login-icon-close.png" 
            onClick={codeModalClose}
          />
          <div className="title">支付验证</div>
          <div className="content">
            <div className="phone">{cardList.length > 0 && activeIndex !== null && cardList[activeIndex].mobile}</div>
            <Input 
              type="tel"
              placeholder='请输入验证码' 
              clearable 
              maxLength={6}
              value={smsCode}
              onChange={val => setSmsCode(val.trim())}
            />
          </div>
          <div 
            className={`countdown-btn ${sending ? 'disable' : ''}`}
            onClick={handleSendCode}
          >{sending ? `${count}s后重试` : '获取验证码'}</div>
          <div 
            className={`confirm-btn ${confirmEnable ? '' : 'disable'}`}
            onClick={handleConfirm}
          >确定</div>
        </div>
      </Mask>
    </div>
	)
}

export default BuyDetail
