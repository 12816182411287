/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-07-12 11:32:46
 */
import React, { useRef, useState, useEffect, Suspense } from 'react'
import GlbModel from '@/components/GlbModel'
import FbxModel from '@/components/FbxModel'
import Loader from '@/components/Loader'
import { OrbitControls } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import { ResizeObserver } from "@juggle/resize-observer"
import { Mask } from 'antd-mobile'
import api from '@/api'
import './index.scss'

function Show({ history, location }) {
  const { state } = location
  const [detail, setDetail] = useState({})
  const [suffix, setSuffix] = useState('')
  const [scale , setScale] = useState(1)
  const [showModal, setShowModal] = useState(false)
  const mediaRef = useRef(null)
  const controls = useRef(null)

  useEffect(() => {
    // 已购买可以通过产品id进入
    if (state.isProduct) {
      api.getProductOne({ id: state.id }).then(res => {
        setDetail(res.data)
        setSuffix(res.data.modelFile.slice(-3))
      })
    } else {
      api.getCollectionOne({ id: state.id }).then(res => {
        const { productName, productCreatorName, productModelFile, productCategoryId, productVideo, productAudio, productEnvLighting } = res.data
        setDetail({
          name: productName,
          creatorName: productCreatorName,
          modelFile: productModelFile,
          categoryId: productCategoryId,
          video: productVideo,
          audio: productAudio,
          envLighting: productEnvLighting,
        })
        setSuffix(productModelFile.slice(-3))
      })
    }
  }, [])

  
  const handleClick = (opt) => {
    if (opt === 'plus') {
      setScale(scale + 0.1)
    } else if (opt === 'minus' && scale > 0.2) {
      setScale(scale - 0.1)
    } else if (opt === 'reset') {
      setScale(1)
      controls.current.reset()
    }
  }

  const handlePlay = () => {
    setShowModal(true)
    mediaRef.current.play()
  }

  const handleClose = () => {
    setShowModal(false)
    mediaRef.current.pause()
    mediaRef.current.currentTime = 0
  }

  return (
    <div className="show-container">
      <div className="header-super">
        <div className="content">
          <div className="name">{detail.name}</div>
          <div className="creator">{detail.creatorName}</div>
        </div>
        <img 
          className="icon-close" 
          src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/login-icon-close.png" 
          onClick={() => history.goBack()}
        />
      </div>
      <Canvas 
        resize={{ polyfill: ResizeObserver }} 
        className="product-canvas"
        dpr={Math.max(window.devicePixelRatio, 2)}
      >
        <Suspense fallback={<Loader/>}>
          {/*点光源*/}
          <pointLight position={[10, 10, 10]} color="#C4C4C4" />
          <pointLight position={[-10, -10, -10]} color="#C4C4C4" />
          {/*环境光源 调节点光源*/}
          <ambientLight intensity={1} color={detail.envLighting || '#C4C4C4'} />
          {/*聚光灯 设置阴影*/}
          {/* <spotLight position={[-10, -10, -10]} intensity={.5}/> */}
          {/*平行光 设置阴影*/}
          {/* <directionalLight position={[0, 10, 0]} intensity={.5}/> */}
          <OrbitControls enablePan={false} ref={controls} />
          {suffix === 'fbx' &&  
            <FbxModel 
              controls={controls}
              scale={scale}
              url={detail.modelFile}
            />
          }
          {suffix === 'glb' &&
            <GlbModel 
              controls={controls}
              scale={scale}
              url={detail.modelFile}
            />
          }
        </Suspense>
      </Canvas>
      <img className="product-bottom" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/product-bottom.png" />
      <div className="btn-group">
        <img 
          className="btn" 
          src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-show-minus.png"
          onClick={() => handleClick('minus')}
        />
        <img 
          className="btn" 
          src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-show-plus.png"
          onClick={() => handleClick('plus')}
        />
        <img 
          className="btn" 
          src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-show-reset.png"
          onClick={() => handleClick('reset')}
        />
        {(detail.categoryId === 3 || detail.categoryId === 4) && 
          <img 
            className="btn play" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-show-play.png"
            onClick={handlePlay}
          />
        }
      </div>
      <Mask 
        className="media-modal-super"
        visible={showModal} 
        onMaskClick={handleClose}
        style={{ '--z-index': 1000 }}
        forceRender
      >
        {detail.categoryId === 3 &&
          <video 
            className="media-player"
            src={detail.video}
            loop
            ref={mediaRef}
            controls
            controlsList="nodownload"
          >
            您的浏览器不支持 video 标签。
          </video>
        }
        {detail.categoryId === 4 &&
          <audio 
            className="media-player"
            src={detail.audio}
            loop
            ref={mediaRef}
            controls
            controlsList="nodownload"
          >
            您的浏览器不支持 audio 标签。
          </audio>
        }
      </Mask>
    </div>
  );
}

export default Show;
