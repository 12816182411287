/*
 * @Description: 设置
 * @Author: 杨志航
 * @Date: 2022-07-05 11:32:53
 */
import React, { useState, useEffect } from 'react'
import { regExt } from '@/libs/constant'
import { Mask, Toast } from 'antd-mobile'
import { dealMobile } from '@/libs/util'
import ImageUploader from '@/components/ImageUploader'
import Header from '@/components/Header'
import api from '@/api'
import './index.scss'

function Setting({ history }) {
  const [detail, setDetail] = useState({})
  const [nicknameVisible, setNicknameVisible] = useState(false)
  const [logoutVisible, setLogoutVisible] = useState(false)
  const [nickname, setNickname] = useState('')

  useEffect(() => {
    getUserInfo()
  }, [])

  const getUserInfo = () => {
    api.getUserInfo().then(res => {
      setDetail(res)
    }).catch(() => {
      history.goBack()
    })
  }
  
  //点击更换昵称按钮
  const onAdd = () => {
    if (!regExt.nickname.test(nickname)) {
      Toast.show({
        icon: 'fail',
        content: '请输入2-12位中英文、数字',
      })
      return
    }
    api.setUpdateInfo({
      nickname: nickname
    }).then(res => {
      setNickname('')
      getUserInfo()
      setNicknameVisible(false)
      Toast.show({
        icon: 'success',
        content: '修改成功',
      })
    })
  }

  // 退出登录
  const handleLogout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('mobile')
    history.goBack()
  }

  // 修改头像
  const handleAvatar = url => {
    api.setUpdateInfo({
      avatar: url
    }).then(() => {
      Toast.show({
        icon: 'success',
        content: '修改成功',
      })
    })
  }

  return (
    <div className="setting-container">
      <Header title="设置" onClick={() => history.push('/my')} />
      <div className="setting-container-list">
        <div className="list-left">头像</div>
        <div className="list-right">
          <ImageUploader imageUrl={detail.avatar} onChange={handleAvatar} />
          <img className="right-icon-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-right-arrow.png" />
        </div>
      </div>
      <div className="setting-container-list">
        <div className="list-left">昵称</div>
        <div className="list-right" onClick={() => setNicknameVisible(true)}>
          <div className="left-text">{detail.nickname}</div>
          <img className="right-icon-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-right-arrow.png" />
        </div>
      </div>
      <div className="setting-container-list">
        <div className="list-left">手机号</div>
        <div className="list-right">
          <div className="left-text">{dealMobile(localStorage.getItem('mobile'))}</div>
        </div>
      </div>
      <div className="setting-container-list">
        <div className="list-left">实名认证</div>
        <div className="list-right">
          {
            detail.isCert !== 1 ? <div 
              className="left-text"
              onClick={() => history.push('/my/certification')}
            >
              <img className="dot-icon-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-red-dot.png" />未认证
            </div> :
            <div className="left-text">{detail.idNum}</div>
          }
          {detail.isCert !== 1 && <img className="right-icon-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-right-arrow.png" />}
        </div>
      </div>
      <div className="setting-container-list">
        <div className="list-left">操作密码</div>
        <div className="list-right">
          <div 
            className="left-text"
            onClick={() => history.push('/password', { hasOptPassword: detail.hasOptPassword })}
          >
            {detail.hasOptPassword === 2 && <img className="dot-icon-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-red-dot.png" />}
            {detail.hasOptPassword === 1 ? '已设置': '未设置'}
          </div>
          <img className="right-icon-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-right-arrow.png" />
        </div>
      </div>
      <div className="setting-container-list">
        <div className="list-left">账号注销</div>
        <div className="list-right" onClick={() => history.push('/abandon')}>
          <div className="left-text">注销后无法恢复</div>
          <img className="right-icon-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-right-arrow.png" />
        </div>
      </div>
      <div onClick={() => setLogoutVisible(true)} className="setting-container-log">
        <img className="log-out" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/log-out.png" />
      </div>
      <Mask 
        visible={nicknameVisible} 
        onMaskClick={() => setNicknameVisible(false)} 
      >
        <div className="overlay-content">
          <div className="overlay-content-top">
            <div className="top-title">修改昵称</div>
            <div className="top-content">原昵称：{detail.nickname}</div>
            <input 
              className="top-add" 
              placeholder='支持2-12位中英文、数字' 
              value={nickname} 
              onChange={evt => setNickname(evt.target.value.trim())}
              maxLength={12}
            />
          </div>
          <div className="overlay-content-bottom">
            <div className="vertical-bar"></div>
            <div className="left" onClick={() => setNicknameVisible(false)}>取消</div>
            <div className="right" onClick={onAdd}>确认</div>
          </div>
        </div>
      </Mask>
      <Mask 
        visible={logoutVisible} 
        onMaskClick={() => setLogoutVisible(false)} 
      >
        <div className="overlay-content">
          <div className="overlay-content-center">
            <div className="top-title">确认退出登录吗？</div>
          </div>
          <div className="overlay-content-bottom">
            <div className="vertical-bar"></div>
            <div className="left" onClick={() => setLogoutVisible(false)}>取消</div>
            <div className="right" onClick={handleLogout}>退出登录</div>
          </div>
        </div>
      </Mask>
    </div>
  )
}

export default Setting
