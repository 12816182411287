/*
 * @Description: 
 * @Author: 杨志航<yangzhihang@372163.com>
 * @Date: 2021-11-23 08:26:18
 */
import React from "react"
import ReactDOM from "react-dom"
import { Router } from 'react-router-dom'
import history from '@/libs/history'
import App from './App'
import "./styles.scss"
import './libs/global'

ReactDOM.render(
  <Router history={history}>
		<App/>
	</Router>,
  document.getElementById('app')
);

// const container = document.getElementById('app');
// const root = createRoot(container);
// root.render(<App/>);
