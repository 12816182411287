/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-10-24 16:00:12
 */
import React, { useState, useEffect, useRef } from 'react'
import Header from '@/components/Header'
import './index.scss'

function Set({ history, location }) {
  const [value, setValue] = useState('')
  const inputRef = useRef(null)

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  const handleCode = evt => {
    const val = evt.target.value
    setValue(val)
    if (val.length === 6) {
      history.push('/password/reset', { password: val, ...location.state })
    }
  }

  const handleClick = () => {
    inputRef.current.focus()
    inputRef.current.setSelectionRange(100, 100)
  }

  return (
    <div className="password-set-container">
      <Header title="设置密码" />
      <div className="set-title">输入新操作密码</div>
      <div className="content" onClick={handleClick}>
        <input 
          className="password-value-input"
          type="tel" 
          ref={inputRef}
          maxLength={6}
          value={value}
          onChange={handleCode}
        />
        <div className="box-list">
          {new Array(6).fill('').map((item, index) => (
            <div className="box-item" key={index}>
              <div className={`dot ${value.length < index + 1 ? 'hide' : ''}`}></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Set
