/*
 * @Description: 
 * @Author: 杨志航<yangzhihang@372163.com>
 * @Date: 2021-11-23 17:30:52
 */
/**
 * 接口文件
 * url的第一个反斜杠/一定要加，否则请求会带上相对路径
 */

 const prefix = '/api'
 const urls   = {
  // banner列表
  getBannerList: {
    url: '/banner/get-list',
    method: 'get'
  },
  // 商品列表
  getProductList: {
    url: '/product/get-list',
    method: 'get'
  },
  // 某频道下的列表
  getChannelProductList: {
    url: '/channel/list',
    method: 'get'
  },
  // 某频道下的列表
  getTopic: {
    url: '/channel/topic',
    method: 'get'
  },
  // 藏品列表
  getCollectionList: {
    url: '/collection/get-list',
    method: 'get'
  },
  // 创作作品列表
  getMyCreateList: {
    url: '/product/my-create-list',
    method: 'get'
  },
  // 原创作品上/下架
  getSetEnable: {
    url: '/product/set-enable',
    method: 'post'
  },
  // 获取单个商品
  getProductOne: {
    url: '/product/get-one',
    method: 'get'
  },
  // 获取单个藏品
  getCollectionOne: {
    url: '/collection/get-one',
    method: 'get'
  },
  // 预约商品
  productSubscribe: {
    url: '/product/subscribe',
    method: 'post'
  },
  // 发送手机验证码
  sendSms: {
    url: '/public/send-sms',
    method: 'post'
  },
  // 账号登录注册
  login: {
    url: '/public/login',
    method: 'post'
  },
  // 帐号注销
  abandon: {
    url: '/user/abandon',
    method: 'post'
  },
  // app端上传oss的token，阿里云的sts方案
  getOssToken: {
    url: '/public/get-oss-token',
    method: 'get'
  },
  // 实名认证
  setCertification: {
    url: '/user/cert',
    method: 'post'
  },
  // 订单列表
  getOrderList: {
    url: '/order/get-list',
    method: 'get'
  },
  // 订单详情
  getOrderInfo: {
    url: '/order/info',
    method: 'get'
  },
  // 购买检查，查询是否可以购买
  orderCheck: {
    url: '/order/buy-check',
    method: 'post'
  },
  // 创建锁定订单 
  orderCreate: {
    url: '/order/create',
    method: 'post'
  },
  // 继续付款，获取支付相关签名信息
  orderPay: {
    url: '/order/pay',
    method: 'post'
  },
  // 银行卡确认支付
  orderConfirm: {
    url: '/order/bank-card-pay-confirm',
    method: 'post'
  },
  // 取消订单
  orderCancel: {
    url: '/order/cancel',
    method: 'post'
  },
  //设置获取个人资料
  getUserInfo: {
    url: '/user/get-info',
    method: 'get'
  },
  //更新资料
  setUpdateInfo: {
    url: '/user/update-info',
    method: 'post'
  },
  //消息列表
  getMessageList: {
    url: '/message/get-list',
    method: 'get'
  },
  //设置一条消息已读
  setMessageRead: {
    url: '/message/read',
    method: 'post'
  },
  //设置所有消息已读
  setMessageReadAll: {
    url: '/message/read-all',
    method: 'post'
  },
  //通过hash获取藏品证书
  getCertificateDetail: {
    url: '/collection/get-one-by-hash',
    method: 'get'
  },
  //活动管理-详情
  getActivityDetail: {
    url: '/activity/detail',
    method: 'get'
  },
  //抽奖
  activityPlay: {
    url: '/activity/play',
    method: 'get'
  },
  // 我的奖池
  getActivityRecord: {
    url: '/activity/record',
    method: 'get'
  },
  // 空投活动列表
  getAirdropList: {
    url: '/airdrop/list',
    method: 'get'
  },
  // 空投活动详情
  getAirdropDetail: {
    url: '/airdrop/detail',
    method: 'get'
  },
  // 空投活动领取
  airdropGet: {
    url: '/airdrop/get',
    method: 'post'
  },
  // 空投活动领取检查
  airdropGetCheck: {
    url: '/airdrop/get-check',
    method: 'post'
  },
  // 我的银行卡
  getBankCardList: {
    url: '/bank-card/list',
    method: 'get'
  },
  // 预绑卡
  preBindCard: {
    url: '/bank-card/pre-bind-card',
    method: 'post'
  },
  // 确认绑卡
  confirmBindCard: {
    url: '/bank-card/confirm-bind-card',
    method: 'post'
  },
  // 邀请好友
  getUserInvite: {
    url: '/user/invite',
    method: 'get'
  },
  // 邀请记录
  getInviteList: {
    url: '/user/invite-list',
    method: 'get'
  },
  // 操作密码
  optPassword: {
    url: '/user/opt-password',
    method: 'post'
  },
  // 查看藏品是否可以转赠
  checkSubgift: {
    url: '/collection/check-subgift',
    method: 'get'
  },
  // 查询用户
  userQuery: {
    url: '/user/query',
    method: 'get'
  },
  // 转赠商品
  sendSubgift: {
    url: '/collection/subgift',
    method: 'post'
  },
  // 获取转赠详情
  getSubgiftDetail: {
    url: '/subgift/detail',
    method: 'get'
  },
  // 领取转赠藏品
  receiveSubgift: {
    url: '/subgift/get',
    method: 'post'
  },
  // 藏品记录
  getCollectionRecords: {
    url: '/collection/records',
    method: 'get'
  },
  // 搜索hash
  getHashDetail: {
    url: '/collection/search-hash',
    method: 'get'
  },
  // 盲盒搜集进度
  getProductProgress: {
    url: '/product/progress',
    method: 'get'
  },
  // 获取合成活动
  getSynthetic: {
    url: '/synthetic/get',
    method: 'get'
  },
  // 合成活动材料列表
  getMaterialList: {
    url: '/synthetic/get-material-list',
    method: 'get'
  },
  // 合成
  syntheticPlay: {
    url: '/synthetic/play',
    method: 'post'
  },
  // 钱包信息
  getWalletInfo: {
    url: '/wallet/info',
    method: 'get'
  },
  // 账单列表
  getWalletBill: {
    url: '/wallet/bill',
    method: 'get'
  },
  // 申请提现接口
  cashApply: {
    url: '/wallet/cash-apply',
    method: 'post'
  },
  // 账单详情
  getBillInfo: {
    url: '/wallet/bill-info',
    method: 'get'
  },
  // 设置支付密码
  payPassword: {
    url: '/wallet/pay-password',
    method: 'post'
  },
  // 发布寄售列表
  getPushList: {
    url: '/pro-consignment/get-push-list',
    method: 'get'
  },
  // 发布寄售详情
  getPushDetail: {
    url: '/pro-consignment/push-detail',
    method: 'get'
  },
  // 寄售上架
  pushConsignment: {
    url: '/pro-consignment/push-consignment',
    method: 'post'
  },
  // 寄售下架
  offConsignment: {
    url: '/pro-consignment/off-shelf',
    method: 'post'
  },
  // 寄售修改价格
  updateConsignment: {
    url: '/pro-consignment/update-price',
    method: 'post'
  },
  // 获取相关配置
  getConfig: {
    url: '/user/get-config',
    method: 'get'
  },
  // 寄售藏品聚合页
  getSaleList: {
    url: '/pro-consignment/get-list',
    method: 'get'
  },
  // 寄售藏品聚类型
  getSaleCategory: {
    url: '/pro-consignment/get-category-list',
    method: 'get'
  },
  // 同类寄售列表
  getSimilarList: {
    url: '/pro-consignment/similar-list',
    method: 'get'
  },
  // 我卖出的列表
  getSellOutList: {
    url: '/pro-consignment/my-sell-out-list',
    method: 'get'
  },
  // 我买入的列表
  getBuyInList: {
    url: '/pro-consignment/my-buy-list',
    method: 'get'
  },
  // 我卖出的详情
  getSellOutDetail: {
    url: '/pro-consignment/my-sell-out-detail',
    method: 'get'
  },
  // 我买入的详情
  getBuyDetail: {
    url: '/pro-consignment/my-buy-detail',
    method: 'get'
  },
  // 创作者市场列表
  getCreatorMarket: {
    url: '/product/creator-market',
    method: 'get'
  },
  // 检测是否含有敏感词
  getTestKeyWords: {
    url: '/product/test-key-words',
    method: 'get'
  },
  // 标签列表
  getProductTags: {
    url: '/product/tags',
    method: 'get'
  },
  // 公告列表
  getAnnouncementList: {
    url: '/app-announcement/get-list',
    method: 'get'
  },
  // 公告详情
  getAnnouncementDetail: {
    url: '/app-announcement/detail',
    method: 'get'
  },
  // 获取铸造卡流水
  getCastCardList: {
    url: '/user/cast-card-record-list',
    method: 'get'
  },
  // 用户创建商品
  productCreate: {
    url: '/product/create',
    method: 'post'
  },
  // 我的收藏列表
  getFavoriteList: {
    url: '/user/favorite-list',
    method: 'get'
  },
  // 收藏藏品
  productCollect: {
    url: '/product/collect',
    method: 'post'
  },
  // 取消收藏藏品
  productCancelCollect: {
    url: '/product/cancel-collect',
    method: 'post'
  },
  // 用户原创作品上/下架
  productSetEnable: {
    url: '/product/set-enable',
    method: 'post'
  },
  // 获取原创作品可售卖列表
  canSellList: {
    url: '/product/user-creator-can-sell-list',
    method: 'get'
  },
  // 上传pv/uv 
  upPuv: {
    url: '/product/up-puv',
    method: 'post'
  },
  // 上传分享次数
  upShare: {
    url: '/product/up-share',
    method: 'post'
  },
 }
 
 for (let k in urls) {//eslint-disable-line
   urls[k].url = prefix + urls[k].url
 }
 
 export default urls